//
//
//
//
//
//

import AgentMenu from '@/components/agents/AgentMenu'
import ClientMenu from '@/components/layout/ClientMenu'
import FreelancerMenu from '@/components/layout/FreelancerMenu'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LeftMenu',
  components: {
    AgentMenu,
    ClientMenu,
    FreelancerMenu
  },
  computed: {
    menuComponent () {
      const roleName = this.$store.getters['user/getActiveRoleTypeName']

      if (roleName) {
        return `${roleName}-menu`
      }

      return ''
    }
  }
}
