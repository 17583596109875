//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import MobileMenu from '@/components/layout/MobileMenu'
import Initializer from '@/components/layout/Initializer'
import MainLayoutHeader from '@/components/layout/MainLayoutHeader'
import MainLayoutFooter from '@/components/layout/MainLayoutFooter'
import GlobalNotification from '@/components/ui/GlobalNotification'
import LoadingOverlay from '@/components/ui/LoadingOverlay'

export default {
  name: 'LayoutWrapper',
  components: {
    MobileMenu,
    Initializer,
    MainLayoutHeader,
    MainLayoutFooter,
    GlobalNotification,
    LoadingOverlay
  },
  computed: {
    /**
     * Is loading activated
     * @return {boolean}
     */
    loading () {
      return this.$store.state.ui.mainLoader
    }
  }
}
