export const state = () => ({
  posts: []
})

export const mutations = {
  setPosts (state, posts) {
    state.posts = posts
  }
}

export const actions = {
  async getPosts ({ commit }) {
    const posts = await this.$axios.get('/posts')
    commit('setPosts', posts.data.data)
  }
}
