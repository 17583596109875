export default (context) => {
  if (process.browser) {
    const settings = process.env.fbPixel.split(';')
    const mapping = {}
    const domain = window.location.host
    settings.forEach((s) => {
      const item = s.split('::')
      mapping[item[0]] = item[1]
    })
    const key = mapping[domain]
    if (key) {
      context.$fb.setPixelId(key)
      context.$fb.enable()
    }
  }
}
