import Vue from 'vue'
import { wrapFunctional } from './utils'

const components = {
  AboutEn: () => import('../../components/about/AboutEn.vue' /* webpackChunkName: "components/about-en" */).then(c => wrapFunctional(c.default || c)),
  AboutHr: () => import('../../components/about/AboutHr.vue' /* webpackChunkName: "components/about-hr" */).then(c => wrapFunctional(c.default || c)),
  AboutItem: () => import('../../components/about/AboutItem.vue' /* webpackChunkName: "components/about-item" */).then(c => wrapFunctional(c.default || c)),
  AboutPerson: () => import('../../components/about/AboutPerson.vue' /* webpackChunkName: "components/about-person" */).then(c => wrapFunctional(c.default || c)),
  AboutEmployersFunnel: () => import('../../components/about/EmployersFunnel.vue' /* webpackChunkName: "components/about-employers-funnel" */).then(c => wrapFunctional(c.default || c)),
  AboutFreelancersFunnel: () => import('../../components/about/FreelancersFunnel.vue' /* webpackChunkName: "components/about-freelancers-funnel" */).then(c => wrapFunctional(c.default || c)),
  AboutWhyBlock: () => import('../../components/about/WhyBlock.vue' /* webpackChunkName: "components/about-why-block" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentApplicationButtons: () => import('../../components/agents/AgentApplicationButtons.vue' /* webpackChunkName: "components/agents-agent-application-buttons" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentApplications: () => import('../../components/agents/AgentApplications.vue' /* webpackChunkName: "components/agents-agent-applications" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentBasicInfoForm: () => import('../../components/agents/AgentBasicInfoForm.vue' /* webpackChunkName: "components/agents-agent-basic-info-form" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentClientContactDetails: () => import('../../components/agents/AgentClientContactDetails.vue' /* webpackChunkName: "components/agents-agent-client-contact-details" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentInvitationItem: () => import('../../components/agents/AgentInvitationItem.vue' /* webpackChunkName: "components/agents-agent-invitation-item" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentMenu: () => import('../../components/agents/AgentMenu.vue' /* webpackChunkName: "components/agents-agent-menu" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentPayoutAddressForm: () => import('../../components/agents/AgentPayoutAddressForm.vue' /* webpackChunkName: "components/agents-agent-payout-address-form" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentPayoutAddressModal: () => import('../../components/agents/AgentPayoutAddressModal.vue' /* webpackChunkName: "components/agents-agent-payout-address-modal" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentPayoutAddressTab: () => import('../../components/agents/AgentPayoutAddressTab.vue' /* webpackChunkName: "components/agents-agent-payout-address-tab" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentProfilePreview: () => import('../../components/agents/AgentProfilePreview.vue' /* webpackChunkName: "components/agents-agent-profile-preview" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentSelector: () => import('../../components/agents/AgentSelector.vue' /* webpackChunkName: "components/agents-agent-selector" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentShortTaskItem: () => import('../../components/agents/AgentShortTaskItem.vue' /* webpackChunkName: "components/agents-agent-short-task-item" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentTaskApplicationsTab: () => import('../../components/agents/AgentTaskApplicationsTab.vue' /* webpackChunkName: "components/agents-agent-task-applications-tab" */).then(c => wrapFunctional(c.default || c)),
  AgentsAgentTaskInvitationsTab: () => import('../../components/agents/AgentTaskInvitationsTab.vue' /* webpackChunkName: "components/agents-agent-task-invitations-tab" */).then(c => wrapFunctional(c.default || c)),
  AgentsSelectedAgent: () => import('../../components/agents/SelectedAgent.vue' /* webpackChunkName: "components/agents-selected-agent" */).then(c => wrapFunctional(c.default || c)),
  AgentsSmallAgent: () => import('../../components/agents/SmallAgent.vue' /* webpackChunkName: "components/agents-small-agent" */).then(c => wrapFunctional(c.default || c)),
  AgentsUpdateAgent: () => import('../../components/agents/UpdateAgent.vue' /* webpackChunkName: "components/agents-update-agent" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetails: () => import('../../components/applications/ApplicationDetails.vue' /* webpackChunkName: "components/applications-application-details" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetailsClientButtons: () => import('../../components/applications/ApplicationDetailsClientButtons.vue' /* webpackChunkName: "components/applications-application-details-client-buttons" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetailsCreated: () => import('../../components/applications/ApplicationDetailsCreated.vue' /* webpackChunkName: "components/applications-application-details-created" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetailsPayoutAddress: () => import('../../components/applications/ApplicationDetailsPayoutAddress.vue' /* webpackChunkName: "components/applications-application-details-payout-address" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetailsRecommended: () => import('../../components/applications/ApplicationDetailsRecommended.vue' /* webpackChunkName: "components/applications-application-details-recommended" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationDetailsResume: () => import('../../components/applications/ApplicationDetailsResume.vue' /* webpackChunkName: "components/applications-application-details-resume" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsApplicationFlagButtons: () => import('../../components/applications/ApplicationFlagButtons.vue' /* webpackChunkName: "components/applications-application-flag-buttons" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsDetailsStatus: () => import('../../components/applications/ApplicationsDetailsStatus.vue' /* webpackChunkName: "components/applications-details-status" */).then(c => wrapFunctional(c.default || c)),
  ApplicationsClientItemHeader: () => import('../../components/applications/ClientItemHeader.vue' /* webpackChunkName: "components/applications-client-item-header" */).then(c => wrapFunctional(c.default || c)),
  AuthAccess: () => import('../../components/auth/Access.vue' /* webpackChunkName: "components/auth-access" */).then(c => wrapFunctional(c.default || c)),
  AuthContainer: () => import('../../components/auth/AuthContainer.vue' /* webpackChunkName: "components/auth-container" */).then(c => wrapFunctional(c.default || c)),
  AuthDescription: () => import('../../components/auth/AuthDescription.vue' /* webpackChunkName: "components/auth-description" */).then(c => wrapFunctional(c.default || c)),
  AuthForgotPasswordForm: () => import('../../components/auth/ForgotPasswordForm.vue' /* webpackChunkName: "components/auth-forgot-password-form" */).then(c => wrapFunctional(c.default || c)),
  AuthLoginForm: () => import('../../components/auth/LoginForm.vue' /* webpackChunkName: "components/auth-login-form" */).then(c => wrapFunctional(c.default || c)),
  AuthPasswordForm: () => import('../../components/auth/PasswordForm.vue' /* webpackChunkName: "components/auth-password-form" */).then(c => wrapFunctional(c.default || c)),
  AuthRegisterForm: () => import('../../components/auth/RegisterForm.vue' /* webpackChunkName: "components/auth-register-form" */).then(c => wrapFunctional(c.default || c)),
  AuthResetPasswordForm: () => import('../../components/auth/ResetPasswordForm.vue' /* webpackChunkName: "components/auth-reset-password-form" */).then(c => wrapFunctional(c.default || c)),
  AuthSignUpRoles: () => import('../../components/auth/SignUpRoles.vue' /* webpackChunkName: "components/auth-sign-up-roles" */).then(c => wrapFunctional(c.default || c)),
  BlogShortPost: () => import('../../components/blog/ShortPost.vue' /* webpackChunkName: "components/blog-short-post" */).then(c => wrapFunctional(c.default || c)),
  ClientApplicationFeedback: () => import('../../components/client/ApplicationFeedback.vue' /* webpackChunkName: "components/client-application-feedback" */).then(c => wrapFunctional(c.default || c)),
  ClientBecomeTrusted: () => import('../../components/client/BecomeTrusted.vue' /* webpackChunkName: "components/client-become-trusted" */).then(c => wrapFunctional(c.default || c)),
  ClientBasicInfoForm: () => import('../../components/client/ClientBasicInfoForm.vue' /* webpackChunkName: "components/client-basic-info-form" */).then(c => wrapFunctional(c.default || c)),
  ClientFeedback: () => import('../../components/client/ClientFeedback.vue' /* webpackChunkName: "components/client-feedback" */).then(c => wrapFunctional(c.default || c)),
  ClientFeedbacks: () => import('../../components/client/ClientFeedbacks.vue' /* webpackChunkName: "components/client-feedbacks" */).then(c => wrapFunctional(c.default || c)),
  ClientName: () => import('../../components/client/ClientName.vue' /* webpackChunkName: "components/client-name" */).then(c => wrapFunctional(c.default || c)),
  ClientProfilePreview: () => import('../../components/client/ClientProfilePreview.vue' /* webpackChunkName: "components/client-profile-preview" */).then(c => wrapFunctional(c.default || c)),
  ClientRate: () => import('../../components/client/ClientRate.vue' /* webpackChunkName: "components/client-rate" */).then(c => wrapFunctional(c.default || c)),
  ClientTasksList: () => import('../../components/client/ClientTasksList.vue' /* webpackChunkName: "components/client-tasks-list" */).then(c => wrapFunctional(c.default || c)),
  ClientTasksListItem: () => import('../../components/client/ClientTasksListItem.vue' /* webpackChunkName: "components/client-tasks-list-item" */).then(c => wrapFunctional(c.default || c)),
  ClientTrusted: () => import('../../components/client/ClientTrusted.vue' /* webpackChunkName: "components/client-trusted" */).then(c => wrapFunctional(c.default || c)),
  ClientCloseTaskButton: () => import('../../components/client/CloseTaskButton.vue' /* webpackChunkName: "components/client-close-task-button" */).then(c => wrapFunctional(c.default || c)),
  ClientFreelancersSearch: () => import('../../components/client/FreelancersSearch.vue' /* webpackChunkName: "components/client-freelancers-search" */).then(c => wrapFunctional(c.default || c)),
  ClientFreelancersSearchHeading: () => import('../../components/client/FreelancersSearchHeading.vue' /* webpackChunkName: "components/client-freelancers-search-heading" */).then(c => wrapFunctional(c.default || c)),
  ClientFreelancersSearchItem: () => import('../../components/client/FreelancersSearchItem.vue' /* webpackChunkName: "components/client-freelancers-search-item" */).then(c => wrapFunctional(c.default || c)),
  ClientFreelancersSearchList: () => import('../../components/client/FreelancersSearchList.vue' /* webpackChunkName: "components/client-freelancers-search-list" */).then(c => wrapFunctional(c.default || c)),
  ClientInviteButton: () => import('../../components/client/InviteButton.vue' /* webpackChunkName: "components/client-invite-button" */).then(c => wrapFunctional(c.default || c)),
  ClientInviteToJobForm: () => import('../../components/client/InviteToJobForm.vue' /* webpackChunkName: "components/client-invite-to-job-form" */).then(c => wrapFunctional(c.default || c)),
  ClientInviteToJobModal: () => import('../../components/client/InviteToJobModal.vue' /* webpackChunkName: "components/client-invite-to-job-modal" */).then(c => wrapFunctional(c.default || c)),
  ClientNoResults: () => import('../../components/client/NoResults.vue' /* webpackChunkName: "components/client-no-results" */).then(c => wrapFunctional(c.default || c)),
  ClientReopenTaskButton: () => import('../../components/client/ReopenTaskButton.vue' /* webpackChunkName: "components/client-reopen-task-button" */).then(c => wrapFunctional(c.default || c)),
  ClientUpdateClient: () => import('../../components/client/UpdateClient.vue' /* webpackChunkName: "components/client-update-client" */).then(c => wrapFunctional(c.default || c)),
  CommonBcCheckStatus: () => import('../../components/common/BcCheckStatus.vue' /* webpackChunkName: "components/common-bc-check-status" */).then(c => wrapFunctional(c.default || c)),
  CommonContactForm: () => import('../../components/common/ContactForm.vue' /* webpackChunkName: "components/common-contact-form" */).then(c => wrapFunctional(c.default || c)),
  CommonNotFoundError: () => import('../../components/common/NotFoundError.vue' /* webpackChunkName: "components/common-not-found-error" */).then(c => wrapFunctional(c.default || c)),
  CommonPageContent: () => import('../../components/common/PageContent.vue' /* webpackChunkName: "components/common-page-content" */).then(c => wrapFunctional(c.default || c)),
  EscrowCreateTaskEscrowForm: () => import('../../components/escrow/CreateTaskEscrowForm.vue' /* webpackChunkName: "components/escrow-create-task-escrow-form" */).then(c => wrapFunctional(c.default || c)),
  EscrowBalance: () => import('../../components/escrow/EscrowBalance.vue' /* webpackChunkName: "components/escrow-balance" */).then(c => wrapFunctional(c.default || c)),
  EscrowForm: () => import('../../components/escrow/EscrowForm.vue' /* webpackChunkName: "components/escrow-form" */).then(c => wrapFunctional(c.default || c)),
  FeedbackModal: () => import('../../components/feedback/FeedbackModal.vue' /* webpackChunkName: "components/feedback-modal" */).then(c => wrapFunctional(c.default || c)),
  FormCategorySkillSelector: () => import('../../components/form/CategorySkillSelector.vue' /* webpackChunkName: "components/form-category-skill-selector" */).then(c => wrapFunctional(c.default || c)),
  FormCountrySelector: () => import('../../components/form/CountrySelector.vue' /* webpackChunkName: "components/form-country-selector" */).then(c => wrapFunctional(c.default || c)),
  FormFileUploader: () => import('../../components/form/FileUploader.vue' /* webpackChunkName: "components/form-file-uploader" */).then(c => wrapFunctional(c.default || c)),
  FormGalleryUploader: () => import('../../components/form/GalleryUploader.vue' /* webpackChunkName: "components/form-gallery-uploader" */).then(c => wrapFunctional(c.default || c)),
  FormHCaptchaInput: () => import('../../components/form/HCaptchaInput.vue' /* webpackChunkName: "components/form-h-captcha-input" */).then(c => wrapFunctional(c.default || c)),
  FormImageUploader: () => import('../../components/form/ImageUploader.vue' /* webpackChunkName: "components/form-image-uploader" */).then(c => wrapFunctional(c.default || c)),
  FormInputDatepicker: () => import('../../components/form/InputDatepicker.vue' /* webpackChunkName: "components/form-input-datepicker" */).then(c => wrapFunctional(c.default || c)),
  FormInputField: () => import('../../components/form/InputField.vue' /* webpackChunkName: "components/form-input-field" */).then(c => wrapFunctional(c.default || c)),
  FormInputGroup: () => import('../../components/form/InputGroup.vue' /* webpackChunkName: "components/form-input-group" */).then(c => wrapFunctional(c.default || c)),
  FormInputSelect: () => import('../../components/form/InputSelect.vue' /* webpackChunkName: "components/form-input-select" */).then(c => wrapFunctional(c.default || c)),
  FormInputTags: () => import('../../components/form/InputTags.vue' /* webpackChunkName: "components/form-input-tags" */).then(c => wrapFunctional(c.default || c)),
  FormStarRateGroup: () => import('../../components/form/StarRateGroup.vue' /* webpackChunkName: "components/form-star-rate-group" */).then(c => wrapFunctional(c.default || c)),
  FormTextareaGroup: () => import('../../components/form/TextareaGroup.vue' /* webpackChunkName: "components/form-textarea-group" */).then(c => wrapFunctional(c.default || c)),
  FormValidationMessages: () => import('../../components/form/ValidationMessages.vue' /* webpackChunkName: "components/form-validation-messages" */).then(c => wrapFunctional(c.default || c)),
  FormWysiwygTextareaGroup: () => import('../../components/form/WysiwygTextareaGroup.vue' /* webpackChunkName: "components/form-wysiwyg-textarea-group" */).then(c => wrapFunctional(c.default || c)),
  FreelancerBoostVisibilityButton: () => import('../../components/freelancer/FreelancerBoostVisibilityButton.vue' /* webpackChunkName: "components/freelancer-boost-visibility-button" */).then(c => wrapFunctional(c.default || c)),
  FreelancerFeedbackItem: () => import('../../components/freelancer/FreelancerFeedbackItem.vue' /* webpackChunkName: "components/freelancer-feedback-item" */).then(c => wrapFunctional(c.default || c)),
  FreelancerFeedbacks: () => import('../../components/freelancer/FreelancerFeedbacks.vue' /* webpackChunkName: "components/freelancer-feedbacks" */).then(c => wrapFunctional(c.default || c)),
  FreelancerProfile: () => import('../../components/freelancer/FreelancerProfile.vue' /* webpackChunkName: "components/freelancer-profile" */).then(c => wrapFunctional(c.default || c)),
  FreelancerProfilePreview: () => import('../../components/freelancer/FreelancerProfilePreview.vue' /* webpackChunkName: "components/freelancer-profile-preview" */).then(c => wrapFunctional(c.default || c)),
  FreelancerProfileTabs: () => import('../../components/freelancer/FreelancerProfileTabs.vue' /* webpackChunkName: "components/freelancer-profile-tabs" */).then(c => wrapFunctional(c.default || c)),
  FreelancerRate: () => import('../../components/freelancer/FreelancerRate.vue' /* webpackChunkName: "components/freelancer-rate" */).then(c => wrapFunctional(c.default || c)),
  FreelancerInvitationLetterTab: () => import('../../components/freelancer/InvitationLetterTab.vue' /* webpackChunkName: "components/freelancer-invitation-letter-tab" */).then(c => wrapFunctional(c.default || c)),
  FreelancerInvitationStatusBadge: () => import('../../components/freelancer/InvitationStatusBadge.vue' /* webpackChunkName: "components/freelancer-invitation-status-badge" */).then(c => wrapFunctional(c.default || c)),
  FreelancerOccupationLocation: () => import('../../components/freelancer/OccupationLocation.vue' /* webpackChunkName: "components/freelancer-occupation-location" */).then(c => wrapFunctional(c.default || c)),
  FreelancerProjectsPreview: () => import('../../components/freelancer/ProjectsPreview.vue' /* webpackChunkName: "components/freelancer-projects-preview" */).then(c => wrapFunctional(c.default || c)),
  FreelancerProjectsPreviewItem: () => import('../../components/freelancer/ProjectsPreviewItem.vue' /* webpackChunkName: "components/freelancer-projects-preview-item" */).then(c => wrapFunctional(c.default || c)),
  FreelancerUpdateBasicInfo: () => import('../../components/freelancer/UpdateBasicInfo.vue' /* webpackChunkName: "components/freelancer-update-basic-info" */).then(c => wrapFunctional(c.default || c)),
  FreelancerUpdateFreelancer: () => import('../../components/freelancer/UpdateFreelancer.vue' /* webpackChunkName: "components/freelancer-update-freelancer" */).then(c => wrapFunctional(c.default || c)),
  InvitationsInvitationShortItem: () => import('../../components/invitations/InvitationShortItem.vue' /* webpackChunkName: "components/invitations-invitation-short-item" */).then(c => wrapFunctional(c.default || c)),
  LandingAgentsSignup: () => import('../../components/landing/AgentsSignup.vue' /* webpackChunkName: "components/landing-agents-signup" */).then(c => wrapFunctional(c.default || c)),
  LandingBrowseFreelancers: () => import('../../components/landing/BrowseFreelancers.vue' /* webpackChunkName: "components/landing-browse-freelancers" */).then(c => wrapFunctional(c.default || c)),
  LandingBrowseFreelancersTab: () => import('../../components/landing/BrowseFreelancersTab.vue' /* webpackChunkName: "components/landing-browse-freelancers-tab" */).then(c => wrapFunctional(c.default || c)),
  LandingBrowseJobs: () => import('../../components/landing/BrowseJobs.vue' /* webpackChunkName: "components/landing-browse-jobs" */).then(c => wrapFunctional(c.default || c)),
  LandingBrowseJobsJobItem: () => import('../../components/landing/BrowseJobsJobItem.vue' /* webpackChunkName: "components/landing-browse-jobs-job-item" */).then(c => wrapFunctional(c.default || c)),
  LandingBrowseJobsTab: () => import('../../components/landing/BrowseJobsTab.vue' /* webpackChunkName: "components/landing-browse-jobs-tab" */).then(c => wrapFunctional(c.default || c)),
  LandingFeaturedIn: () => import('../../components/landing/FeaturedIn.vue' /* webpackChunkName: "components/landing-featured-in" */).then(c => wrapFunctional(c.default || c)),
  LandingFeaturedInItem: () => import('../../components/landing/FeaturedInItem.vue' /* webpackChunkName: "components/landing-featured-in-item" */).then(c => wrapFunctional(c.default || c)),
  LandingFindJob: () => import('../../components/landing/FindJob.vue' /* webpackChunkName: "components/landing-find-job" */).then(c => wrapFunctional(c.default || c)),
  LandingFindTalent: () => import('../../components/landing/FindTalent.vue' /* webpackChunkName: "components/landing-find-talent" */).then(c => wrapFunctional(c.default || c)),
  LandingFreelancerItem: () => import('../../components/landing/FreelancerItem.vue' /* webpackChunkName: "components/landing-freelancer-item" */).then(c => wrapFunctional(c.default || c)),
  LandingHowItWorks: () => import('../../components/landing/HowItWorks.vue' /* webpackChunkName: "components/landing-how-it-works" */).then(c => wrapFunctional(c.default || c)),
  LandingKoth: () => import('../../components/landing/Koth.vue' /* webpackChunkName: "components/landing-koth" */).then(c => wrapFunctional(c.default || c)),
  LandingKothItem: () => import('../../components/landing/KothItem.vue' /* webpackChunkName: "components/landing-koth-item" */).then(c => wrapFunctional(c.default || c)),
  LandingPartnersItem: () => import('../../components/landing/PartnersItem.vue' /* webpackChunkName: "components/landing-partners-item" */).then(c => wrapFunctional(c.default || c)),
  LandingPartnersSlider: () => import('../../components/landing/PartnersSlider.vue' /* webpackChunkName: "components/landing-partners-slider" */).then(c => wrapFunctional(c.default || c)),
  LandingSeoCategories: () => import('../../components/landing/SeoCategories.vue' /* webpackChunkName: "components/landing-seo-categories" */).then(c => wrapFunctional(c.default || c)),
  LandingSeoCategoryTab: () => import('../../components/landing/SeoCategoryTab.vue' /* webpackChunkName: "components/landing-seo-category-tab" */).then(c => wrapFunctional(c.default || c)),
  LandingTestimonialItem: () => import('../../components/landing/TestimonialItem.vue' /* webpackChunkName: "components/landing-testimonial-item" */).then(c => wrapFunctional(c.default || c)),
  LandingTestimonials: () => import('../../components/landing/Testimonials.vue' /* webpackChunkName: "components/landing-testimonials" */).then(c => wrapFunctional(c.default || c)),
  LandingWhyUs: () => import('../../components/landing/WhyUs.vue' /* webpackChunkName: "components/landing-why-us" */).then(c => wrapFunctional(c.default || c)),
  LandingWhyUsItem: () => import('../../components/landing/WhyUsItem.vue' /* webpackChunkName: "components/landing-why-us-item" */).then(c => wrapFunctional(c.default || c)),
  LayoutClientMenu: () => import('../../components/layout/ClientMenu.vue' /* webpackChunkName: "components/layout-client-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutFooterLogo: () => import('../../components/layout/FooterLogo.vue' /* webpackChunkName: "components/layout-footer-logo" */).then(c => wrapFunctional(c.default || c)),
  LayoutFreelancerMenu: () => import('../../components/layout/FreelancerMenu.vue' /* webpackChunkName: "components/layout-freelancer-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutInitializer: () => import('../../components/layout/Initializer.vue' /* webpackChunkName: "components/layout-initializer" */).then(c => wrapFunctional(c.default || c)),
  LayoutWrapper: () => import('../../components/layout/LayoutWrapper.vue' /* webpackChunkName: "components/layout-wrapper" */).then(c => wrapFunctional(c.default || c)),
  LayoutLeftMenu: () => import('../../components/layout/LeftMenu.vue' /* webpackChunkName: "components/layout-left-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoBack: () => import('../../components/layout/LogoBack.vue' /* webpackChunkName: "components/layout-logo-back" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoCryptotaskOrg: () => import('../../components/layout/LogoCryptotaskOrg.vue' /* webpackChunkName: "components/layout-logo-cryptotask-org" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoFreelanceHr: () => import('../../components/layout/LogoFreelanceHr.vue' /* webpackChunkName: "components/layout-logo-freelance-hr" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoImage: () => import('../../components/layout/LogoImage.vue' /* webpackChunkName: "components/layout-logo-image" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoSmallCt: () => import('../../components/layout/LogoSmallCt.vue' /* webpackChunkName: "components/layout-logo-small-ct" */).then(c => wrapFunctional(c.default || c)),
  LayoutLogoSmallFl: () => import('../../components/layout/LogoSmallFl.vue' /* webpackChunkName: "components/layout-logo-small-fl" */).then(c => wrapFunctional(c.default || c)),
  LayoutMainLayoutFooter: () => import('../../components/layout/MainLayoutFooter.vue' /* webpackChunkName: "components/layout-main-layout-footer" */).then(c => wrapFunctional(c.default || c)),
  LayoutMainLayoutHeader: () => import('../../components/layout/MainLayoutHeader.vue' /* webpackChunkName: "components/layout-main-layout-header" */).then(c => wrapFunctional(c.default || c)),
  LayoutMobileMenu: () => import('../../components/layout/MobileMenu.vue' /* webpackChunkName: "components/layout-mobile-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutMobileTopMenu: () => import('../../components/layout/MobileTopMenu.vue' /* webpackChunkName: "components/layout-mobile-top-menu" */).then(c => wrapFunctional(c.default || c)),
  LayoutPostJobMenuItem: () => import('../../components/layout/PostJobMenuItem.vue' /* webpackChunkName: "components/layout-post-job-menu-item" */).then(c => wrapFunctional(c.default || c)),
  LayoutSocialLink: () => import('../../components/layout/SocialLink.vue' /* webpackChunkName: "components/layout-social-link" */).then(c => wrapFunctional(c.default || c)),
  LayoutSocialLinks: () => import('../../components/layout/SocialLinks.vue' /* webpackChunkName: "components/layout-social-links" */).then(c => wrapFunctional(c.default || c)),
  LayoutTopMenu: () => import('../../components/layout/TopMenu.vue' /* webpackChunkName: "components/layout-top-menu" */).then(c => wrapFunctional(c.default || c)),
  MixinsApplicationUrl: () => import('../../components/mixins/applicationUrl.js' /* webpackChunkName: "components/mixins-application-url" */).then(c => wrapFunctional(c.default || c)),
  PaymentsPaymentInstructions: () => import('../../components/payments/PaymentInstructions.vue' /* webpackChunkName: "components/payments-payment-instructions" */).then(c => wrapFunctional(c.default || c)),
  PaymentsPaymentMethodName: () => import('../../components/payments/PaymentMethodName.vue' /* webpackChunkName: "components/payments-payment-method-name" */).then(c => wrapFunctional(c.default || c)),
  PaymentsPaymentMethodSelector: () => import('../../components/payments/PaymentMethodSelector.vue' /* webpackChunkName: "components/payments-payment-method-selector" */).then(c => wrapFunctional(c.default || c)),
  PaymentsPaymentProcessor: () => import('../../components/payments/PaymentProcessor.vue' /* webpackChunkName: "components/payments-payment-processor" */).then(c => wrapFunctional(c.default || c)),
  PaymentsPaymentStatusBadge: () => import('../../components/payments/PaymentStatusBadge.vue' /* webpackChunkName: "components/payments-payment-status-badge" */).then(c => wrapFunctional(c.default || c)),
  ProfileBasicInfoForm: () => import('../../components/profile/BasicInfoForm.vue' /* webpackChunkName: "components/profile-basic-info-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileBasicInfoPreview: () => import('../../components/profile/BasicInfoPreview.vue' /* webpackChunkName: "components/profile-basic-info-preview" */).then(c => wrapFunctional(c.default || c)),
  ProfileCopyProfileUrl: () => import('../../components/profile/CopyProfileUrl.vue' /* webpackChunkName: "components/profile-copy-profile-url" */).then(c => wrapFunctional(c.default || c)),
  ProfileDeleteUserForm: () => import('../../components/profile/DeleteUserForm.vue' /* webpackChunkName: "components/profile-delete-user-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileExperienceForm: () => import('../../components/profile/ExperienceForm.vue' /* webpackChunkName: "components/profile-experience-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileExperiencePreview: () => import('../../components/profile/ExperiencePreview.vue' /* webpackChunkName: "components/profile-experience-preview" */).then(c => wrapFunctional(c.default || c)),
  ProfileExperiencePreviewItem: () => import('../../components/profile/ExperiencePreviewItem.vue' /* webpackChunkName: "components/profile-experience-preview-item" */).then(c => wrapFunctional(c.default || c)),
  ProfileFunnelButtons: () => import('../../components/profile/FunnelButtons.vue' /* webpackChunkName: "components/profile-funnel-buttons" */).then(c => wrapFunctional(c.default || c)),
  ProfileFunnelContainer: () => import('../../components/profile/FunnelContainer.vue' /* webpackChunkName: "components/profile-funnel-container" */).then(c => wrapFunctional(c.default || c)),
  ProfileFunnelMenu: () => import('../../components/profile/FunnelMenu.vue' /* webpackChunkName: "components/profile-funnel-menu" */).then(c => wrapFunctional(c.default || c)),
  ProfileFunnelMenuItem: () => import('../../components/profile/FunnelMenuItem.vue' /* webpackChunkName: "components/profile-funnel-menu-item" */).then(c => wrapFunctional(c.default || c)),
  ProfileLanguageSettingsForm: () => import('../../components/profile/LanguageSettingsForm.vue' /* webpackChunkName: "components/profile-language-settings-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileNewsletterSubscriptionForm: () => import('../../components/profile/NewsletterSubscriptionForm.vue' /* webpackChunkName: "components/profile-newsletter-subscription-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileForm: () => import('../../components/profile/ProfileForm.vue' /* webpackChunkName: "components/profile-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileProjectItemForm: () => import('../../components/profile/ProjectItemForm.vue' /* webpackChunkName: "components/profile-project-item-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileProjectsForm: () => import('../../components/profile/ProjectsForm.vue' /* webpackChunkName: "components/profile-projects-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileResumeForm: () => import('../../components/profile/ResumeForm.vue' /* webpackChunkName: "components/profile-resume-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileResumeLink: () => import('../../components/profile/ResumeLink.vue' /* webpackChunkName: "components/profile-resume-link" */).then(c => wrapFunctional(c.default || c)),
  ProfileResumesForm: () => import('../../components/profile/ResumesForm.vue' /* webpackChunkName: "components/profile-resumes-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileSkillsForm: () => import('../../components/profile/SkillsForm.vue' /* webpackChunkName: "components/profile-skills-form" */).then(c => wrapFunctional(c.default || c)),
  ProfileWebPresenceGroup: () => import('../../components/profile/WebPresenceGroup.vue' /* webpackChunkName: "components/profile-web-presence-group" */).then(c => wrapFunctional(c.default || c)),
  ProfileWebPresenceItem: () => import('../../components/profile/WebPresenceItem.vue' /* webpackChunkName: "components/profile-web-presence-item" */).then(c => wrapFunctional(c.default || c)),
  SearchCategoriesDropdown: () => import('../../components/search/CategoriesDropdown.vue' /* webpackChunkName: "components/search-categories-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchCountriesDropdown: () => import('../../components/search/CountriesDropdown.vue' /* webpackChunkName: "components/search-countries-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchFeaturedCheckbox: () => import('../../components/search/FeaturedCheckbox.vue' /* webpackChunkName: "components/search-featured-checkbox" */).then(c => wrapFunctional(c.default || c)),
  SearchLanguagesDropdown: () => import('../../components/search/LanguagesDropdown.vue' /* webpackChunkName: "components/search-languages-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchHeading: () => import('../../components/search/SearchHeading.vue' /* webpackChunkName: "components/search-heading" */).then(c => wrapFunctional(c.default || c)),
  SearchItemDropdown: () => import('../../components/search/SearchItemDropdown.vue' /* webpackChunkName: "components/search-item-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchSkillsDropdown: () => import('../../components/search/SkillsDropdown.vue' /* webpackChunkName: "components/search-skills-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchSortDropdown: () => import('../../components/search/SortDropdown.vue' /* webpackChunkName: "components/search-sort-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchTaskLocationDropdown: () => import('../../components/search/TaskLocationDropdown.vue' /* webpackChunkName: "components/search-task-location-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SearchTasksGroup: () => import('../../components/search/TasksGroup.vue' /* webpackChunkName: "components/search-tasks-group" */).then(c => wrapFunctional(c.default || c)),
  SearchTasksSearch: () => import('../../components/search/TasksSearch.vue' /* webpackChunkName: "components/search-tasks-search" */).then(c => wrapFunctional(c.default || c)),
  SearchTypesDropdown: () => import('../../components/search/TypesDropdown.vue' /* webpackChunkName: "components/search-types-dropdown" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsBasicFreelancerSubscriptionForm: () => import('../../components/subscriptions/BasicFreelancerSubscriptionForm.vue' /* webpackChunkName: "components/subscriptions-basic-freelancer-subscription-form" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsBasicTaskSubscriptionForm: () => import('../../components/subscriptions/BasicTaskSubscriptionForm.vue' /* webpackChunkName: "components/subscriptions-basic-task-subscription-form" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsSubscriptionForm: () => import('../../components/subscriptions/SubscriptionForm.vue' /* webpackChunkName: "components/subscriptions-subscription-form" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsSubscriptionListTitle: () => import('../../components/subscriptions/SubscriptionListTitle.vue' /* webpackChunkName: "components/subscriptions-subscription-list-title" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsSubscriptionPaymentModal: () => import('../../components/subscriptions/SubscriptionPaymentModal.vue' /* webpackChunkName: "components/subscriptions-subscription-payment-modal" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsSubscriptionPrice: () => import('../../components/subscriptions/SubscriptionPrice.vue' /* webpackChunkName: "components/subscriptions-subscription-price" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsSubscriptionStatusBadge: () => import('../../components/subscriptions/SubscriptionStatusBadge.vue' /* webpackChunkName: "components/subscriptions-subscription-status-badge" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsList: () => import('../../components/subscriptions/SubscriptionsList.vue' /* webpackChunkName: "components/subscriptions-list" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsListActions: () => import('../../components/subscriptions/SubscriptionsListActions.vue' /* webpackChunkName: "components/subscriptions-list-actions" */).then(c => wrapFunctional(c.default || c)),
  SubscriptionsTrustedClientSubscriptionForm: () => import('../../components/subscriptions/TrustedClientSubscriptionForm.vue' /* webpackChunkName: "components/subscriptions-trusted-client-subscription-form" */).then(c => wrapFunctional(c.default || c)),
  TasksApplicationEscrow: () => import('../../components/tasks/ApplicationEscrow.vue' /* webpackChunkName: "components/tasks-application-escrow" */).then(c => wrapFunctional(c.default || c)),
  TasksApplicationFreelancer: () => import('../../components/tasks/ApplicationFreelancer.vue' /* webpackChunkName: "components/tasks-application-freelancer" */).then(c => wrapFunctional(c.default || c)),
  TasksApplicationStatusBadge: () => import('../../components/tasks/ApplicationStatusBadge.vue' /* webpackChunkName: "components/tasks-application-status-badge" */).then(c => wrapFunctional(c.default || c)),
  TasksApplicationsCounter: () => import('../../components/tasks/ApplicationsCounter.vue' /* webpackChunkName: "components/tasks-applications-counter" */).then(c => wrapFunctional(c.default || c)),
  TasksAppliedFreelancer: () => import('../../components/tasks/AppliedFreelancer.vue' /* webpackChunkName: "components/tasks-applied-freelancer" */).then(c => wrapFunctional(c.default || c)),
  TasksApplyForTaskButton: () => import('../../components/tasks/ApplyForTaskButton.vue' /* webpackChunkName: "components/tasks-apply-for-task-button" */).then(c => wrapFunctional(c.default || c)),
  TasksClientApplicationButtons: () => import('../../components/tasks/ClientApplicationButtons.vue' /* webpackChunkName: "components/tasks-client-application-buttons" */).then(c => wrapFunctional(c.default || c)),
  TasksClientApplicationFlags: () => import('../../components/tasks/ClientApplicationFlags.vue' /* webpackChunkName: "components/tasks-client-application-flags" */).then(c => wrapFunctional(c.default || c)),
  TasksClientApplications: () => import('../../components/tasks/ClientApplications.vue' /* webpackChunkName: "components/tasks-client-applications" */).then(c => wrapFunctional(c.default || c)),
  TasksClientFreelancerDropdown: () => import('../../components/tasks/ClientFreelancerDropdown.vue' /* webpackChunkName: "components/tasks-client-freelancer-dropdown" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerApplicationDropdown: () => import('../../components/tasks/FreelancerApplicationDropdown.vue' /* webpackChunkName: "components/tasks-freelancer-application-dropdown" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerApplicationsList: () => import('../../components/tasks/FreelancerApplicationsList.vue' /* webpackChunkName: "components/tasks-freelancer-applications-list" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerTaskButtons: () => import('../../components/tasks/FreelancerTaskButtons.vue' /* webpackChunkName: "components/tasks-freelancer-task-buttons" */).then(c => wrapFunctional(c.default || c)),
  TasksMyShortTaskItem: () => import('../../components/tasks/MyShortTaskItem.vue' /* webpackChunkName: "components/tasks-my-short-task-item" */).then(c => wrapFunctional(c.default || c)),
  TasksMyTaskShareLink: () => import('../../components/tasks/MyTaskShareLink.vue' /* webpackChunkName: "components/tasks-my-task-share-link" */).then(c => wrapFunctional(c.default || c)),
  TasksMyTasksHeading: () => import('../../components/tasks/MyTasksHeading.vue' /* webpackChunkName: "components/tasks-my-tasks-heading" */).then(c => wrapFunctional(c.default || c)),
  TasksMyTasksList: () => import('../../components/tasks/MyTasksList.vue' /* webpackChunkName: "components/tasks-my-tasks-list" */).then(c => wrapFunctional(c.default || c)),
  TasksNewApplicationModal: () => import('../../components/tasks/NewApplicationModal.vue' /* webpackChunkName: "components/tasks-new-application-modal" */).then(c => wrapFunctional(c.default || c)),
  TasksPostJobLink: () => import('../../components/tasks/PostJobLink.vue' /* webpackChunkName: "components/tasks-post-job-link" */).then(c => wrapFunctional(c.default || c)),
  TasksRequiredSkills: () => import('../../components/tasks/RequiredSkills.vue' /* webpackChunkName: "components/tasks-required-skills" */).then(c => wrapFunctional(c.default || c)),
  TasksSearchTaskItem: () => import('../../components/tasks/SearchTaskItem.vue' /* webpackChunkName: "components/tasks-search-task-item" */).then(c => wrapFunctional(c.default || c)),
  TasksShortApplicationItem: () => import('../../components/tasks/ShortApplicationItem.vue' /* webpackChunkName: "components/tasks-short-application-item" */).then(c => wrapFunctional(c.default || c)),
  TasksSingleTask: () => import('../../components/tasks/SingleTask.vue' /* webpackChunkName: "components/tasks-single-task" */).then(c => wrapFunctional(c.default || c)),
  TasksSingleTaskTitle: () => import('../../components/tasks/SingleTaskTitle.vue' /* webpackChunkName: "components/tasks-single-task-title" */).then(c => wrapFunctional(c.default || c)),
  TasksSkillName: () => import('../../components/tasks/SkillName.vue' /* webpackChunkName: "components/tasks-skill-name" */).then(c => wrapFunctional(c.default || c)),
  TasksSmallEmployer: () => import('../../components/tasks/SmallEmployer.vue' /* webpackChunkName: "components/tasks-small-employer" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDescription: () => import('../../components/tasks/TaskDescription.vue' /* webpackChunkName: "components/tasks-task-description" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetails: () => import('../../components/tasks/TaskDetails.vue' /* webpackChunkName: "components/tasks-task-details" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsApplications: () => import('../../components/tasks/TaskDetailsApplications.vue' /* webpackChunkName: "components/tasks-task-details-applications" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsButtons: () => import('../../components/tasks/TaskDetailsButtons.vue' /* webpackChunkName: "components/tasks-task-details-buttons" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsDuration: () => import('../../components/tasks/TaskDetailsDuration.vue' /* webpackChunkName: "components/tasks-task-details-duration" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsEscrow: () => import('../../components/tasks/TaskDetailsEscrow.vue' /* webpackChunkName: "components/tasks-task-details-escrow" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsItem: () => import('../../components/tasks/TaskDetailsItem.vue' /* webpackChunkName: "components/tasks-task-details-item" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsLanguage: () => import('../../components/tasks/TaskDetailsLanguage.vue' /* webpackChunkName: "components/tasks-task-details-language" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsLocation: () => import('../../components/tasks/TaskDetailsLocation.vue' /* webpackChunkName: "components/tasks-task-details-location" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsStatus: () => import('../../components/tasks/TaskDetailsStatus.vue' /* webpackChunkName: "components/tasks-task-details-status" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDetailsValue: () => import('../../components/tasks/TaskDetailsValue.vue' /* webpackChunkName: "components/tasks-task-details-value" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDuration: () => import('../../components/tasks/TaskDuration.vue' /* webpackChunkName: "components/tasks-task-duration" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskDurationContent: () => import('../../components/tasks/TaskDurationContent.vue' /* webpackChunkName: "components/tasks-task-duration-content" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskEscrow: () => import('../../components/tasks/TaskEscrow.vue' /* webpackChunkName: "components/tasks-task-escrow" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskForm: () => import('../../components/tasks/TaskForm.vue' /* webpackChunkName: "components/tasks-task-form" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskLikes: () => import('../../components/tasks/TaskLikes.vue' /* webpackChunkName: "components/tasks-task-likes" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskLikesSmall: () => import('../../components/tasks/TaskLikesSmall.vue' /* webpackChunkName: "components/tasks-task-likes-small" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskLocation: () => import('../../components/tasks/TaskLocation.vue' /* webpackChunkName: "components/tasks-task-location" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskPrice: () => import('../../components/tasks/TaskPrice.vue' /* webpackChunkName: "components/tasks-task-price" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskTag: () => import('../../components/tasks/TaskTag.vue' /* webpackChunkName: "components/tasks-task-tag" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskTags: () => import('../../components/tasks/TaskTags.vue' /* webpackChunkName: "components/tasks-task-tags" */).then(c => wrapFunctional(c.default || c)),
  TasksTaskType: () => import('../../components/tasks/TaskType.vue' /* webpackChunkName: "components/tasks-task-type" */).then(c => wrapFunctional(c.default || c)),
  TasksWinglioButton: () => import('../../components/tasks/WinglioButton.vue' /* webpackChunkName: "components/tasks-winglio-button" */).then(c => wrapFunctional(c.default || c)),
  UiAvatarDisplay: () => import('../../components/ui/AvatarDisplay.vue' /* webpackChunkName: "components/ui-avatar-display" */).then(c => wrapFunctional(c.default || c)),
  UiCopyUrl: () => import('../../components/ui/CopyUrl.vue' /* webpackChunkName: "components/ui-copy-url" */).then(c => wrapFunctional(c.default || c)),
  UiGlobalNotification: () => import('../../components/ui/GlobalNotification.vue' /* webpackChunkName: "components/ui-global-notification" */).then(c => wrapFunctional(c.default || c)),
  UiImageDisplay: () => import('../../components/ui/ImageDisplay.vue' /* webpackChunkName: "components/ui-image-display" */).then(c => wrapFunctional(c.default || c)),
  UiLanguageSwitcher: () => import('../../components/ui/LanguageSwitcher.vue' /* webpackChunkName: "components/ui-language-switcher" */).then(c => wrapFunctional(c.default || c)),
  UiLazyLoader: () => import('../../components/ui/LazyLoader.vue' /* webpackChunkName: "components/ui-lazy-loader" */).then(c => wrapFunctional(c.default || c)),
  UiLoaderRing: () => import('../../components/ui/LoaderRing.vue' /* webpackChunkName: "components/ui-loader-ring" */).then(c => wrapFunctional(c.default || c)),
  UiLoadingButton: () => import('../../components/ui/LoadingButton.vue' /* webpackChunkName: "components/ui-loading-button" */).then(c => wrapFunctional(c.default || c)),
  UiLoadingOverlay: () => import('../../components/ui/LoadingOverlay.vue' /* webpackChunkName: "components/ui-loading-overlay" */).then(c => wrapFunctional(c.default || c)),
  UiPageWrapper: () => import('../../components/ui/PageWrapper.vue' /* webpackChunkName: "components/ui-page-wrapper" */).then(c => wrapFunctional(c.default || c)),
  UiPaper: () => import('../../components/ui/Paper.vue' /* webpackChunkName: "components/ui-paper" */).then(c => wrapFunctional(c.default || c)),
  UiPrice: () => import('../../components/ui/Price.vue' /* webpackChunkName: "components/ui-price" */).then(c => wrapFunctional(c.default || c)),
  UiQuickChatSearch: () => import('../../components/ui/QuickChatSearch.vue' /* webpackChunkName: "components/ui-quick-chat-search" */).then(c => wrapFunctional(c.default || c)),
  UiQuickSearch: () => import('../../components/ui/QuickSearch.vue' /* webpackChunkName: "components/ui-quick-search" */).then(c => wrapFunctional(c.default || c)),
  UiRibbon: () => import('../../components/ui/Ribbon.vue' /* webpackChunkName: "components/ui-ribbon" */).then(c => wrapFunctional(c.default || c)),
  UiShareButtons: () => import('../../components/ui/ShareButtons.vue' /* webpackChunkName: "components/ui-share-buttons" */).then(c => wrapFunctional(c.default || c)),
  UiSuccessWrapper: () => import('../../components/ui/SuccessWrapper.vue' /* webpackChunkName: "components/ui-success-wrapper" */).then(c => wrapFunctional(c.default || c)),
  UiTagsDisplay: () => import('../../components/ui/TagsDisplay.vue' /* webpackChunkName: "components/ui-tags-display" */).then(c => wrapFunctional(c.default || c)),
  UiThemeToggler: () => import('../../components/ui/ThemeToggler.vue' /* webpackChunkName: "components/ui-theme-toggler" */).then(c => wrapFunctional(c.default || c)),
  UiUserInfo: () => import('../../components/ui/UserInfo.vue' /* webpackChunkName: "components/ui-user-info" */).then(c => wrapFunctional(c.default || c)),
  UiUserMenu: () => import('../../components/ui/UserMenu.vue' /* webpackChunkName: "components/ui-user-menu" */).then(c => wrapFunctional(c.default || c)),
  UiUserMenuProfileButton: () => import('../../components/ui/UserMenuProfileButton.vue' /* webpackChunkName: "components/ui-user-menu-profile-button" */).then(c => wrapFunctional(c.default || c)),
  PaymentsInstructionsCryptoInstructions: () => import('../../components/payments/instructions/CryptoInstructions.vue' /* webpackChunkName: "components/payments-instructions-crypto-instructions" */).then(c => wrapFunctional(c.default || c)),
  PaymentsInstructionsWireInstructions: () => import('../../components/payments/instructions/WireInstructions.vue' /* webpackChunkName: "components/payments-instructions-wire-instructions" */).then(c => wrapFunctional(c.default || c)),
  PaymentsResolversCryptoResolver: () => import('../../components/payments/resolvers/CryptoResolver.vue' /* webpackChunkName: "components/payments-resolvers-crypto-resolver" */).then(c => wrapFunctional(c.default || c)),
  PaymentsResolversWireResolver: () => import('../../components/payments/resolvers/WireResolver.vue' /* webpackChunkName: "components/payments-resolvers-wire-resolver" */).then(c => wrapFunctional(c.default || c)),
  PaymentsSuccessCryptoSuccess: () => import('../../components/payments/success/CryptoSuccess.vue' /* webpackChunkName: "components/payments-success-crypto-success" */).then(c => wrapFunctional(c.default || c)),
  PaymentsSuccessWireSuccess: () => import('../../components/payments/success/WireSuccess.vue' /* webpackChunkName: "components/payments-success-wire-success" */).then(c => wrapFunctional(c.default || c)),
  TasksChatAttachment: () => import('../../components/tasks/chat/ChatAttachment.vue' /* webpackChunkName: "components/tasks-chat-attachment" */).then(c => wrapFunctional(c.default || c)),
  TasksChatAttachmentFile: () => import('../../components/tasks/chat/ChatAttachmentFile.vue' /* webpackChunkName: "components/tasks-chat-attachment-file" */).then(c => wrapFunctional(c.default || c)),
  TasksChatAttachmentImage: () => import('../../components/tasks/chat/ChatAttachmentImage.vue' /* webpackChunkName: "components/tasks-chat-attachment-image" */).then(c => wrapFunctional(c.default || c)),
  TasksChatAttachmentOptions: () => import('../../components/tasks/chat/ChatAttachmentOptions.vue' /* webpackChunkName: "components/tasks-chat-attachment-options" */).then(c => wrapFunctional(c.default || c)),
  TasksChatAvatar: () => import('../../components/tasks/chat/ChatAvatar.vue' /* webpackChunkName: "components/tasks-chat-avatar" */).then(c => wrapFunctional(c.default || c)),
  TasksChatContainer: () => import('../../components/tasks/chat/ChatContainer.vue' /* webpackChunkName: "components/tasks-chat-container" */).then(c => wrapFunctional(c.default || c)),
  TasksChatHistory: () => import('../../components/tasks/chat/ChatHistory.vue' /* webpackChunkName: "components/tasks-chat-history" */).then(c => wrapFunctional(c.default || c)),
  TasksChatHistoryAvatar: () => import('../../components/tasks/chat/ChatHistoryAvatar.vue' /* webpackChunkName: "components/tasks-chat-history-avatar" */).then(c => wrapFunctional(c.default || c)),
  TasksChatHistoryHeader: () => import('../../components/tasks/chat/ChatHistoryHeader.vue' /* webpackChunkName: "components/tasks-chat-history-header" */).then(c => wrapFunctional(c.default || c)),
  TasksChatInput: () => import('../../components/tasks/chat/ChatInput.vue' /* webpackChunkName: "components/tasks-chat-input" */).then(c => wrapFunctional(c.default || c)),
  TasksChatMessage: () => import('../../components/tasks/chat/ChatMessage.vue' /* webpackChunkName: "components/tasks-chat-message" */).then(c => wrapFunctional(c.default || c)),
  TasksChatTypingInfo: () => import('../../components/tasks/chat/ChatTypingInfo.vue' /* webpackChunkName: "components/tasks-chat-typing-info" */).then(c => wrapFunctional(c.default || c)),
  TasksChatClientGroupedThreadGroup: () => import('../../components/tasks/chat/ClientGroupedThreadGroup.vue' /* webpackChunkName: "components/tasks-chat-client-grouped-thread-group" */).then(c => wrapFunctional(c.default || c)),
  TasksChatClientGroupedThreads: () => import('../../components/tasks/chat/ClientGroupedThreads.vue' /* webpackChunkName: "components/tasks-chat-client-grouped-threads" */).then(c => wrapFunctional(c.default || c)),
  TasksChatClientThreads: () => import('../../components/tasks/chat/ClientThreads.vue' /* webpackChunkName: "components/tasks-chat-client-threads" */).then(c => wrapFunctional(c.default || c)),
  TasksChatFreelancerThreads: () => import('../../components/tasks/chat/FreelancerThreads.vue' /* webpackChunkName: "components/tasks-chat-freelancer-threads" */).then(c => wrapFunctional(c.default || c)),
  TasksChatThread: () => import('../../components/tasks/chat/Thread.vue' /* webpackChunkName: "components/tasks-chat-thread" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsApplicationEscrowTab: () => import('../../components/tasks/clientTabs/ApplicationEscrowTab.vue' /* webpackChunkName: "components/tasks-client-tabs-application-escrow-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsApplicationFreelancerTab: () => import('../../components/tasks/clientTabs/ApplicationFreelancerTab.vue' /* webpackChunkName: "components/tasks-client-tabs-application-freelancer-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskAgentsTab: () => import('../../components/tasks/clientTabs/TaskAgentsTab.vue' /* webpackChunkName: "components/tasks-client-tabs-task-agents-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskApplicationsTab: () => import('../../components/tasks/clientTabs/TaskApplicationsTab.vue' /* webpackChunkName: "components/tasks-client-tabs-task-applications-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskDescriptionTab: () => import('../../components/tasks/clientTabs/TaskDescriptionTab.vue' /* webpackChunkName: "components/tasks-client-tabs-task-description-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskEscrowTab: () => import('../../components/tasks/clientTabs/TaskEscrowTab.vue' /* webpackChunkName: "components/tasks-client-tabs-task-escrow-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskSubscriptionsTab: () => import('../../components/tasks/clientTabs/TaskSubscriptionsTab.vue' /* webpackChunkName: "components/tasks-client-tabs-task-subscriptions-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksClientTabsTaskSubscriptionsTabContent: () => import('../../components/tasks/clientTabs/TaskSubscriptionsTabContent.vue' /* webpackChunkName: "components/tasks-client-tabs-task-subscriptions-tab-content" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerTabsApplicationChatTab: () => import('../../components/tasks/freelancerTabs/ApplicationChatTab.vue' /* webpackChunkName: "components/tasks-freelancer-tabs-application-chat-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerTabsApplicationDescriptionTab: () => import('../../components/tasks/freelancerTabs/ApplicationDescriptionTab.vue' /* webpackChunkName: "components/tasks-freelancer-tabs-application-description-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerTabsApplicationEscrowTab: () => import('../../components/tasks/freelancerTabs/ApplicationEscrowTab.vue' /* webpackChunkName: "components/tasks-freelancer-tabs-application-escrow-tab" */).then(c => wrapFunctional(c.default || c)),
  TasksFreelancerTabsApplicationFeedbackTab: () => import('../../components/tasks/freelancerTabs/ApplicationFeedbackTab.vue' /* webpackChunkName: "components/tasks-freelancer-tabs-application-feedback-tab" */).then(c => wrapFunctional(c.default || c))
}

for (const name in components) {
  Vue.component(name, components[name])
  Vue.component('Lazy' + name, components[name])
}
