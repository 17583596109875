import { applicationStatuses } from 'ct-constants'

export const state = () => ({
  loadedApplications: [], // list of detailed applications loaded
  selectedApplicationId: null
})

export const actions = {
  /**
   * Load single application and select it
   * @param commit
   * @param {Number} applicationId
   * @returns {Promise<void>}
   */
  async loadApplication ({ commit }, applicationId) {
    const { data } = await this.$axios.get(`/applications/${applicationId}`)
    commit('addLoadedApplication', data.data)
    commit('selectApplication', applicationId)
  },

  /**
   * Load single application for selected task if exists and select it
   * @param commit
   * @param {Number} taskId
   * @returns {Promise<void>}
   */
  async loadTaskApplication ({ commit }, taskId) {
    const { data } = await this.$axios.get(`/tasks/${taskId}/application`)
    if (data.data) {
      commit('addLoadedApplication', data.data)
      commit('selectApplication', data.data.id)
    }
  },

  /**
   * Create new application
   * @param commit
   * @param {Number} taskId
   * @param {String} letter
   * @param {Number} resumeId
   * @param {String} cryptoPayoutAddress
   * @param {String} taskBcId
   * @param {Number} invitationId
   * @returns {Promise<Object>}
   */
  async apply ({ commit }, {
    taskId,
    letter,
    resumeId,
    cryptoPayoutAddress,
    taskBcId,
    invitationId
  }) {
    const { data } = await this.$axios.post('/applications', {
      taskId,
      letter,
      resumeId,
      cryptoPayoutAddress,
      invitationId
    })
    const application = data.data

    commit('addLoadedApplication', application)
    commit('selectApplication', application.id)

    return application
  },

  /**
   * Create freelancer feedback on application
   * @param commit
   * @param {Object} feedbackData
   * @param {Number} feedbackData.status
   * @param {Number} feedbackData.applicationId
   * @param {Number} feedbackData.rate
   * @param {String} feedbackData.message
   * @returns {Promise<Object>}
   */
  async createFeedback ({ commit }, feedbackData) {
    const { data } = await this.$axios.post('/feedbacks', feedbackData)
    if (feedbackData.status) {
      commit('setStatus', {
        status: feedbackData.status,
        applicationId: feedbackData.applicationId
      })
    }
    commit('setFeedback', data.data)
    return data.data
  },

  /**
   * Update payout address for selected application
   * @param commit
   * @param state
   * @param {String} newAddress
   * @returns {Promise<void>}
   */
  async updatePayoutAddress ({
    commit,
    state
  }, newAddress) {
    if (state.selectedApplicationId) {
      await this.$axios.put(`/applications/${state.selectedApplicationId}/payout-address`, { cryptoPayoutAddress: newAddress })
      commit('setApplicationPayoutAddress', newAddress)
    }
  },

  /**
   * Socket handler when freelancer is hired
   * @param commit
   * @param {Object} application
   */
  socket_freelancerHired ({ commit }, application) {
    commit('setHired', application)
  },

  /**
   * Socket handler for feedback when client created it
   * @param commit
   * @param {Object} feedback
   */
  socket_clientFeedback ({ commit }, feedback) {
    commit('setFeedback', feedback)
  },

  /**
   * Socket handler
   * Triggered when application status is changed
   * @param commit
   * @param {Object} application
   */
  socket_applicationStatus ({ commit }, application) {
    commit('setStatus', {
      applicationId: application.id,
      status: application.status
    })
  }
}

export const mutations = {
  /**
   * Add details of loaded application
   * @param state
   * @param {Object} application
   */
  addLoadedApplication (state, application) {
    state.loadedApplications = [...state.loadedApplications.filter(a => a.id !== application.id), application]
  },

  /**
   * Select single application id
   * @param state
   * @param {Number} applicationId
   */
  selectApplication (state, applicationId) {
    state.selectedApplicationId = applicationId
  },

  /**
   * Remove selected application id from store
   * @param state
   */
  deselectApplication (state) {
    state.selectedApplicationId = null
  },

  /**
   * Set status on selected application
   * @param state
   * @param {Number} status
   * @param {Number} applicationId
   */
  setStatus (state, {
    status,
    applicationId
  }) {
    state.loadedApplications = state.loadedApplications.map((a) => {
      if (a.id === applicationId) {
        return {
          ...a,
          status
        }
      }
      return a
    })
  },

  /**
   * Set feedback on selected application
   * @param state
   * @param {Object} feedback
   */
  setFeedback (state, feedback) {
    state.loadedApplications = state.loadedApplications.map((a) => {
      if (a.id === feedback.applicationId) {
        return {
          ...a,
          feedback
        }
      }
      return a
    })
  },

  /**
   * Set payout address on currently selected application
   * @param state
   * @param {String} address
   */
  setApplicationPayoutAddress (state, address) {
    if (state.selectedApplicationId) {
      state.loadedApplications = state.loadedApplications.map((a) => {
        if (a.id === state.selectedApplicationId) {
          return {
            ...a,
            cryptoPayoutAddress: address
          }
        }
        return a
      })
    }
  },

  /**
   * Set application status to hired
   * @param state
   * @param {Object} application
   */
  setHired (state, application) {
    state.loadedApplications = state.loadedApplications.map((a) => {
      if (a.id === application.id) {
        return {
          ...a,
          status: applicationStatuses.ACCEPTED
        }
      }

      return a
    })
  }
}

export const getters = {
  /**
   * Return selected application details
   * @param state
   * @returns {Object}
   */
  selectedApplication (state) {
    return state.loadedApplications.find(a => a?.id === state.selectedApplicationId)
  }
}
