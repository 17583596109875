//
//
//
//
//
//
//
//
//
//
//
//
//

import { truncate } from 'lodash'
import { roleNames } from 'ct-constants'
import ChatHistoryAvatar from './ChatHistoryAvatar.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'Thread',
  components: { ChatHistoryAvatar },
  props: {
    thread: {
      type: Object,
      required: true
    },
    role: {
      type: String,
      required: true
    }
  },
  computed: {
    /**
     * Get other user data
     */
    otherUser () {
      const otherRole = this.role === roleNames.CLIENT ? roleNames.FREELANCER : roleNames.CLIENT
      return this.thread[otherRole]
    },

    /**
     * Build header string depending on user role
     */
    headerString () {
      return this.role === roleNames.FREELANCER
        ? `${this.thread.task.title} - ${this.otherUser?.name}`
        : this.otherUser?.name
    },

    /**
     * Get last received message
     * @returns {*|null}
     */
    lastMessage () {
      return this.thread.messages?.length ? this.thread.messages[0] : null
    },

    /**
     * Format message created at time
     * @return {string}
     */
    timeString () {
      const time = this.lastMessage?.createdAt || this.thread.createdAt

      const dateTime = this.$moment(time)
      return this.$moment()
        .isSame(dateTime, 'day')
        ? dateTime.format('HH:mm:ss')
        : dateTime.format('D.M.YYYY')
    },

    /**
     * Truncate text to reasonable length
     * @return {string}
     */
    truncatedText () {
      const text = this.lastMessage?.text || ''

      return truncate(text, {
        separator: ' ',
        length: 36
      })
    },

    /**
     * Is thread already read
     * @returns {*}
     */
    threadRead () {
      return this.lastMessage?.read === true || this.lastMessage?.read === undefined
    },

    /**
     * Thread title classes, remove bold if thread is read
     * @returns {string[]}
     */
    threadTitleClasses () {
      const classes = ['m-0']

      if (this.threadRead) {
        classes.push('font-weight-normal')
      }

      return classes
    },

    /**
     * Other users avatar
     * @return {*}
     */
    avatar () {
      return this.otherUser?.avatar
    },

    /**
     * Is other user online
     * @return {boolean}
     */
    isOnline () {
      return !!this.otherUser?.user?.online
    }
  },
  methods: {
    /**
     * Emit clicked thread
     */
    selectThread () {
      this.$emit('click', this.thread)
    }
  }
}
