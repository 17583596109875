//
//
//
//
//
//

import SocialLink from '@/components/layout/SocialLink'

export default {
  name: 'SocialLinks',
  components: { SocialLink },
  data () {
    return {
      links: [
        {
          name: 'medium',
          color: '#000',
          icon: 'medium-m',
          title: 'Medium'
        },
        {
          name: 'twitter',
          color: '#0bf',
          icon: 'twitter',
          title: 'Twitter'
        },
        {
          name: 'youtube',
          color: '#f00',
          icon: 'youtube',
          title: 'YouTube'
        },
        {
          name: 'telegram',
          color: '#0af',
          icon: 'telegram-plane',
          title: 'Telegram'
        },
        {
          name: 'discord',
          color: '#9af',
          icon: 'discord',
          title: 'Discord'
        },
        {
          name: 'reddit',
          color: '#f52',
          icon: 'reddit-alien',
          title: 'Reddit'
        },
        {
          name: 'linkedin',
          color: '#08b',
          icon: 'linkedin-in',
          title: 'LinkedIn'
        },
        {
          name: 'facebook',
          color: '#46a',
          icon: 'facebook-f',
          title: 'Facebook'
        },
        {
          name: 'instagram',
          color: '#8a3ab9',
          icon: 'instagram',
          title: 'Instagram'
        },
        {
          name: 'tiktok',
          color: '#000',
          icon: 'tiktok',
          title: 'Tiktok'
        }
      ]
    }
  },
  computed: {
    items () {
      return this.links.filter(l => this.$te('socials.' + l.name)).map(l => ({
        ...l,
        url: this.$t('socials.' + l.name)
      }))
    }
  }
}
