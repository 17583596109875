import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from '../layouts/error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_plugin_2be3b449 from 'nuxt_plugin_plugin_2be3b449' // Source: ./components/plugin.js (mode: 'all')
import nuxt_plugin_bootstrapvue_a49657e4 from 'nuxt_plugin_bootstrapvue_a49657e4' // Source: ./bootstrap-vue.js (mode: 'all')
import nuxt_plugin_sentryserver_788db5a3 from 'nuxt_plugin_sentryserver_788db5a3' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_12d4441b from 'nuxt_plugin_sentryclient_12d4441b' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_templatesplugin7671286c_7c6ca7fa from 'nuxt_plugin_templatesplugin7671286c_7c6ca7fa' // Source: ./templates.plugin.7671286c.js (mode: 'client')
import nuxt_plugin_vlazyload_1979fe8d from 'nuxt_plugin_vlazyload_1979fe8d' // Source: ./v-lazy-load.js (mode: 'all')
import nuxt_plugin_moment_a9de2ece from 'nuxt_plugin_moment_a9de2ece' // Source: ./moment.js (mode: 'all')
import nuxt_plugin_pluginrouting_18275cf2 from 'nuxt_plugin_pluginrouting_18275cf2' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_a0b34170 from 'nuxt_plugin_pluginmain_a0b34170' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_axios_37f97642 from 'nuxt_plugin_axios_37f97642' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_fontawesome_8378963e from 'nuxt_plugin_fontawesome_8378963e' // Source: ./fontawesome.js (mode: 'all')
import nuxt_plugin_AxiosLanguage_4c532952 from 'nuxt_plugin_AxiosLanguage_4c532952' // Source: ../plugins/AxiosLanguage (mode: 'all')
import nuxt_plugin_Vuelidate_6c685d9a from 'nuxt_plugin_Vuelidate_6c685d9a' // Source: ../plugins/Vuelidate (mode: 'all')
import nuxt_plugin_userLocale_ccf8adc8 from 'nuxt_plugin_userLocale_ccf8adc8' // Source: ../plugins/userLocale (mode: 'all')
import nuxt_plugin_Tawk_56c18e78 from 'nuxt_plugin_Tawk_56c18e78' // Source: ../plugins/Tawk (mode: 'client')
import nuxt_plugin_SmartContract_52c86f64 from 'nuxt_plugin_SmartContract_52c86f64' // Source: ../plugins/SmartContract (mode: 'client')
import nuxt_plugin_VueSocketIo_0b00e348 from 'nuxt_plugin_VueSocketIo_0b00e348' // Source: ../plugins/VueSocketIo (mode: 'client')
import nuxt_plugin_StarRating_260201c6 from 'nuxt_plugin_StarRating_260201c6' // Source: ../plugins/StarRating (mode: 'client')
import nuxt_plugin_Datepicker_0e3fe0f3 from 'nuxt_plugin_Datepicker_0e3fe0f3' // Source: ../plugins/Datepicker (mode: 'client')
import nuxt_plugin_VueCropper_907d8944 from 'nuxt_plugin_VueCropper_907d8944' // Source: ../plugins/VueCropper (mode: 'client')
import nuxt_plugin_MomentLocale_eeb8241e from 'nuxt_plugin_MomentLocale_eeb8241e' // Source: ../plugins/MomentLocale (mode: 'client')
import nuxt_plugin_Jsonld_c6fd11d2 from 'nuxt_plugin_Jsonld_c6fd11d2' // Source: ../plugins/Jsonld (mode: 'all')
import nuxt_plugin_Gtag_56bbea4a from 'nuxt_plugin_Gtag_56bbea4a' // Source: ../plugins/Gtag (mode: 'client')
import nuxt_plugin_FbPixel_015b2b53 from 'nuxt_plugin_FbPixel_015b2b53' // Source: ../plugins/FbPixel (mode: 'client')
import nuxt_plugin_DateFormatFilter_6fb5b1d8 from 'nuxt_plugin_DateFormatFilter_6fb5b1d8' // Source: ../plugins/DateFormatFilter (mode: 'client')
import nuxt_plugin_Vuescroll_780c4dbc from 'nuxt_plugin_Vuescroll_780c4dbc' // Source: ../plugins/Vuescroll (mode: 'client')
import nuxt_plugin_auth_6df6671e from 'nuxt_plugin_auth_6df6671e' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_AuthRedirect_5f63177d from 'nuxt_plugin_AuthRedirect_5f63177d' // Source: ../plugins/AuthRedirect.js (mode: 'all')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root.$options.$nuxt
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const router = await createRouter(ssrContext, config)

  const store = createStore(ssrContext)
  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"meta":[{"charset":"utf-8"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"property":"og:type","content":"website","hid":"og:type"},{"name":"twitter:card","content":"summary_large_image","hid":"twitter:card"}],"link":[],"style":[],"script":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (typeof nuxt_plugin_plugin_2be3b449 === 'function') {
    await nuxt_plugin_plugin_2be3b449(app.context, inject)
  }

  if (typeof nuxt_plugin_bootstrapvue_a49657e4 === 'function') {
    await nuxt_plugin_bootstrapvue_a49657e4(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_sentryserver_788db5a3 === 'function') {
    await nuxt_plugin_sentryserver_788db5a3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_12d4441b === 'function') {
    await nuxt_plugin_sentryclient_12d4441b(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_templatesplugin7671286c_7c6ca7fa === 'function') {
    await nuxt_plugin_templatesplugin7671286c_7c6ca7fa(app.context, inject)
  }

  if (typeof nuxt_plugin_vlazyload_1979fe8d === 'function') {
    await nuxt_plugin_vlazyload_1979fe8d(app.context, inject)
  }

  if (typeof nuxt_plugin_moment_a9de2ece === 'function') {
    await nuxt_plugin_moment_a9de2ece(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_18275cf2 === 'function') {
    await nuxt_plugin_pluginrouting_18275cf2(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_a0b34170 === 'function') {
    await nuxt_plugin_pluginmain_a0b34170(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_37f97642 === 'function') {
    await nuxt_plugin_axios_37f97642(app.context, inject)
  }

  if (typeof nuxt_plugin_fontawesome_8378963e === 'function') {
    await nuxt_plugin_fontawesome_8378963e(app.context, inject)
  }

  if (typeof nuxt_plugin_AxiosLanguage_4c532952 === 'function') {
    await nuxt_plugin_AxiosLanguage_4c532952(app.context, inject)
  }

  if (typeof nuxt_plugin_Vuelidate_6c685d9a === 'function') {
    await nuxt_plugin_Vuelidate_6c685d9a(app.context, inject)
  }

  if (typeof nuxt_plugin_userLocale_ccf8adc8 === 'function') {
    await nuxt_plugin_userLocale_ccf8adc8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_Tawk_56c18e78 === 'function') {
    await nuxt_plugin_Tawk_56c18e78(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_SmartContract_52c86f64 === 'function') {
    await nuxt_plugin_SmartContract_52c86f64(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_VueSocketIo_0b00e348 === 'function') {
    await nuxt_plugin_VueSocketIo_0b00e348(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_StarRating_260201c6 === 'function') {
    await nuxt_plugin_StarRating_260201c6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_Datepicker_0e3fe0f3 === 'function') {
    await nuxt_plugin_Datepicker_0e3fe0f3(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_VueCropper_907d8944 === 'function') {
    await nuxt_plugin_VueCropper_907d8944(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_MomentLocale_eeb8241e === 'function') {
    await nuxt_plugin_MomentLocale_eeb8241e(app.context, inject)
  }

  if (typeof nuxt_plugin_Jsonld_c6fd11d2 === 'function') {
    await nuxt_plugin_Jsonld_c6fd11d2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_Gtag_56bbea4a === 'function') {
    await nuxt_plugin_Gtag_56bbea4a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_FbPixel_015b2b53 === 'function') {
    await nuxt_plugin_FbPixel_015b2b53(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_DateFormatFilter_6fb5b1d8 === 'function') {
    await nuxt_plugin_DateFormatFilter_6fb5b1d8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_Vuescroll_780c4dbc === 'function') {
    await nuxt_plugin_Vuescroll_780c4dbc(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_6df6671e === 'function') {
    await nuxt_plugin_auth_6df6671e(app.context, inject)
  }

  if (typeof nuxt_plugin_AuthRedirect_5f63177d === 'function') {
    await nuxt_plugin_AuthRedirect_5f63177d(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    router.push(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }
