export default function ({
  store,
  redirect,
  $auth,
  localePath
}) {
  if ($auth.loggedIn) {
    const isFreelancer = store.getters['user/isFreelancer']
    if (!isFreelancer) {
      store.dispatch('ui/showNotification', {
        type: 'danger',
        text: 'Please log in as freelancer'
      })

      // redirect client to new task form
      return redirect(localePath('/create-task'))
    }

    return true
  }
  return true
}
