//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CoffeeIcon, BriefcaseIcon, MailIcon, BookmarkIcon
} from 'vue-feather-icons'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ClientMenu',
  components: {
    CoffeeIcon,
    BriefcaseIcon,
    MailIcon,
    BookmarkIcon
  }
}
