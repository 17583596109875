//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ChevronDownIcon, ChevronUpIcon } from 'vue-feather-icons'
import Thread from './Thread.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ClientGroupedThreadGroup',
  components: {
    Thread,
    ChevronDownIcon,
    ChevronUpIcon
  },
  props: {
    groupId: {
      type: Number,
      required: true
    },
    threads: {
      type: Array,
      default () {
        return []
      }
    }
  },
  data () {
    return {
      visible: false
    }
  },
  computed: {
    groupThreads () {
      return this.threads.filter(t => t.taskId === this.groupId)
    },
    groupUnRead () {
      return !!this.groupThreads.find(t => t.messages?.length && t.messages[0].read === false)
    },
    groupTitle () {
      return this.groupThreads.length ? this.groupThreads[0].task.title : ''
    },
    groupTitleClasses () {
      const classes = ['mb-0']

      if (!this.groupUnRead) {
        classes.push('font-weight-normal')
      }

      return classes
    }
  },
  methods: {
    /**
     * Toggle thread group visibility
     */
    toggleVisibility () {
      this.visible = !this.visible
    },

    /**
     * Open thread for selected application
     * @param thread
     */
    openThread (thread) {
      this.$emit('click', thread)
    }
  }
}
