//
//
//
//
//
//
//
//
//

import LoaderRing from './LoaderRing.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LoadingOverlay',
  components: { LoaderRing },
  props: {
    visible: {
      type: Boolean,
      default: false
    },
    text: {
      type: String,
      default: null
    }
  }
}
