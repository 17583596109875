import { applicationClientFlag, applicationStatuses } from 'ct-constants'
import { orderBy } from 'lodash'

export const state = () => ({
  taskId: null,
  applications: [],
  selectedApplicationId: null,
  flags: [applicationClientFlag.DEFAULT, applicationClientFlag.STARRED]
})

export const actions = {
  /**
   * Fetch applications from API only if task ID is changed
   * @param commit
   * @param state
   * @param getters
   * @param {Number} taskId
   * @param {Boolean} force
   * @returns {Promise<void>}
   */
  async fetchApplications ({
    commit,
    state,
    getters
  }, taskId, force) {
    commit('setTaskId', taskId)
    let applications = []
    if (!getters.loadedApplicationTaskIds.includes(taskId) || force) {
      const { data } = await this.$axios.get(`/tasks/${taskId}/applications`)
      applications = data.data
      commit('addApplications', applications)
    }
  },

  /**
   * Set application status to hired
   * @param commit
   * @param {Number} applicationId
   * @returns {Promise<void>}
   */
  async hire ({ commit }, applicationId) {
    await this.$axios.put(`/applications/${applicationId}/hire`)
    commit('hireFreelancer', applicationId)
  },

  /**
   * Create client feedback on application
   * @param commit
   * @param {Object} feedbackData
   * @param {Number} feedbackData.status
   * @param {Number} feedbackData.applicationId
   * @param {Number} feedbackData.rate
   * @param {String} feedbackData.message
   * @returns {Promise<Object>}
   */
  async createFeedback ({ commit }, feedbackData) {
    const { data } = await this.$axios.post('/feedbacks', feedbackData)
    if (feedbackData.status) {
      commit('setStatus', {
        status: feedbackData.status,
        applicationId: feedbackData.applicationId
      })
    }
    commit('setFeedback', data.data)
    return data.data
  },

  /**
   * Update application flag
   * @param commit
   * @param {Number} applicationId
   * @param {Number} flag
   * @returns {Promise<void>}
   */
  async updateFlag ({ commit }, {
    applicationId,
    flag
  }) {
    await this.$axios.put(`/applications/${applicationId}/flag`, { flag })
    commit('setApplicationFlag', {
      applicationId,
      flag
    })
  },

  /**
   * Toggle recommendation flag on application
   * @param commit
   * @param {Number} applicationId
   * @returns {Promise<void>}
   */
  async toggleRecommendation ({ commit }, {
    applicationId
  }) {
    await this.$axios.put(`/applications/${applicationId}/toggle-recommended`)
    commit('setApplicationRecommendation', {
      applicationId
    })
  },

  /**
   * Toggle application recommendation flag
   * Invoked by socket emit
   * @param commit
   * @param {Object} application
   */
  socket_applicationRecommended ({ commit }, application) {
    commit('setApplicationRecommendation', {
      applicationId: application.id
    })
  },

  /**
   * Socket handler for creating new applications
   * @param commit
   * @param {Object} application
   */
  socket_applicationCreated ({ commit }, application) {
    commit('addApplications', [application])
  },

  /**
   * Socket handler
   * Triggered when freelancer left his feedback
   * @param commit
   * @param {Object} feedback
   */
  socket_freelancerFeedback ({ commit }, feedback) {
    commit('setFeedback', feedback)
  },

  /**
   * Socket handler
   * Triggered when application status is changed
   * @param commit
   * @param {Object} application
   */
  socket_applicationStatus ({ commit }, application) {
    commit('setStatus', {
      applicationId: application.id,
      status: application.status
    })
  }
}

export const mutations = {
  /**
   * Set task ID
   * @param state
   * @param {Number} taskId
   */
  setTaskId (state, taskId) {
    state.taskId = taskId
  },

  /**
   * Deselect task ID
   * @param state
   */
  deselectTaskId (state) {
    state.taskId = null
  },

  /**
   * Add applications to already loaded ones
   * @param state
   * @param {Object[]} applications
   * TODO filter existing ones
   */
  addApplications (state, applications) {
    state.applications = [...state.applications, ...applications]
  },

  /**
   * Select one of the loaded applications
   * @param state
   * @param {Number} applicationId
   */
  selectApplication (state, applicationId) {
    state.selectedApplicationId = applicationId
  },

  /**
   * Deselect any applications
   * @param state
   */
  unselectApplication (state) {
    state.selectedApplicationId = null
  },

  /**
   * Set one of applications status to hired
   * @param state
   * @param {Number} applicationId
   */
  hireFreelancer (state, applicationId) {
    state.applications = [...state.applications.map((a) => {
      const app = { ...a }
      if (a.id === applicationId) {
        app.status = applicationStatuses.ACCEPTED
      }
      return app
    })]
  },

  /**
   * Set status on applications
   * @param state
   * @param {Number} applicationId
   * @param {Number} status
   */
  setStatus (state, {
    applicationId,
    status
  }) {
    state.applications = [...state.applications.map((a) => {
      const app = { ...a }
      if (a.id === applicationId) {
        app.status = status
      }
      return app
    })]
  },

  /**
   * Set feedback on one of applications
   * @param state
   * @param {Object} feedback
   * @param {Number} feedback.applicationId
   */
  setFeedback (state, feedback) {
    state.applications = state.applications.map((a) => {
      const app = { ...a }
      if (app.id === feedback.applicationId) {
        app.feedback = feedback
      }
      return app
    })
  },

  /**
   * Set flags for fetching applications
   * @param state
   * @param {Number[]}flags
   */
  setFlags (state, flags) {
    state.flags = flags
  },

  /**
   * Set flag on selected application
   * @param state
   * @param {Number} applicationId
   * @param {Number} flag
   */
  setApplicationFlag (state, {
    applicationId,
    flag
  }) {
    state.applications = [...state.applications.map((a) => {
      const app = { ...a }
      if (app.id === applicationId) {
        app.clientFlag = flag
      }
      return app
    })]
  },

  /**
   * Toggle recommendation flag
   * @param state
   * @param {Number} applicationId
   */
  setApplicationRecommendation (state, { applicationId }) {
    state.applications = [...state.applications.map((a) => {
      const app = { ...a }
      if (app.id === applicationId) {
        app.recommended = !app.recommended
      }
      return app
    })]
  }
}

export const getters = {
  /**
   * Get only applications for selected task
   * @param state
   * @returns {*[]}
   */
  taskApplications (state) {
    return orderBy(state.applications.filter(a => a.taskId === state.taskId).map(a => ({
      ...a,
      recommendedSort: a.recommended ? 1 : 0,
      visibility: a.freelancer.visibility
    })), ['visibility', 'recommendedSort', 'createdAt'], ['desc', 'desc', 'desc'])
  },

  /**
   * Get list of unique task IDs whose applications have been loaded
   * @param state
   * @returns {(null|Number)[]}
   */
  loadedApplicationTaskIds (state) {
    return state.applications.map(a => a.taskId).filter((value, index, self) => {
      return self.indexOf(value) === index
    })
  },

  /**
   * get filtered applications depending on set flags
   * @param state
   * @param getters
   * @returns {Object[]}
   */
  filteredApplications (state, getters) {
    return getters.taskApplications.filter(a => state.flags.includes(a.clientFlag))
  },

  /**
   * Get selected application by selected application id
   * @param state
   * @param getters
   * @returns {*}
   */
  selectedApplication (state, getters) {
    return getters.taskApplications.find(a => a.id === state.selectedApplicationId)
  },

  /**
   * Get freelancer from selected application
   * @param state
   * @param getters
   * @returns {*}
   */
  selectedApplicationFreelancer (state, getters) {
    return getters.selectedApplication?.freelancer
  },

  /**
   * Is currently selected application freelancer active
   * @param state
   * @param getters
   * @returns {Boolean}
   */
  isSelectedApplicationFreelancerActive (state, getters) {
    return !getters.selectedApplicationFreelancer?.deletedAt && getters.selectedApplicationFreelancer?.published && getters.selectedApplicationFreelancer?.confirmed
  },

  /**
   * Get all task applications that don't have status created
   * @param state
   * @param getters
   * @returns {Object[]}
   */
  taskApplicationHires (state, getters) {
    return getters.taskApplications.filter(a => a.status > applicationStatuses.CREATED)
  },

  /**
   * Get only hired applications
   * @param state
   * @param getters
   * @returns {Object[]}
   */
  hiredApplications (state, getters) {
    return getters.filteredApplications.filter(a => a.status === applicationStatuses.ACCEPTED)
  },

  /**
   * Get all applications except hired ones
   * @param state
   * @param getters
   * @returns {Object[]}
   */
  notHiredApplications (state, getters) {
    return getters.filteredApplications.filter(a => a.status !== applicationStatuses.ACCEPTED)
  },

  /**
   * Just recommended applications
   * @param state
   * @param getters
   * @returns {*}
   */
  recommendedApplications (state, getters) {
    return getters.taskApplications.filter(a => a.recommended)
  }
}
