export const state = () => ({
  notifications: [],
  popoverDisplayed: false
})

export const actions = {
  /**
   * Get notifications
   * @param commit
   * @return {Promise<void>}
   */
  async getNotifications ({ commit }) {
    const notifications = await this.$axios.get('/notifications')
    commit('setNotifications', notifications.data.data)
  },

  /**
   * Socket triggered action when new notification is received
   * @param commit
   * @param {Object} notification
   */
  socket_receivedNotification ({ commit }, notification) {
    commit('setNotification', notification)
  },

  /**
   * Set notification as seen
   * @param commit
   * @param {Object} notification
   * @return {Promise<void>}
   */
  async seeNotification ({ commit }, notification) {
    await this.$axios.put(`/notifications/${notification.id}/seen`)
    commit('removeNotification', notification)
  }
}

export const mutations = {
  /**
   * Set notifications to store
   * @param state
   * @param {Array} notifications
   */
  setNotifications (state, notifications) {
    state.notifications = notifications
  },

  /**
   * Add new notification or update old one with new payload
   * @param state
   * @param {Object} notification
   */
  setNotification (state, notification) {
    const index = state.notifications.findIndex(n => n.id === notification.id)

    if (index > -1) {
      Object.assign(state.notifications[index], { ...notification })
    } else {
      state.notifications.push(notification)
    }
  },

  /**
   * Remove notification from state
   * @param state
   * @param {Object} notification
   */
  removeNotification (state, notification) {
    const index = state.notifications.findIndex(n => n.id === notification.id)

    if (index > -1) {
      state.notifications.splice(index, 1)
    }
  },

  /**
   * Show notifications popover
   * @param state
   */
  showPopover (state) {
    state.popoverDisplayed = true
  },

  /**
   * Hide notifications popover
   * @param state
   */
  hidePopover (state) {
    state.popoverDisplayed = false
  }
}

export const getters = {}
