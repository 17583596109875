import Vue from 'vue'
import axios from 'axios'
import * as Bip39 from 'bip39'
import { TezosToolkit } from '@taquito/taquito'
import { InMemorySigner } from '@taquito/signer'
import { RpcClient } from '@taquito/rpc'
import { blake2b } from 'blakejs'

const KEYPAIRS_KEY = 'keypairs_key'
const langMappings = {
  en: 1,
  hr: 2,
  es: 3,
  vi: 4
}

class SmartContract {
  constructor (options) {
    this._options = options
    this._bcData = null
    this._keypairs = null
  }

  /**
   * Calculate 256bits Blake2b hash of `input`
   * @rtype (input: String) => hash: String
   * @param {Data} input - Data to hash
   * @return {Buffer} Hash
   */
  hash (input) {
    return Buffer.from(blake2b(input, null, 32)) // 256 bits
  }

  /**
   * Get BC data
   * @return {null}
   */
  getBcData () {
    return this._bcData
  }

  /**
   * Set BC data
   * @param data
   */
  setBcData (data) {
    this._bcData = data
  }

  /**
   * Get keypairs from local storage or instance
   * @return {Object}
   */
  getKeypairs () {
    if (!this._keypairs) {
      try {
        const _keypairsData = localStorage.getItem(KEYPAIRS_KEY)
        if (_keypairsData) {
          this._keypairs = JSON.parse(_keypairsData)
        }
        return this._keypairs
      } catch (err) {
        return this._keypairs
      }
    }
    return this._keypairs
  }

  /**
   * Set keypairs to local storage and instance
   * @param keypairs
   */
  setKeypairs (keypairs) {
    this._keypairs = keypairs
    localStorage.setItem(KEYPAIRS_KEY, JSON.stringify(keypairs))
  }

  /**
   * Remove keypairs from local storage
   */
  removeKeypairs () {
    localStorage.removeItem(KEYPAIRS_KEY)
  }

  /**
   * Create keypairs
   * @param email
   * @param password
   */
  createKeypairs ({
    email,
    password
  }) {
    // use email+pwd as seed to generate private key
    const seed = Bip39.mnemonicToSeedSync(email + password)
    const keypairs = {
      seed
    }
    this.setKeypairs(keypairs)
  }

  /**
   * Create BC data
   * @return {Promise<void>}
   */
  async createBcData () {
    const keypairs = this.getKeypairs()

    if (keypairs?.seed) {
      const tezos = new TezosToolkit(this._options.tezosUrl)
      tezos.setProvider({ signer: InMemorySigner.fromFundraiser('', '', Buffer.from(keypairs.seed).toString('hex')) })
      const tezosRpcClient = new RpcClient(this._options.tezosUrl, this._options.tezosNetworkId)
      const pubkey = await tezos.signer.publicKey()

      this.setBcData({
        tezos,
        tezosRpcClient,
        pubkey
      })
    }
  }

  async getNonce (pubkey) {
    try {
      const resNonce = await axios.get(`${this._options.tezosReadApi}contracts/${this._options.tezosContract}/bigmaps/nonces/keys/${pubkey}`)
      let nonce = resNonce.data
      if (!nonce) {
        nonce = 0
      } else {
        nonce = nonce.value
      }
      console.log('nonce: ' + nonce)
      return nonce
    } catch (err) {
      console.log('nonce not fetched')
      return 0
    }
  }

  /**
   * Update freelancer properties with smart contract properties
   * @param {Object} freelancerData
   * @param {string} langCode
   * @return {Promise<any>}
   */
  async setFreelancerProperties (freelancerData, langCode) {
    const langIdBc = langMappings[langCode]

    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const flancerInfoHash = this.createFlancerInfoHash(freelancerData)
    const nonce = await this.getNonce(bcData.pubkey)

    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'signUp' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'string': '' }, {
                      'prim': 'Pair',
                      'args': [{ 'string': flancerInfoHash }, {
                        'prim': 'Pair',
                        'args':
                          [{ 'int': '1' }, { 'int': langIdBc.toString() }]
                      }]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'string' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'string' }, {
                      'prim': 'pair',
                      'args': [{ 'prim': 'int' }, { 'prim': 'int' }]
                    }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, freelancerData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      flancerInfoHash,
      langIdBc
    })
  }

  /**
   * createFlancerInfoHash
   * @param {Object} freelancerData
   * @return {String} flancerInfoHash
   */
  createFlancerInfoHash (freelancerData) {
    return Buffer.from(this.hash([freelancerData.name, freelancerData.bio].join('')))
      .toString('hex')
  }

  /**
   * Update edit freelancer properties with smart contract properties
   * @param {Object} freelancerData
   * @return {Promise<any>}
   */
  async setEditFreelancerProperties (freelancerData) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const flancerInfoHash = this.createFlancerInfoHash(freelancerData)
    const nonce = await this.getNonce(bcData.pubkey)

    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'editProfile' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'string': '' }, {
                      'prim': 'Pair',
                      'args': [{ 'string': flancerInfoHash }, { 'int': '1' }]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'string' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'string' }, { 'prim': 'int' }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, freelancerData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      flancerInfoHash
    })
  }

  /**
   * Update client properties with smart contract properties
   * @param {Object} clientData
   * @param {string} langCode
   * @return {Promise<any>}
   */
  async setClientProperties (clientData, langCode) {
    const langIdBc = langMappings[langCode]

    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const clientInfoHash = this.createClientInfoHash(clientData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'signUp' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'string': clientInfoHash }, {
                      'prim': 'Pair',
                      'args': [{ 'string': '' }, {
                        'prim': 'Pair',
                        'args':
                          [{ 'int': '1' }, { 'int': langIdBc.toString() }]
                      }]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'string' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'string' }, {
                      'prim': 'pair',
                      'args': [{ 'prim': 'int' }, { 'prim': 'int' }]
                    }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, clientData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      clientInfoHash,
      langIdBc
    })
  }

  /**
   * createClientInfoHash
   * @param {Object} clientData
   * @return {String} clientInfoHash
   */
  createClientInfoHash (clientData) {
    return Buffer.from(this.hash([clientData.name, clientData.about].join('')))
      .toString('hex')
  }

  /**
   * Update edit client properties with smart contract properties
   * @param {Object} clientData
   * @return {Promise<any>}
   */
  async setEditClientProperties (clientData) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const clientInfoHash = this.createClientInfoHash(clientData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'editProfile' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'string': clientInfoHash }, {
                      'prim': 'Pair',
                      'args': [{ 'string': '' }, { 'int': '1' }
                      ]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'string' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'string' }, { 'prim': 'int' }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, clientData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      clientInfoHash
    })
  }

  /**
   * Update task properties with smart contract properties
   * @param {Object} taskData
   * @param {string} langCode
   * @return {Promise<any>}
   */
  async setTaskProperties (taskData, langCode) {
    const langIdBc = langMappings[langCode]

    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const taskInfoHash = this.createTaskInfoHash(taskData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'postTask' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'string': taskInfoHash }, { 'int': langIdBc.toString() }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'string' }, { 'prim': 'int' }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, taskData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskInfoHash,
      langIdBc
    })
  }

  /**
   * createTaskInfoHash
   * @param {Object} taskData
   * @return {String} taskInfoHash
   */
  createTaskInfoHash (taskData) {
    return Buffer.from(this.hash([taskData.title, taskData.description].join('')))
      .toString('hex')
  }

  /**
   * Update edit task properties with smart contract properties
   * @param {Object} taskData
   * @return {Promise<any>}
   */
  async setEditTaskProperties (taskData) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const taskInfoHash = this.createTaskInfoHash(taskData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'editTask' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'int': taskData.bcId.toString() }, { 'string': taskInfoHash }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'int' }, { 'prim': 'string' }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return Object.assign({}, taskData, {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskInfoHash
    })
  }

  /**
   * Update application properties with smart contract properties
   * @param {Number} taskBcId
   * @return {Promise<any>}
   */
  async setApplicationProperties (taskBcId) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const nonce = await this.getNonce(bcData.pubkey)

    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'applyForTask' },
                  { 'int': taskBcId.toString() }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, { 'prim': 'int' }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId
    }
  }

  /**
   * Update application hire properties with smart contract properties
   * @param {Number} taskBcId
   * @param {Number} flancerBcId
   * @return {Promise<any>}
   */
  async setHireProperties (taskBcId, flancerBcId) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'acceptForTask' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'int': taskBcId.toString() }, { 'int': flancerBcId.toString() }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'int' }, { 'prim': 'int' }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return {
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      flancerBcId
    }
  }

  /**
   * Update finalize feedback properties with smart contract properties
   * @param {Number} feedbackId
   * @param {Number} taskBcId
   * @param {Number} flancerBcId
   * @param {Number} rate
   * @param {String} message
   * @return {Promise<any>}
   */
  async setFinalizeProperties (feedbackId, taskBcId, flancerBcId, rate, message) {
    const messageHash = Buffer.from(this.hash(message)).toString('hex')

    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'finalize' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'int': taskBcId.toString() }, {
                      'prim': 'Pair',
                      'args': [{ 'int': flancerBcId.toString() }, {
                        'prim': 'Pair',
                        'args':
                          [{ 'int': rate.toString() }, { 'string': messageHash }]
                      }]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'int' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'int' }, {
                      'prim': 'pair',
                      'args': [{ 'prim': 'int' }, { 'prim': 'string' }]
                    }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return {
      feedbackId,
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      flancerBcId,
      rate,
      messageHash
    }
  }

  /**
   * Update client cancel feedback properties with smart contract properties
   * @param {Number} feedbackId
   * @param {Number} taskBcId
   * @param {Number} flancerBcId
   * @param {Number} rate
   * @param {String} message
   * @return {Promise<any>}
   */
  /* async setCancelContractClientProperties (feedbackId, taskBcId, flancerBcId, rate, message) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const resNonce = await bcData.contractLogic.methods.getNonce(bcData.keypairFormatted.publicKey)
    const nonce = resNonce.decodedResult
    console.log('nonce', nonce)

    const messageHash = Buffer.from(Crypto.hash(message)).toString('hex')

    const args = `${this._options.bcLogicVersion}${nonce.toString()}cancelContractClient${taskBcId}${flancerBcId}${rate}${messageHash}`
    console.log(args)
    const hash = Crypto.hash(args)
    console.log('hash', hash)
    const signedHash = Crypto.sign(hash, bcData.keypair.secretKey)
    console.log(signedHash)
    const sig = Buffer.from(signedHash)
      .toString('hex')
    console.log(sig)

    return {
      feedbackId,
      publicKey: bcData.keypairFormatted.publicKey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      flancerBcId,
      rate,
      messageHash
    }
  } */

  /**
   * Update client leave feedback properties with smart contract properties
   * @param {Number} feedbackId
   * @param {Number} taskBcId
   * @param {Number} flancerBcId
   * @param {Number} rate
   * @param {String} message
   * @return {Promise<any>}
   */

  /* async setLeaveFeedbackClientProperties (feedbackId, taskBcId, flancerBcId, rate, message) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const resNonce = await bcData.contractLogic.methods.getNonce(bcData.keypairFormatted.publicKey)
    const nonce = resNonce.decodedResult
    console.log('nonce', nonce)

    const messageHash = Buffer.from(Crypto.hash(message)).toString('hex')

    const args = `${this._options.bcLogicVersion}${nonce.toString()}leaveFeedbackClient${taskBcId}${flancerBcId}${rate}${messageHash}`
    console.log(args)
    const hash = Crypto.hash(args)
    console.log('hash', hash)
    const signedHash = Crypto.sign(hash, bcData.keypair.secretKey)
    console.log(signedHash)
    const sig = Buffer.from(signedHash)
      .toString('hex')
    console.log(sig)

    return {
      feedbackId,
      publicKey: bcData.keypairFormatted.publicKey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      flancerBcId,
      rate,
      messageHash
    }
  } */

  /**
   * Update flancer leave feedback properties with smart contract properties
   * @param {Number} feedbackId
   * @param {Number} taskBcId
   * @param {Number} rate
   * @param {String} message
   * @return {Promise<any>}
   */
  async setLeaveFeedbackFlancerProperties (feedbackId, taskBcId, rate, message) {
    const messageHash = Buffer.from(this.hash(message)).toString('hex')

    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const nonce = await this.getNonce(bcData.pubkey)
    /* eslint-disable */
    const packedData = await bcData.tezosRpcClient.packData(
      {
        data: {
          'prim': 'Pair',
          'args': [{ 'int': this._options.bcLogicVersion.toString() },
            {
              'prim': 'Pair',
              'args': [{ 'int': nonce.toString() }, {
                'prim': 'Pair',
                'args': [{ 'string': 'leaveFeedbackFlancer' }, {
                  'prim': 'Pair',
                  'args':
                    [{ 'int': taskBcId.toString() }, {
                      'prim': 'Pair',
                      'args': [{ 'int': rate.toString() },
                        { 'string': messageHash }]
                    }]
                }]
              }]
            }]
        },
        type: {
          'prim': 'pair',
          'args': [{ 'prim': 'int' }, {
            'prim': 'pair',
            'args': [{ 'prim': 'int' }, {
              'prim': 'pair',
              'args': [{ 'prim': 'string' }, {
                'prim': 'pair',
                'args': [{ 'prim': 'int' }, {
                  'prim': 'pair',
                  'args':
                    [{ 'prim': 'int' }, { 'prim': 'string' }]
                }]
              }]
            }]
          }]
        }
      })
    /* eslint-enable */
    const sig = await bcData.tezos.signer.sign(packedData.packed)

    return {
      feedbackId,
      pubkey: bcData.pubkey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      rate,
      messageHash
    }
  }

  /**
   * Update flancer cancel contract feedback properties with smart contract properties
   * @param {Number} feedbackId
   * @param {Number} taskBcId
   * @param {Number} rate
   * @param {String} message
   * @return {Promise<any>}
   */
  /* async setCancelContractFlancerProperties (feedbackId, taskBcId, rate, message) {
    const bcData = this.getBcData()
    console.log('bcdata', bcData)
    const resNonce = await bcData.contractLogic.methods.getNonce(bcData.keypairFormatted.publicKey)
    const nonce = resNonce.decodedResult
    console.log('nonce', nonce)

    const messageHash = Buffer.from(Crypto.hash(message)).toString('hex')

    const args = `${this._options.bcLogicVersion}${nonce.toString()}cancelContractFlancer${taskBcId}${rate}${messageHash}`
    console.log(args)
    const hash = Crypto.hash(args)
    console.log('hash', hash)
    const signedHash = Crypto.sign(hash, bcData.keypair.secretKey)
    console.log(signedHash)
    const sig = Buffer.from(signedHash)
      .toString('hex')
    console.log(sig)

    return {
      feedbackId,
      publicKey: bcData.keypairFormatted.publicKey,
      sig,
      logicVersion: this._options.bcLogicVersion,
      nonce,
      taskBcId,
      rate,
      messageHash
    }
  } */
}

const SmartContractPlugin = {}
SmartContractPlugin.install = function (Vue, options) {
  Vue.prototype.$smartContract = new SmartContract(options)
}

export default function (store) {
  Vue.use(SmartContractPlugin, {
    bcURL: process.env.bcURL,
    bcAddressStore: process.env.bcAddressStore,
    bcAddressLogic: process.env.bcAddressLogic,
    bcLogicVersion: process.env.bcLogicVersion,
    tezosUrl: process.env.tezosUrl,
    tezosNetworkId: process.env.tezosNetworkId,
    tezosContract: process.env.tezosContract,
    tezosReadApi: process.env.tezosReadApi
  })
  const keypairs = Vue.prototype.$smartContract.getKeypairs()
  if (keypairs) {
    Vue.prototype.$smartContract.createBcData()
      .then(() => {
        store.store.commit('user/activateBcData')
      })
  }
}
