//
//
//
//
//
//
//

import LogoFreelanceHr from '@/components/layout/LogoFreelanceHr'
import LogoCryptotaskOrg from '@/components/layout/LogoCryptotaskOrg'

export default {
  name: 'LogoImage',
  components: {
    LogoCryptotaskOrg,
    LogoFreelanceHr
  },
  computed: {
    /**
     * Current language
     * @return string
     */
    currentLanguage () {
      return this.$store.state.i18n.locale
    }
  }
}
