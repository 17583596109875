import Vue from 'vue'
import vuescroll from 'vuescroll'
import 'vuescroll/dist/vuescroll.css'

Vue.use(vuescroll, {
  ops: {
    bar: {
      onlyShowBarOnScroll: false,
      keepShow: true
    }
  }
})
