//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import FreelancerThreads from '@/components/tasks/chat/FreelancerThreads'
import ClientThreads from '@/components/tasks/chat/ClientThreads'
import ChatHistoryHeader from '@/components/tasks/chat/ChatHistoryHeader'
import Access from '@/components/auth/Access'
import { roleNames } from 'ct-constants'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ChatHistory',
  components: {
    Access,
    FreelancerThreads,
    ClientThreads,
    ChatHistoryHeader
  },
  data () {
    return {
      canAccess: [roleNames.CLIENT, roleNames.FREELANCER],
      scrollOptions: {
        vuescroll: {
          sizeStrategy: 'number',
          detectResize: true
        },
        scrollPanel: {
          scrollingX: false
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', [
      'isFreelancer',
      'isClient'
    ]),
    loading () {
      return this.$store.state.chat.loadingThreads
    }
  },
  mounted () {
    window.addEventListener('resize', this.windowResized)
  },
  unmounted () {
    window.removeEventListener('resize', this.windowResized)
  },
  methods: {
    windowResized () {
      if (this.$refs && this.$refs['ch-scroll']) {
        this.$refs['ch-scroll'].refresh()
      }
    }
  }
}
