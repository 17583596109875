export const state = () => ({
  mainLoader: false,
  notificationText: null,
  notificationType: null,
  isDarkThemeEnabled: false
})

export const mutations = {
  /**
   * Show main loader
   * @param state
   */
  showLoader (state) {
    state.mainLoader = true
  },

  /**
   * Hide main loader
   * @param state
   */
  hideLoader (state) {
    state.mainLoader = false
  },

  /**
   * Show alert notification
   * @param state
   * @param {String} text
   * @param {String} [type] - optional
   */
  showNotification (state, { text, type }) {
    state.notificationText = text
    state.notificationType = type || 'success'
  },

  /**
   * Hide notification
   * @param state
   */
  hideNotification (state) {
    state.notificationText = null
    state.notificationType = null
  },

  /**
   * Enable dark theme
   * @param state
   */
  enableDarkTheme (state) {
    state.isDarkThemeEnabled = true
  },
  /**
   * Disable dark theme
   * @param state
   */
  disableDarkTheme (state) {
    state.isDarkThemeEnabled = false
  }
}

export const actions = {
  /**
   * Show notification with auto-close function
   * @param commit
   * @param {Object} data
   * @param {String} data.text
   * @param {String} data.type
   * @param {Number} data.expiry
   */
  showNotification ({ commit }, data) {
    commit('showNotification', data)

    setTimeout(() => {
      commit('hideNotification')
    }, data.expiry || 5000)
  }
}
