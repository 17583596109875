import { roleNames } from 'ct-constants'
import capitalize from 'lodash/capitalize'

export const state = () => ({
  bcDataSet: false
})

export const actions = {
  /**
   * Register user
   * @param commit
   * @param {Object} data
   * @return {*}
   */
  // eslint-disable-next-line no-unused-vars
  register ({ commit }, data) {
    return this.$axios.post('auth/register', data)
  },

  /**
   * Create blank freelancer profile
   * @returns {Promise<void>}
   */
  async createFreelancer () {
    const user = { ...this.$auth.user }
    const res = await this.$axios.post('/freelancers')

    this.$auth.setUser(Object.assign({}, user, {
      freelancer: res.data.data
    }))
  },

  /**
   * Create blank client profile
   * @returns {Promise<void>}
   */
  async createClient () {
    const user = { ...this.$auth.user }
    const res = await this.$axios.post('/clients')

    this.$auth.setUser(Object.assign({}, user, {
      client: res.data.data
    }))
  },

  /**
   * Create blank agent profile
   * @returns {Promise<void>}
   */
  async createAgent () {
    const user = { ...this.$auth.user }
    const res = await this.$axios.post('/agents')

    this.$auth.setUser(Object.assign({}, user, {
      agent: res.data.data
    }))
  },

  /**
   * Change current user role
   * @returns {Promise<boolean>}
   */
  async switchRole ({ dispatch }, role) {
    const res = await this.$axios.put('/users/change-role', { role })
    this.$auth.setUser(res.data.data)

    // call actions from other modules to restart state in some cases
    dispatch('chat/getThreads', null, { root: true })
  },

  /**
   * Update freelancer basic data
   * @param commit
   * @param state
   * @param rootState
   * @param {Object} data
   * @return {Promise<void>}
   */
  async updateFreelancerBasicInfo ({
    commit,
    state,
    rootState
  }, data) {
    const freelancerData = await this.$axios.put('/freelancers', data.data)
    const user = { ...this.$auth.user }
    user.freelancer = Object.assign({}, user.freelancer, freelancerData.data.data)

    this.$auth.setUser(user)

    if (data.caller === 'update') {
      this._vm.$smartContract.setEditFreelancerProperties(user.freelancer)
        .then(async (bcFreelancer) => {
          console.log(bcFreelancer)
          try {
            const resBc = await this.$axios.put('/freelancers/regBcEditFreelancer', bcFreelancer)
            console.log(resBc.data)
          } catch (err) {
            console.error(err.response ? err.response.data : err)
          }
        })
    }
  },

  /**
   * Update agent role data
   * @param commit
   * @param state
   * @param rootState
   * @param {Object} data
   * @returns {Promise<void>}
   */
  async updateAgentBasicInfo ({
    commit,
    state,
    rootState
  }, data) {
    const agentData = await this.$axios.put('/agents', data)
    const user = { ...this.$auth.user }
    user.agent = Object.assign({}, user.agent, agentData.data.data)

    this.$auth.setUser(user)
  },

  /**
   * Update freelancer skills and categories
   * @param commit
   * @param rootState
   * @param data
   * @return {Promise<void>}
   */
  async updateFreelancerSkills ({
    commit,
    rootState
  }, data) {
    await this.$axios.put('/freelancers/skills', data)

    const user = { ...this.$auth.user }
    user.freelancer = {
      ...user.freelancer,
      skills: data.skills,
      categories: data.categories
    }

    this.$auth.setUser(user)

    this._vm.$smartContract.setFreelancerProperties(user.freelancer, rootState.i18n.locale)
      .then(async (bcFreelancer) => {
        console.log(bcFreelancer)
        try {
          const resBc = await this.$axios.put('/freelancers/regBcFreelancer', bcFreelancer)
          console.log(resBc.data)
        } catch (err) {
          console.error(err.response ? err.response.data : err)
        }
      })
  },

  /**
   * Update client basic data
   * @param commit
   * @param rootState
   * @param state
   * @param {Object} data
   * @return {Promise<void>}
   */
  async updateClientBasicInfo ({
    commit,
    rootState,
    state
  }, data) {
    const clientData = await this.$axios.put('/clients', data.data)

    const user = { ...this.$auth.user }
    user.client = Object.assign({}, user.client, clientData.data.data)

    this.$auth.setUser(user)

    if (data.caller === 'create') {
      this._vm.$smartContract
        .setClientProperties(user.client, rootState.i18n.locale)
        .then(async (bcClient) => {
          console.log(bcClient)
          try {
            const resBc = await this.$axios.put('/clients/regBcClient', bcClient)
            console.log(resBc.data)
          } catch (err) {
            console.error(err.response ? err.response.data : err)
          }
        })
    } else if (data.caller === 'update') {
      this._vm.$smartContract.setEditClientProperties(user.client)
        .then(async (bcClient) => {
          console.log(bcClient)
          try {
            const resBc = await this.$axios.put('/clients/regBcEditClient', bcClient)
            console.log(resBc.data)
          } catch (err) {
            console.error(err.response ? err.response.data : err)
          }
        })
    }
  },

  /**
   * Subscribe user to newsletter
   * @param commit
   * @param status
   * @return {Promise<void>}
   */
  async newsletterToggleSubscription ({ commit }, status) {
    await this.$axios.post('/users/toggle-subscription', {
      subscribed: status
    })

    const user = { ...this.$auth.user }
    user.emailSubscribed = status

    this.$auth.setUser(user)
  },

  /**
   * Delete user
   * @param commit
   * @param dispatch
   * @return {Promise<void>}
   */
  async removeUser ({ dispatch }) {
    await this.$axios.delete('/users')
    await this.$auth.logout()
  },

  /**
   * Update user languages
   * @param store
   * @param languages
   * @returns {Promise<void>}
   */
  async updateUserLanguages (store, languages) {
    const newUser = await this.$axios.put('/users/languages', { languages })
    this.$auth.setUser(newUser.data.data)
  }
}

export const mutations = {
  /**
   * When bcData is set then set this flag to true for other components to know
   * @param state
   */
  activateBcData (state) {
    state.bcDataSet = true
  }
}

export const getters = {
  /**
   * Get current user
   * @param state
   * @param getters
   * @param rootState
   * @returns {*}
   */
  getCurrentUser (state, getters, rootState) {
    return rootState.auth.user
  },

  /**
   * Get currently logged user ID
   * @param state
   * @param getters
   * @param rootState
   * @return {*}
   */
  getCurrentUserId (state, getters, rootState) {
    if (rootState.auth.loggedIn && rootState.auth.user) {
      return rootState.auth.user.id
    }

    return null
  },

  /**
   * Get user name depending on active role
   * @param state
   * @param getters
   * @param rootState
   * @return {*}
   */
  getUserName (state, getters, rootState) {
    if (rootState.auth.loggedIn && rootState.auth.user) {
      const { email } = rootState.auth.user
      const roleData = getters.getActiveRoleData
      return roleData && roleData.name ? roleData.name : email
    }

    return ''
  },

  /**
   * Get current user role type
   * @param state
   * @param getters
   * @param rootState
   * @param rootGetters
   * @returns {null|*}
   */
  getActiveRoleType (state, getters, rootState, rootGetters) {
    if (rootState.auth.loggedIn && rootState.auth.user) {
      return getters.getCurrentUser?.activeRole
    }

    return null
  },

  /**
   * Get name of current users active role
   * @param state
   * @param getters
   * @returns {*}
   */
  getActiveRoleTypeName (state, getters) {
    return getters.getActiveRoleType?.name
  },

  /**
   * Is current user active role freelancer
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isFreelancer (state, getters) {
    return getters.getActiveRoleTypeName === roleNames.FREELANCER
  },

  /**
   * Is current user active role client
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isClient (state, getters) {
    return getters.getActiveRoleTypeName === roleNames.CLIENT
  },

  /**
   * Is current user active role agent
   * @param state
   * @param getters
   * @returns {boolean}
   */
  isAgent (state, getters) {
    return getters.getActiveRoleTypeName === roleNames.AGENT
  },

  /**
   * Get current user role data
   * @param state
   * @param getters
   * @param rootState
   * @returns {null|*}
   */
  getActiveRoleData (state, getters, rootState) {
    if (rootState.auth.loggedIn && rootState.auth.user) {
      const activeRoleName = getters.getActiveRoleTypeName
      return activeRoleName ? rootState.auth.user[activeRoleName] : null
    }

    return null
  },

  /**
   * Get role profile about, bio for freelancer and about for client
   * @param state
   * @param getters
   * @returns {string|string|*|null|(function(): (string|string))}
   */
  getProfileDescription (state, getters) {
    const profile = getters.getActiveRoleData

    if (getters.isFreelancer) {
      return profile.bio
    }

    if (getters.isClient || getters.isAgent) {
      return profile.about
    }

    return ''
  },

  /**
   * Get freelancer profile from user
   * @param state
   * @param getters
   * @param rootState
   * @return {null}
   */
  getFreelancer (state, getters, rootState) {
    return rootState.auth?.user?.freelancer
  },

  /**
   * Get client profile from current user
   * @param state
   * @param getters
   * @param rootState
   * @returns {*|null}
   */
  getClient (state, getters, rootState) {
    return rootState.auth?.user?.client
  },

  /**
   * Get agent profile from current user
   * @param state
   * @param getters
   * @param rootState
   * @returns {*|null}
   */
  getAgent (state, getters, rootState) {
    return rootState.auth?.user?.agent
  },

  /**
   * Check if current user has freelancer profile
   * @param state
   * @param getters
   * @returns {boolean}
   */
  hasFreelancer (state, getters) {
    return !!getters.getFreelancer
  },

  /**
   * Check if current user has client profile
   * @param state
   * @param getters
   * @returns {boolean}
   */
  hasClient (state, getters) {
    return !!getters.getClient
  },

  /**
   * Check if current user has agent profile
   * @param state
   * @param getters
   * @returns {boolean}
   */
  hasAgent (state, getters) {
    return !!getters.getAgent
  },

  /**
   * Get other roles that are currently not active
   * @param state
   * @param getters
   * @returns {[Object]}
   */
  otherRoles (state, getters) {
    const currentRole = getters.getActiveRoleType
    const roles = [roleNames.FREELANCER, roleNames.CLIENT, roleNames.AGENT].filter(r => r !== currentRole?.name)
    const others = []

    for (let i = 0; i < roles.length; i++) {
      const roleName = capitalize(roles[i])
      if (getters[`has${roleName}`]) {
        others.push({
          name: roles[i],
          data: getters[`get${roleName}`]
        })
      }
    }

    return others
  },

  /**
   * Check if current user has completed his profile with required fields
   * @param state
   * @param getters
   */
  isUserCompleted (state, getters) {
    const activeRoleData = getters.getActiveRoleData

    if (getters.isFreelancer) {
      return !!activeRoleData.firstName &&
        !!activeRoleData.lastName &&
        !!activeRoleData.avatar &&
        !!activeRoleData.skills &&
        !!activeRoleData.skills.length &&
        !!activeRoleData.categories &&
        !!activeRoleData.categories.length &&
        !!activeRoleData.occupation &&
        !!activeRoleData.bio
    } else if (getters.isClient) {
      return !!activeRoleData.name &&
        !!activeRoleData.about
    } else if (getters.isAgent) {
      return !!activeRoleData.name &&
        !!activeRoleData.about &&
        !!activeRoleData.skills &&
        !!activeRoleData.skills.length &&
        !!activeRoleData.categories &&
        !!activeRoleData.categories.length
    }
    return true
  },

  /**
   * Check on what profile wizard step is user currently
   * @param state
   * @param getters
   */
  currentWizardStep (state, getters) {
    const activeRoleData = getters.getActiveRoleData

    if (getters.isFreelancer) {
      if (!activeRoleData.firstName ||
        !activeRoleData.lastName ||
        !activeRoleData.avatar ||
        !activeRoleData.occupation ||
        !activeRoleData.bio) {
        return 'basic-info'
      } else if (
        !activeRoleData.skills ||
        !activeRoleData.skills.length ||
        !activeRoleData.categories ||
        !activeRoleData.categories.length) {
        return 'skills'
      }
    }

    return 'basic-info'
  },

  /**
   * Is current user role published
   * @param state
   * @param getters
   * @returns {string|Boolean|*}
   */
  isPublished (state, getters) {
    const activeRole = getters.getActiveRoleData
    return activeRole.published
  },

  /**
   * Is current user confirmed
   * @param state
   * @param getters
   * @returns {string|*}
   */
  isConfirmed (state, getters) {
    const currentRole = getters.getActiveRoleData
    return !!currentRole?.confirmed
  },

  /**
   * Get current role avatar data
   * @param state
   * @param getters
   * @returns {any}
   */
  getAvatar (state, getters) {
    const currentRole = getters.getActiveRoleData

    return currentRole ? currentRole.avatar : null
  }
}
