//
//
//
//
//
//

export default {
  name: 'Access',
  props: {
    roles: {
      type: Array,
      default () {
        return []
      }
    }
  },
  computed: {
    canAccess () {
      if (!this.roles || !this.roles.length) {
        return true
      }

      return this.$auth.loggedIn && this.roles.includes(this.$store.getters['user/getActiveRoleTypeName'])
    }
  }
}
