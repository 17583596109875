//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'LogoSmallCt',
  computed: {
    /**
     * Check if dark theme is enabled
     * @return boolean
     * */
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
