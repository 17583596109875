import { tasksSortOptions } from 'ct-constants'

export const state = () => ({
  sort: {
    by: tasksSortOptions.DATE_ADDED,
    dir: 'desc'
  },
  languages: [],
  category: null,
  skill: null,
  queryTerm: null,
  type: null,
  location: null,
  tasks: [],
  perPage: 20,
  total: 0
})

export const mutations = {
  /**
   * Set query term for search
   * @param state
   * @param {String} term
   */
  setQueryTerm (state, term) {
    state.queryTerm = term
  },

  /**
   * Set search results
   * @param state
   * @param {Array} hits - search result items
   */
  setTasks (state, hits) {
    state.tasks = hits
  },

  /**
   * Set total num of items
   * @param state
   * @param total
   */
  setTotal (state, total) {
    state.total = total
  },

  /**
   * Update sort properties
   * @param state
   * @param {String} by - sort by
   */
  setSort (state, by) {
    if (by === state.sort.by) {
      state.sort = Object.assign({}, state.sort, {
        dir: state.sort.dir === 'asc' ? 'desc' : 'asc'
      })
    } else {
      state.sort = {
        by,
        dir: 'asc'
      }
    }
  },

  /**
   * Set search category
   * @param state
   * @param category
   */
  setCategory (state, category) {
    state.category = category
  },

  /**
   * Set skill for search
   * @param state
   * @param skill
   */
  setSkill (state, skill) {
    state.skill = skill
  },

  /**
   * Set task type
   * @param state
   * @param type
   */
  setType (state, type) {
    state.type = type
  },

  /**
   * Set task location
   * @param state
   * @param {String} location
   */
  setLocation (state, location) {
    state.location = location
  },

  /**
   * Set selected languages
   * @param state
   * @param languages
   */
  setLanguages (state, languages) {
    state.languages = languages
  }
}

export const actions = {
  /**
   * Run search
   * @param commit
   * @param state
   * @param page
   * @return {Promise<void>}
   */
  async runSearch ({
    commit,
    state
  }, page) {
    const params = {
      sortBy: state.sort.by,
      sortDir: state.sort.dir,
      q: state.queryTerm,
      category: state.category,
      skill: state.skill,
      page,
      perPage: state.perPage,
      type: state.type,
      location: state.location,
      languages: state.languages.join(',')
    }

    const res = await this.$axios.get('/tasks', { params })

    commit('setTasks', res.data.data.hits)
    commit('setTotal', res.data.data.total.value)
  },

  setCategory ({ commit }, category) {
    commit('setCategory', category)
  },

  setType ({ commit }, type) {
    commit('setType', type)
  },

  setLocation ({ commit }, location) {
    commit('setLocation', location)
  },

  setLanguages ({ commit }, languages) {
    commit('setLanguages', languages)
  }
}

export const getters = {
  numberOfPages (state) {
    return state.total ? Math.ceil(state.total / state.perPage) : 1
  }
}
