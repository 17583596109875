import Vue from 'vue'
import VueSocketIO from 'vue-socket.io'

// noinspection JSUnusedGlobalSymbols
export default function ({ store, isDev }) {
  Vue.use(new VueSocketIO({
    debug: isDev,
    connection: process.env.baseUrl,
    vuex: {
      store,
      actionPrefix: 'socket_',
      mutationPrefix: 'socket_'
    }
  }))
}
