export default {
  head () {
    const i18nHead = this.$nuxtI18nHead({ addSeoAttributes: true })
    return {
      titleTemplate: this.$t('meta.template'),
      title: this.$t('meta.title'),
      htmlAttrs: {
        ...i18nHead.htmlAttrs
      },
      meta: [
        {
          hid: 'description',
          name: 'description',
          content: this.$t('meta.description')
        },
        {
          hid: 'keywords',
          name: 'keywords',
          content: this.$t('meta.keywords')
        },
        {
          property: 'og:title',
          content: this.$t('meta.fullTitle'),
          hid: 'og:title'
        },
        {
          property: 'og:description',
          content: this.$t('meta.description'),
          hid: 'og:description'
        },
        {
          name: 'twitter:title',
          content: this.$t('meta.fullTitle'),
          hid: 'twitter:title'
        },
        {
          name: 'twitter:description',
          content: this.$t('meta.description'),
          hid: 'twitter:description'
        },
        {
          name: 'twitter:image',
          content: this.$t('meta.twitterImageUrl'),
          hid: 'twitter:image'
        },
        {
          property: 'og:site_name',
          content: this.$t('meta.site_name'),
          hid: 'og:site_name'
        },
        {
          property: 'og:image',
          content: this.$t('meta.facebookImageUrl'),
          hid: 'og:image'
        },
        {
          property: 'og:image:secure_url',
          content: this.$t('meta.facebookImageUrl'),
          hid: 'og:image:secure_url'
        },
        {
          property: 'og:image:width',
          content: '1031',
          hid: 'og:image:width'
        },
        {
          property: 'og:image:height',
          content: '386',
          hid: 'og:image:height'
        },
        {
          name: 'facebook-domain-verification',
          content: 'hmrnyxpkgcolbq6a3jzqjcazmxiu7t'
        },
        ...i18nHead.meta
      ],
      link: [
        {
          rel: 'icon',
          type: 'image/x-icon',
          href: `/${this.$t('common.faviconPath')}/favicon.ico`
        },
        {
          rel: 'apple-touch-icon',
          sizes: '180x180',
          href: `/${this.$t('common.faviconPath')}/apple-touch-icon.png`
        },
        {
          rel: 'icon',
          sizes: '32x32',
          href: `/${this.$t('common.faviconPath')}/favicon-32x32.png`
        },
        {
          rel: 'icon',
          sizes: '16x16',
          href: `/${this.$t('common.faviconPath')}/favicon-16x16.png`
        },
        {
          rel: 'manifest',
          href: `/${this.$t('common.faviconPath')}/site.webmanifest`
        },
        {
          rel: 'mask-icon',
          href: `/${this.$t('common.faviconPath')}/safari-pinned-tab.svg`,
          color: '#5bbad5'
        }
      ]
    }
  }
}
