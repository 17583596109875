//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LanguageSwitcher from '@/components/ui/LanguageSwitcher'
import ThemeToggler from '@/components/ui/ThemeToggler'
import LogoBack from '@/components/layout/LogoBack'
import UserInfo from '@/components/ui/UserInfo'
import TopMenu from '@/components/layout/TopMenu'
import MobileTopMenu from '@/components/layout/MobileTopMenu'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MainLayoutHeader',
  components: {
    LanguageSwitcher,
    ThemeToggler,
    LogoBack,
    UserInfo,
    TopMenu,
    MobileTopMenu
  },
  data: () => ({
    scrolled: false
  }),
  computed: {
    styles () {
      const styles = ['main-header', 'd-flex', 'align-items-center']

      if (this.scrolled) {
        return [...styles, 'scrolled']
      }

      return styles
    }
  },
  mounted () {
    window.addEventListener('scroll', this.updateHeaderHeight)
    this.updateHeaderHeight()
  },
  unmounted () {
    window.removeEventListener('scroll', this.updateHeaderHeight)
  },
  methods: {
    updateHeaderHeight () {
      this.scrolled = document.body.scrollTop > 80 || document.documentElement.scrollTop > 80
    }
  }
}
