export const state = () => ({
  freelancers: [],
  languages: [],
  perPage: 20,
  page: 1,
  total: 0,
  category: null,
  skill: null,
  term: null,
  featured: false,
  koth: null,
  country: null
})

export const actions = {
  /**
   * Get initial load of freelancers in search
   * @param state
   * @param commit
   * @return {Promise<void>}
   */
  async getFreelancers ({
    state,
    commit
  }) {
    const params = {
      page: state.page,
      perPage: state.perPage,
      category: state.category,
      skill: state.skill,
      featured: state.featured ? true : '',
      languages: state.languages.join(','),
      countryId: state.country,
      q: state.term
    }
    try {
      const res = await this.$axios.get('/freelancers', { params })
      commit('setFreelancers', res.data.data.hits)
      commit('setTotal', res.data.data.total.value)
    } catch (err) {
      commit('setFreelancers', [])
      commit('setTotal', 0)
      throw err
    }
  },

  /**
   * Find category from skill name and set both
   * @param state
   * @param rootState
   * @param {Object} skill
   */
  setSkillCategory ({
    commit,
    rootState,
    rootGetters
  }, skill) {
    const category = rootGetters['util/getCategoriesMapping'][skill.categoryId]

    commit('setSkill', skill)
    commit('setCategory', category)
  },

  /**
   * Set page on fl list
   * @param commit
   * @param page
   * @returns {Promise<void>}
   */
  async setPage ({ commit }, page) {
    await this.$router.push(this.localePath({
      path: '/freelancers',
      query: {
        page
      }
    }))

    commit('setPage', page)
  },

  /**
   * Set category for search
   * @param commit
   * @param dispatch
   * @param category
   * @returns {Promise<void>}
   */
  async setCategory ({
    commit,
    dispatch
  }, category) {
    await dispatch('setPage', 1)

    commit('setCategory', category)
  },

  /**
   * Set skill for search
   * @param commit
   * @param dispatch
   * @param skill
   * @returns {Promise<void>}
   */
  async setSkill ({
    commit,
    dispatch
  }, skill) {
    await dispatch('setPage', 1)

    commit('setSkill', skill)
  },

  /**
   * Set is featured only on on fl search
   * @param commit
   * @param dispatch
   * @param featured
   * @returns {Promise<void>}
   */
  async setFeatured ({
    commit,
    dispatch
  }, featured) {
    await dispatch('setPage', 1)

    commit('setIsFeaturedOnly', featured)
  },

  /**
   * Set country on fl search
   * @param commit
   * @param dispatch
   * @param {Number} country
   * @returns {Promise<void>}
   */
  async setCountry ({
    commit,
    dispatch
  }, country) {
    await dispatch('setPage', 1)

    commit('setCountry', country)
  },

  /**
   * Set languages on search
   * @param commit
   * @param dispatch
   * @param languages
   * @returns {Promise<void>}
   */
  async setLanguages ({
    commit,
    dispatch
  }, languages) {
    await dispatch('setPage', 1)

    commit('setLanguages', languages)
  },

  /**
   * Set search term
   * @param commit
   * @param dispatch
   * @param term
   * @returns {Promise<void>}
   */
  async setSearchTerm ({
    commit,
    dispatch
  }, term) {
    await dispatch('setPage', 1)

    commit('setSearchTerm', term)
  },

  /**
   * Set initial KOTH data
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async setInitialKoth ({
    commit,
    state
  }) {
    if (!state.koth) {
      const res = await this.$axios.get('/freelancers/koth')
      commit('setKoth', res.data.data)
    }
  },

  /**
   * Claim new KOTH and set current freelancer as new KOTH
   * @param commit
   * @param dispatch
   * @param sig
   * @returns {Promise<void>}
   */
  async claimKoth ({
    commit,
    dispatch
  }, sig) {
    try {
      const res = await this.$axios.put('/freelancers/claim-koth', { sig })
      if (res.data.data) {
        const kothRes = await this.$axios.get('/freelancers/koth')
        commit('setKoth', kothRes.data.data)
      }
      return res.data.data
    } catch (err) {
      throw new Error(err.response.data.error)
    }
  }
}

export const mutations = {
  /**
   * Set fetched freelancers
   * @param state
   * @param freelancers
   */
  setFreelancers (state, freelancers) {
    state.freelancers = freelancers
  },

  /**
   * Set total number of freelancers in query
   * @param state
   * @param {Number} total
   */
  setTotal (state, total) {
    state.total = total
  },

  /**
   * Set search term
   * @param state
   * @param term
   */
  setSearchTerm (state, term) {
    state.term = term
  },

  /**
   * Set category for filtering freelancers
   * @param state
   * @param {String} category
   */
  setCategory (state, category) {
    state.category = category
  },

  /**
   * Set is featured only on
   * @param state
   * @param featured
   */
  setIsFeaturedOnly (state, featured) {
    state.featured = featured
  },

  /**
   * Set search languages
   * @param state
   * @param languages
   */
  setLanguages (state, languages) {
    state.languages = languages
  },

  /**
   * Set skill for filtering freelancers
   * @param state
   * @param {String} skill
   */
  setSkill (state, skill) {
    state.skill = skill
  },

  /**
   * Set country for freelancers filtering
   * @param state
   * @param {Number} country
   */
  setCountry (state, country) {
    state.country = country
  },

  /**
   * Set page
   * @param state
   * @param page
   */
  setPage (state, page) {
    state.page = page
  },

  /**
   * Set KOTH freelancer
   * @param state
   * @param koth
   */
  setKoth (state, koth) {
    state.koth = koth
  }
}

export const getters = {
  numberOfPages (state) {
    return state.total ? Math.ceil(state.total / state.perPage) : 1
  }
}
