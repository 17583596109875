import { taskStatuses } from 'ct-constants'

export const state = () => ({
  loadedTasks: [],
  selectedTaskId: null,
  applicationsCount: {}
})

export const actions = {
  /**
   * Create new task
   * @param commit
   * @param rootState
   * @param {Object} data - task data
   * @param {String} data.title - task title
   * @param {String} data.description - task description
   * @param {Number} data.price - task price
   * @param {Number} data.duration - task work time
   * @param {Boolean} data.published - task published
   * @return {Promise<void>}
   */
  async create ({
    commit,
    rootState
  }, data) {
    const res = await this.$axios.post('/tasks', data)
    const task = res.data.data

    commit('addLoadedTask', task)
    commit('setSelectedTask', task.id)

    return task
  },

  /**
   * Update task
   * @param commit
   * @param {Number} id - task ID
   * @param {Object} taskData - task data
   * @return {Promise<Object>}
   */
  async update ({ commit }, {
    id,
    taskData
  }) {
    const { data } = await this.$axios.put(`/tasks/${id}`, taskData)
    const task = data.data

    commit('updateLoadedTask', taskData)

    return task
  },

  /**
   * Socket handler when task is updated
   * @param commit
   * @param {Object} task
   */
  socket_taskUpdated ({ commit }, task) {
    commit('updateLoadedTask', task)
  },

  /**
   * Delete my own task
   * @param commit
   * @param {Number} id
   * @return {Promise<void>}
   */
  async delete ({ commit }, id) {
    await this.$axios.delete(`/tasks/${id}`)

    commit('deleteLoadedTask', id)
  },

  /**
   * Get selected task and commit it to state
   * Fetch only if task is not already fetched
   * Also fetch count of applications for task
   * @param commit
   * @param state
   * @param {String} taskId - task ID in string format
   * @return {Promise<void>}
   */
  async loadTask ({
    commit,
    state
  }, taskId) {
    if (!state.loadedTasks.find(t => t.id === taskId)) {
      const { data } = await this.$axios.get(`/tasks/${taskId}`)
      commit('addLoadedTask', data.data)
    }
  },

  /**
   * Load applications count for task
   * @param commit
   * @param state
   * @param {Number} taskId
   * @param {Boolean} force
   * @returns {Promise<void>}
   */
  async loadTaskApplicationsCount ({
    commit,
    state
  }, taskId, force) {
    if (!state.applicationsCount[taskId] || force) {
      const { data } = await this.$axios.get(`/tasks/${taskId}/applications-count`)
      commit('addApplicationsCount', {
        taskId,
        count: data.data.count
      })
    }
  },

  /**
   * Reopen task
   * @param commit
   * @param {Object} task
   * @return {Promise<void>}
   */
  async reopen ({ commit }, task) {
    await this.$axios.put(`/tasks/${task.id}/reopen`)

    commit('setTaskStatus', taskStatuses.ACCEPTING)
  },

  /**
   * Close task
   * @param commit
   * @param {Object} task
   * @return {Promise<void>}
   */
  async close ({ commit }, task) {
    await this.$axios.put(`/tasks/${task.id}/close`)

    commit('setTaskStatus', taskStatuses.FILLED)
  },

  /**
   * Set escrow on selected task
   * @param commit
   * @param state
   * @param currency
   * @returns {Promise<void>}
   */
  async setTaskEscrow ({
    commit,
    state
  }, currency) {
    if (state.selectedTaskId) {
      const res = await this.$axios.put(`/tasks/${state.selectedTaskId}/set-escrow`, { selectedEscrowCurrency: currency })
      commit('setTaskEscrowData', {
        data: res.data.data,
        currency
      })
    }
  },

  /**
   * Check escrow balance on task
   * @param commit
   * @param {Number} taskId
   * @returns {Promise<void>}
   */
  async checkTaskEscrowBalance ({ commit }, taskId) {
    const { data } = await this.$axios.put(`/tasks/${taskId}/check-escrow-balance`)
    commit('setTaskEscrowBalance', {
      taskId,
      balance: data.data
    })
  },

  /**
   * Update agents crypto payout address
   * @param commit
   * @param {String} address
   * @param {Integer} taskId
   * @returns {Promise<void>}
   */
  async updateAgentsCryptoPayoutAddress ({ commit }, {
    address,
    taskId
  }) {
    await this.$axios.put(`/tasks/${taskId}/agent-payout-address`, { address })
    commit('setAgentsCryptoPayoutAddress', {
      address,
      taskId
    })
  },

  /**
   * Toggle task like
   * @param commit
   * @param {Integer} taskId
   * @return {Promise<void>}
   */
  async toggleTaskLikes ({ commit }, taskId) {
    const { data } = await this.$axios.put(`/tasks/${taskId}/like`)
    commit('setTaskLike', {
      taskId,
      likes: data.data.likes
    })
  }
}

export const mutations = {
  /**
   * Cache loaded tasks
   * @param state
   * @param {Object} task
   */
  addLoadedTask (state, task) {
    state.loadedTasks = [...state.loadedTasks, task]
  },

  /**
   * Update one of loaded tasks
   * @param state
   * @param {Object} task
   */
  updateLoadedTask (state, task) {
    state.loadedTasks = state.loadedTasks.map((t) => {
      if (t.id === task.id) {
        return { ...t, ...task }
      }
      return t
    })
  },

  /**
   * Delete one of loaded tasks
   * @param state
   * @param {Object} task
   */
  deleteLoadedTask (state, task) {
    state.loadedTasks = state.loadedTasks.filter(t => t.id !== task.id)
  },

  /**
   * Set selected task ID
   * @param state
   * @param {Number} taskId - task ID
   */
  setSelectedTask (state, taskId) {
    state.selectedTaskId = taskId
  },

  /**
   * Deselect currently selected task
   * @param state
   */
  deselectTask (state) {
    state.selectedTaskId = null
  },

  /**
   * Set task status
   * @param state
   * @param {Number} status
   */
  setTaskStatus (state, status) {
    if (state.selectedTaskId) {
      state.loadedTasks = state.loadedTasks.map((t) => {
        if (t.id === state.selectedTaskId) {
          return {
            ...t,
            status
          }
        }
        return t
      })
    }
  },

  /**
   * Set escrow data on selected task
   * @param state
   * @param data
   * @param currency
   */
  setTaskEscrowData (state, {
    data,
    currency
  }) {
    state.loadedTasks = state.loadedTasks.map((t) => {
      if (t.id === state.selectedTaskId) {
        return {
          ...t,
          escrowStatus: data.escrowStatus,
          escrowPublic: data.escrowPublic,
          qrCode: data.qrCode,
          cryptoEscrowName: currency
        }
      }
      return t
    })
  },

  /**
   * Set application count for task
   * @param state
   * @param {Number} taskId
   * @param {Number} count
   */
  addApplicationsCount (state, {
    taskId,
    count
  }) {
    state.applicationsCount = {
      ...state.applicationsCount,
      [taskId]: count
    }
  },

  /**
   * Set escrow balance
   * @param state
   * @param {Number} taskId
   * @param {Object} balance
   */
  setTaskEscrowBalance (state, {
    taskId,
    balance
  }) {
    state.loadedTasks = state.loadedTasks.map((t) => {
      if (t.id === taskId) {
        return {
          ...t,
          ...balance
        }
      }
      return t
    })
  },

  /**
   * Set agents crypto payout address
   * @param state
   * @param {String} address
   * @param {Integer} taskId
   */
  setAgentsCryptoPayoutAddress (state, {
    address,
    taskId
  }) {
    state.loadedTasks = state.loadedTasks.map((t) => {
      if (t.id === taskId) {
        return {
          ...t,
          agentCryptoPayoutAddress: address
        }
      }
      return t
    })
  },

  /**
   * Set task likes count
   * @param state
   * @param {Integer} taskId
   * @param {Integer} likes
   */
  setTaskLike (state, {
    taskId,
    likes
  }) {
    state.loadedTasks = state.loadedTasks.map((t) => {
      if (t.id === taskId) {
        return {
          ...t,
          likes
        }
      }
      return t
    })
  }
}

export const getters = {
  /**
   * Get selected task data
   * @param state
   * @returns {*}
   */
  selectedTask (state) {
    return state.loadedTasks.find(t => t.id === state.selectedTaskId)
  },

  /**
   * Get count of applications for selected task
   * @param state
   * @returns {function(Number)}
   */
  getTaskApplicationsCount: state => (taskId) => {
    return state.applicationsCount[taskId] || 0
  }
}
