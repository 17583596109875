import orderBy from 'lodash/orderBy'
import keyBy from 'lodash/keyBy'
import extract from '../lib/extractLanguage'

export const state = () => ({
  skills: [],
  languages: [],
  countries: [],
  currencies: [],
  cryptos: [],
  roles: [],
  paymentMethods: []
})

export const actions = {
  /**
   * Init util data
   * @param dispatch
   * @returns {Promise<void>}
   */
  async init ({ dispatch }) {
    await dispatch('getSkills')
    await dispatch('getLanguages')
    await dispatch('getCountries')
    await dispatch('getCurrencies')
    await dispatch('getRoles')
    await dispatch('getCryptos')
    await dispatch('getPaymentMethods')
  },

  /**
   * Get skills from service and set them if not already set
   * @param commit
   * @param state
   * @return {Promise<void>}
   */
  async getSkills ({
    commit,
    state
  }) {
    if (!state.skills.length) {
      const skills = await this.$axios.get('/utils/categories')
      commit('setSkills', skills.data.data)
    }
  },

  /**
   * Get languages from service and set them if not already set
   * @param commit
   * @param state
   * @return {Promise<void>}
   */
  async getLanguages ({
    commit,
    state
  }) {
    if (!state.languages.length) {
      const languages = await this.$axios.get('/utils/languages')
      commit('setLanguages', languages.data.data)
    }
  },

  /**
   * Get countries from service and set them if not already set
   * @param commit
   * @param state
   * @return {Promise<void>}
   */
  async getCountries ({
    commit,
    state
  }) {
    if (!state.countries.length) {
      const countries = await this.$axios.get('/utils/countries')
      commit('setCountries', countries.data.data)
    }
  },

  /**
   * Get currencies from service and set them if not already set
   * @param commit
   * @param state
   * @return {Promise<void>}
   */
  async getCurrencies ({
    commit,
    state
  }) {
    if (!state.currencies.length) {
      const currencies = await this.$axios.get('/utils/currencies')
      commit('setCurrencies', currencies.data.data)
    }
  },

  /**
   * Get cryptos from utils and commit them to state
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async getCryptos ({
    commit,
    state
  }) {
    if (!state.cryptos.length) {
      const cryptos = await this.$axios.get('/utils/cryptos')
      commit('setCryptos', cryptos.data.data)
    }
  },

  /**
   * Get payment methods from API
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async getPaymentMethods ({
    commit,
    state
  }) {
    if (!state.paymentMethods.length) {
      const { data } = await this.$axios.get('/payment-methods')
      commit('setPaymentMethods', data.data)
    }
  },

  /**
   * Get roles
   * @param commit
   * @param state
   * @returns {Promise<void>}
   */
  async getRoles ({
    commit,
    state
  }) {
    if (!state.roles.length) {
      const roles = await this.$axios.get('/utils/roles')
      commit('setRoles', roles.data.data)
    }
  }
}

export const mutations = {
  /**
   * Set skills to store
   * @param state
   * @param {Array} skills
   */
  setSkills (state, skills) {
    state.skills = skills
  },

  /**
   * Set languages to store
   * @param state
   * @param {Array} languages
   */
  setLanguages (state, languages) {
    state.languages = languages
  },

  /**
   * Set countries to store
   * @param state
   * @param {Array} countries
   */
  setCountries (state, countries) {
    state.countries = countries
  },

  /**
   * Set cryptos to store
   * @param state
   * @param cryptos
   */
  setCryptos (state, cryptos) {
    state.cryptos = cryptos
  },

  /**
   * Set currencies to store
   * @param state
   * @param {Array} currencies
   */
  setCurrencies (state, currencies) {
    state.currencies = currencies
  },

  /**
   * Set roles to store
   * @param state
   * @param roles
   */
  setRoles (state, roles) {
    state.roles = roles
  },

  /**
   * Set payment methods to store
   * @param state
   * @param methods
   */
  setPaymentMethods (state, methods) {
    state.paymentMethods = methods
  }
}

export const getters = {
  /**
   * Get all skills without categories
   * @param state
   * @param getters
   */
  getAllSkills (state, getters) {
    const currentLanguage = getters.getCurrentLanguage
    const skills = []

    if (currentLanguage) {
      state.skills.forEach((c) => {
        skills.push(...c.skills)
      })

      return extract(skills, currentLanguage.id)
    }

    return []
  },

  /**
   * Get map of all skills where key is skill ID
   * @param state
   * @param getters
   */
  getAllSkillsMappings (state, getters) {
    return keyBy(getters.getAllSkills, 'id')
  },

  /**
   * Get currently active language record
   * @param state
   * @param getters
   * @param rootState
   * @return {Object}
   */
  getCurrentLanguage (state, getters, rootState) {
    const currentLocale = rootState.i18n.locale
    return state.languages.find(l => l.code === currentLocale)
  },

  /**
   * Get map of enabled languages [{id: Language},...]
   * @param state
   * @returns {*}
   */
  getEnabledLanguages (state) {
    return state.languages.filter(l => l.enabled)
  },

  /**
   * Get default languages for current language
   * @param state
   * @param getters
   * @returns {*}
   */
  getCurrentLanguageDefaults (state, getters) {
    return getters.getCurrentLanguage?.defaults
  },

  /**
   * Get default currency from current language default currency
   * @param state
   * @param getters
   * @returns {*|null}
   */
  getDefaultCurrency (state, getters) {
    const currentLanguage = getters.getCurrentLanguage
    return currentLanguage && state.currencies.length
      ? state.currencies.find(c => c.id === currentLanguage.defaultCurrencyId)
      : null
  },

  /**
   * Get categories with flat translations
   * @param state
   * @param getters
   */
  getCategories (state, getters) {
    const currentLanguage = getters.getCurrentLanguage

    if (currentLanguage) {
      const cats = extract(state.skills, currentLanguage.id)

      return cats.map(({
        id,
        name,
        order
      }) => ({
        id,
        name,
        order
      }))
    }

    return []
  },

  /**
   * Get categories ordered by name
   * @param state
   * @param getters
   * @return {*}
   */
  getCategoriesByName (state, getters) {
    return orderBy(getters.getCategories, 'name')
  },

  /**
   * Get categories ordered by order number
   * @param state
   * @param getters
   * @return {*}
   */
  getCategoriesByOrder (state, getters) {
    return orderBy(getters.getCategories, 'order')
  },

  /**
   * Get map of categories where key is category ID
   * @param state
   * @param getters
   * @returns {*}
   */
  getCategoriesMapping (state, getters) {
    return keyBy(getters.getCategories, 'id')
  },

  /**
   * Get skills by category Id
   * @param state
   * @param getters
   * @return {function(*): *}
   */
  getSkillsByCategory: (state, getters) => (categoryId) => {
    const skills = getters.getAllSkills.filter(s => s.categoryId === categoryId)

    return (orderBy(
      skills,
      'name',
      'asc'
    )).map(({
      id,
      categoryId,
      name,
      slug
    }) => ({
      id,
      categoryId,
      name,
      slug
    }))
  },

  /**
   * Get skills by selected category IDs
   * @param state
   * @param getters
   * @return {function(*): *}
   */
  getSkillsByCategories: (state, getters) => (categoryIds) => {
    const skills = getters.getAllSkills.filter(s => categoryIds?.includes(s.categoryId))

    if (skills.length) {
      return orderBy(
        skills,
        'name',
        'asc'
      )
    }

    return []
  },

  /**
   * Get hash map od currencies where keys are currency Ids and values are currency data
   * @param state
   * @returns {*}
   */
  getCurrenciesMap (state) {
    const { currencies } = state

    return keyBy(currencies, 'id')
  },

  /**
   * Get map of countries where key id country ID and value is country data
   * @param state
   * @returns {*}
   */
  getCountriesMap (state) {
    const { countries } = state

    return keyBy(countries, 'id')
  },

  /**
   * Get map of payment methods where key is method ID
   * @param state
   * @returns {*}
   */
  getPaymentMethodsById (state) {
    const { paymentMethods } = state

    return keyBy(paymentMethods, 'id')
  }
}
