function extract (arr, languageId) {
  return arr.map((s) => {
    const translation = s.translations?.length
      ? s.translations
        .find(t => t.languageId === languageId)
      : null
    return {
      ...s,
      name: translation?.displayTranslation || '',
      slug: translation?.slug || null
    }
  })
}

export default extract
