//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LayoutSeoDefaults from '@/mixins/LayoutSeoDefaults'
import LayoutInit from '@/mixins/LayoutInit'
import ChatHistory from '@/components/tasks/chat/ChatHistory'
import LeftMenu from '@/components/layout/LeftMenu'
import LayoutWrapper from '@/components/layout/LayoutWrapper'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'DefaultLayout',
  components: {
    LayoutWrapper,
    ChatHistory,
    LeftMenu
  },
  mixins: [LayoutSeoDefaults, LayoutInit],
  computed: {
    /**
     * Is user logged in
     * @return {Boolean}
     */
    loggedIn () {
      return this.$auth.loggedIn
    },

    /**
     * Is left menu displayed on this page
     * @return {Boolean}
     */
    showMenu () {
      return this.$route?.meta?.showMenu !== false
    },

    /**
     * Is chat displayed on this page
     * @return {Boolean}
     */
    showChat () {
      return this.$route?.meta?.showChat !== false
    }
  }
}
