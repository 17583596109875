//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { MenuIcon } from 'vue-feather-icons'
import PostJobMenuItem from '@/components/layout/PostJobMenuItem'

export default {
  name: 'MobileTopMenu',
  components: {
    PostJobMenuItem,
    MenuIcon
  },
  computed: {
    loggedIn () {
      return this.$auth.loggedIn
    },
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
