//
//
//
//
//
//
//

import AvatarDisplay from '../../ui/AvatarDisplay.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ChatHistoryAvatar',
  components: {
    AvatarDisplay
  },
  props: {
    avatar: {
      type: Object,
      default () {
        return {}
      }
    },
    online: {
      type: Boolean,
      default: false
    }
  },
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 40,
          height: 40
        }
      }
    }
  }
}
