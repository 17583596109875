import Vue from 'vue'
import VueGtag from 'vue-gtag'
import { capitalize } from 'lodash/string'

export default ({
  app,
  env,
  i18n
}) => {
  const { locale } = i18n
  const tag = env[`gtag${capitalize(locale)}`]

  if (tag) {
    Vue.use(VueGtag, {
      config: { id: tag }
    })
  } else {
    console.log('Skipping GA in development')
  }
}
