//
//
//
//
//
//
//
//
//
//
//
//

import { applicationUrl } from '@/components/mixins/applicationUrl'
import Thread from '@/components/tasks/chat/Thread'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'FreelancerThreads',
  components: { Thread },
  mixins: [applicationUrl],
  computed: {
    threads () {
      return this.$store.getters['chat/getSortedThreads']
    }
  },
  methods: {
    /**
     * Open thread for selected application
     * @param thread
     */
    async openThread (thread) {
      // redirect to page if not already there
      const path = this.generateApplicationUrl(thread)
      if (this.$route.path !== path) {
        await this.$router.push(this.localePath(path))
      }
    }
  }
}
