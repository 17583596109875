//
//
//
//
//
//
//
//

import ClientGroupedThreads from '@/components/tasks/chat/ClientGroupedThreads'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ClientThreads',
  components: {
    ClientGroupedThreads
  },
  computed: {
    /**
     * Get list of threads
     * @return {[Object]}
     */
    threads () {
      return this.$store.getters['chat/getSortedThreads']
    }
  },
  methods: {
    async openThread (thread) {
      await this.$router.push(this.localePath(`/my-tasks/${thread.taskId}/application/${thread.id}`))
    }
  }
}
