//
//
//
//
//
//
//
//
//

import { InfoIcon } from 'vue-feather-icons'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ChatHistoryHeader',
  components: {
    InfoIcon
  },
  computed: {
    toolTipText () {
      if (this.$store.getters['user/isClient']) {
        return this.$t('chat.clientBoxTooltip')
      } else if (this.$store.getters['user/isFreelancer']) {
        return this.$t('chat.freelancerBoxTooltip')
      }
      return this.$t('chat.otherBoxTooltip')
    }
  }
}
