//
//
//
//
//
//
//
//

import { mapGetters } from 'vuex'
import AgentMenu from '@/components/agents/AgentMenu'
import FreelancerMenu from './FreelancerMenu.vue'
import ClientMenu from './ClientMenu.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'MobileMenu',
  components: {
    AgentMenu,
    ClientMenu,
    FreelancerMenu
  },
  computed: {
    ...mapGetters('user', [
      'isFreelancer',
      'isClient',
      'isAgent'
    ]),
    loggedIn () {
      return this.$auth.loggedIn
    }
  }
}
