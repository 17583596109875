export default async (error, i18n, store) => {
  if (error.response) {
    await store.dispatch('ui/showNotification', {
      type: 'danger',
      text: i18n.t(error.response?.data?.error)
    })
  } else if (error.request) {
    await store.dispatch('ui/showNotification', {
      type: 'danger',
      text: i18n.t('common.error') + ' ' + error.message
    })
  } else {
    await store.dispatch('ui/showNotification', {
      type: 'danger',
      text: i18n.t('common.error')
    })
  }
}
