//
//
//
//
//
//

import LayoutSeoDefaults from '@/mixins/LayoutSeoDefaults'
import LayoutInit from '@/mixins/LayoutInit'
import LayoutWrapper from '@/components/layout/LayoutWrapper'

export default {
  name: 'BlankLayout',
  components: {
    LayoutWrapper
  },
  mixins: [LayoutSeoDefaults, LayoutInit]
}
