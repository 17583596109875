//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'FooterLogo',
  computed: {
    /**
     * Check if dark theme is enabled
     * @return boolean
     * */
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    },

    /**
     * Current language
     * @return string
     */
    currentLanguage () {
      return this.$store.state.i18n.locale
    }
  }
}
