export default function ({
  store,
  redirect,
  $auth,
  localePath
}) {
  if ($auth.loggedIn) {
    const isClient = store.getters['user/isClient']
    if (!isClient) {
      store.dispatch('ui/showNotification', {
        type: 'danger',
        text: 'Please log in as employer'
      })

      // redirect freelancer to tasks list
      return redirect(localePath('/tasks'))
    }

    return true
  }

  return true
}
