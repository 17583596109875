//
//
//
//

export default {
  name: 'Initializer',
  mounted () {
    this.init()
  },
  methods: {
    async init () {
      if (this.$auth.loggedIn) {
        const {
          id,
          email
        } = this.$auth.user
        const username = this.$store.getters['user/getUserName']
        if (this.$Tawk) {
          this.$Tawk.$updateChatUser({
            name: username,
            email
          })
        }

        try {
          this.$store.commit('chat/toggleThreadsLoader', true)
          await this.$store.dispatch('chat/getThreads')
          this.$store.commit('chat/toggleThreadsLoader', false)
        } catch (err) {
          this.$store.commit('chat/toggleThreadsLoader', false)
        }

        if (this.$sentry) {
          this.$sentry.setUser({
            id,
            email
          })
        }

        if (this.$gtag) {
          this.$gtag.set({
            user_id: id
          })
        }
      }
    }
  }
}
