/**
 * Takes config from .env file in format domain.com::language;domain2.hr::language2
 * Translates it to mapping and takes current domain and matches domain with language
 * It sets locale using found language
 *
 * It should work only in server context
 *
 * @param context
 */
export default (context) => {
  if (process.server) {
    const routePath = context.route.path.split('/')
    const locales = context.i18n.locales.map(l => l.code)
    const localeExists = routePath.length > 1 && locales.includes(routePath[1])

    if (!routePath.length || routePath.length === 1 || !localeExists) {
      const mapping = {}
      process.env.domainLanguage.split(';').forEach((dl) => {
        const domainLang = dl.split('::')
        mapping[domainLang[0]] = domainLang[1]
      })
      const lang = mapping[context.req.headers.host] || context.i18n.defaultLocale

      context.i18n.setLocale(lang)
      context.$moment.locale(lang)
    }
  }
}
