//
//
//
//
//
//
//
//
//
//

export default {
  name: 'PostJobMenuItem',
  methods: {
    async checkClient () {
      if (this.$auth.loggedIn && !this.$store.getters['user/isClient']) {
        const confirmation = await this.$bvModal.msgBoxConfirm(this.$t('freelancers.postJobConfirmationModal'), {
          okTitle: this.$t('common.ok'),
          cancelTitle: this.$t('common.cancel'),
          centered: true
        })

        if (confirmation) {
          await this.redirect()
        }
      } else {
        await this.redirect()
      }
    },
    async redirect () {
      await this.$router.push(this.localePath('/create-task'))
    }
  }
}
