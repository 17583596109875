/* eslint-disable no-param-reassign */
import { orderBy, set } from 'lodash'

export const state = () => ({
  openedThread: null, // currently selected application
  oldMessages: [],
  newMessages: [],
  lastId: 0, // used for lazy load of messages
  total: 0,
  threads: [],
  loadingThreads: false
})

// noinspection JSUnusedGlobalSymbols
export const actions = {
  /**
   * Get messages from API
   * @param commit
   * @param state
   * @param {Number} applicationId
   * @return {Promise<void>}
   */
  async getMessages ({
    commit,
    state
  }, applicationId) {
    if (!applicationId) {
      return
    }

    const loaded = state.newMessages.length + state.oldMessages.length

    if (loaded === 0 || loaded < state.total) {
      const options = {}

      if (state.lastId) {
        set(options, 'params.lastId', state.lastId)
      }

      const { data } = await this.$axios.get(`/messages/${applicationId}`, options)

      commit('setMessages', data.data)

      if (data.data.messages.length) {
        commit('setLastId', data.data.messages[data.data.messages.length - 1].id)
      }
    }
  },

  /**
   * Send new message
   * @param commit
   * @param getters
   * @param {Object} newMessage
   * @return {Promise<void>}
   */
  async sendMessage ({
    commit,
    state
  }, newMessage) {
    // get new message index to be able to update it after
    const newMessageIndex = state.newMessages.length

    // first add message
    commit('addMessage', {
      ...newMessage,
      index: newMessageIndex
    })

    try {
      const { data } = await this.$axios.post('/messages', {
        text: newMessage.text,
        applicationId: newMessage.applicationId,
        attachmentIds: newMessage.attachments.map(a => a.id)
      })

      // set message as sent and update message ID
      commit('setMessageSent', {
        messageIndex: newMessageIndex,
        messageId: data.data.id,
        read: data.data.read
      })
    } catch (err) {
      // set message as not sent because of error
      commit('setMessageError', newMessageIndex)
    }
  },

  /**
   * Get list of all threads
   * @param commit
   * @return {Promise<void>}
   */
  async getThreads ({ commit }) {
    const threads = await this.$axios.get('/messages/threads')
    commit('setThreads', threads.data.data)
  },

  /**
   * Socket hook
   * Triggered when "connect" event is emitted
   */
  socket_connect () {
    try {
      const token = this.$auth.strategy.token.get()

      // if logged in send "authenticate" event
      if (token) {
        this._vm.$socket.emit('authenticate', { token: token.split(' ')[1] })
      }
    } catch (err) {
      //
    }
  },

  /**
   * Some user came online
   * @param commit
   * @param {Object} user
   */
  socket_userOnline ({ commit }, user) {
    commit('updateThreadsUser', user)
  },

  /**
   * Some user went offline
   * @param commit
   * @param {Object} user
   */
  socket_userOffline ({ commit }, user) {
    commit('updateThreadsUser', user)
  },

  /**
   * When message is received through sockets add message to new ones
   * @param commit
   * @param state
   * @param rootState
   * @param {Object} data
   * @param {Number} data.id - message id
   * @param {String} data.title - task title
   * @param {String} data.text - new message text
   * @param {Number} data.applicationId - new message applicationId
   * @param {Date} data.createdAt - new message created at date
   */
  socket_messageReceived ({
    commit,
    state
  }, data) {
    // emit message read if already in that chat
    if (state.openedThread === data.applicationId) {
      commit('addMessage', data)
      this._vm.$socket.emit('messageRead', data.id)
    }

    commit('updateThreadLastMessage', data)
  },

  /**
   * Socket handler when new application is created to create new thread
   * @param commit
   * @param {Object} application
   */
  socket_applicationCreated ({ commit }, application) {
    commit('addThread', application)
  },

  /**
   * Set messages as read for currently opened thread
   * @param commit
   * @param {Object} data
   * @param {Number} data.applicationId
   * @param {Number} data.userId
   */
  socket_messagesRead ({ commit }, data) {
    commit('setMessagesRead', data)
  },

  /**
   * Set messages as read for selected application
   * @param commit
   * @param {Number} applicationId
   * @return {Promise<void>}
   */
  async readMessages ({ commit }, applicationId) {
    await this.$axios.put(`/messages/read/${applicationId}`, {})

    commit('setReadThread', applicationId)
  }
}

export const mutations = {
  /**
   * Set currently opened thread
   * @param state
   * @param applicationId
   */
  setOpenedThread (state, applicationId) {
    state.openedThread = applicationId
  },

  /**
   * Set thread loader state
   * @param state
   * @param {Boolean} loading
   */
  toggleThreadsLoader (state, loading) {
    state.loadingThreads = loading
  },

  /**
   * Set messages to state
   * @param state
   * @param {Object} data
   * @param {Array} data.messages
   * @param {Number} data.total
   */
  setMessages (state, data) {
    if (state.lastId === 0) {
      state.oldMessages = data.messages
      state.total = data.total
    } else {
      state.oldMessages.push(...data.messages)
    }
  },

  /**
   * Set threads
   * @param state
   * @param threads
   */
  setThreads (state, threads) {
    state.threads = threads
  },

  /**
   * Add new thread on new application
   * @param state
   * @param thread
   */
  addThread (state, thread) {
    state.threads.push(thread)
  },

  /**
   * Update user data in threads
   * Used for updating online/offline status
   * @param {Object} state
   * @param {Object} user
   */
  updateThreadsUser (state, user) {
    state.threads = state.threads.map((t) => {
      const {
        freelancer,
        client
      } = t

      if (freelancer?.user?.id === user.id) {
        freelancer.user = user
        t.freelancer = freelancer
      }

      if (client?.user?.id === user.id) {
        client.user = user
        t.client = client
      }

      return t
    })
  },

  /**
   * Update last message on thread
   * @param {Object} state
   * @param {Object} message
   */
  updateThreadLastMessage (state, message) {
    state.threads = state.threads.map((t) => {
      if (t.id === message.applicationId) {
        t.messages = [message]
      }

      return t
    })
  },

  /**
   * Add message to state
   * @param state
   * @param {Object} message
   */
  addMessage (state, message) {
    state.newMessages.push(message)
  },

  /**
   * Set message as sent
   * @param state
   * @param {Number} messageIndex
   * @param {Number} messageId
   * @param {Boolean} read
   */
  setMessageSent (state, {
    messageIndex,
    messageId,
    read
  }) {
    state.newMessages = state.newMessages.map((m, i) => {
      if (i === messageIndex) {
        return {
          ...m,
          id: messageId,
          sending: false,
          read
        }
      }
      return m
    })
  },

  /**
   * If something goes wrong with sending message set error
   * @param state
   * @param {Number} messageIndex
   */
  setMessageError (state, messageIndex) {
    state.newMessages = state.newMessages.map((m, i) => {
      if (i === messageIndex) {
        return {
          ...m,
          sending: false,
          error: true
        }
      }
      return m
    })
  },

  /**
   * Set messages as read in currently opened thread
   * @param state
   * @param {Object} data
   * @param {Number} data.applicationId
   * @param {Number} data.userId
   */
  setMessagesRead (state, data) {
    state.newMessages = state.newMessages.map((m) => {
      if (m.applicationId === data.applicationId && m.senderId !== data.userId) {
        return {
          ...m,
          read: true
        }
      }
      return m
    })

    state.oldMessages = state.oldMessages.map((m) => {
      if (m.applicationId === data.applicationId && m.senderId !== data.userId) {
        return {
          ...m,
          read: true
        }
      }
      return m
    })
  },

  /**
   * Remove messages
   * @param state
   */
  resetMessages (state) {
    state.oldMessages = []
    state.newMessages = []
    state.lastId = 0
    state.total = 0
  },

  /**
   * Set last message id
   * @param state
   * @param {Number} lastId
   */
  setLastId (state, lastId) {
    state.lastId = lastId
  },

  /**
   * Set total num of messages for pagination
   * @param state
   * @param {Number} total
   */
  setTotal (state, total) {
    state.total = total
  },

  /**
   * Remove application from unread messages array
   * @param state
   * @param {Number} applicationId
   */
  setReadThread (state, applicationId) {
    // update thread messages to read
    state.threads = state.threads.map((t) => {
      if (t.id === applicationId) {
        if (t.messages?.length) {
          t.messages = [{
            ...t.messages[0],
            read: true
          }]
        }
      }

      return t
    })
  }
}

export const getters = {
  /**
   * Get old and new messages for display
   * @param state
   * @return {*[]}
   */
  getAllMessages (state) {
    return [...state.oldMessages, ...state.newMessages]
  },

  /**
   * Get messages sorted by ID (newest on bottom)
   * @param state
   * @param getters
   * @return {Array}
   */
  // eslint-disable-next-line no-shadow
  getMessagesSorted (state, getters) {
    return orderBy(getters.getAllMessages, ['id', 'asc'])
  },

  /**
   * Get number of messages left to load for this chat
   * @param state
   */
  getMessagesLeft (state) {
    return state.total - state.oldMessages.length
  },

  /**
   * Get threads sorted by thread modified date
   * @param state
   * @return {Array}
   */
  getSortedThreads (state) {
    return orderBy(state.threads, ['updatedAt'], ['desc'])
  }
}
