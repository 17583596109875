//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { ArrowLeftIcon } from 'vue-feather-icons'
import LogoImage from './LogoImage.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LogoBack',
  components: {
    LogoImage,
    ArrowLeftIcon
  },
  computed: {
    freelancerFunnelMapping () {
      return {
        basicInfo: this.$t('freelancers.basic_info'),
        skills: this.$t('freelancers.skills_services'),
        workExperience: this.$t('freelancers.work_experience'),
        projects: this.$t('freelancers.projects'),
        preview: this.$t('freelancers.publish_button')
      }
    },
    /**
     * Count of items in funnel menu
     */
    funnelItemsCount () {
      return Object.keys(this.freelancerFunnelMapping).length
    },

    /**
     * Order number of item in funnel
     * @return {number}
     */
    funnelItemNum () {
      const current = this.$route?.name
      return Object.keys(this.freelancerFunnelMapping)
        .indexOf(current) + 1
    },

    /**
     * Title of funnel item
     * @return {*}
     */
    funnelItemTitle () {
      const current = this.$route?.name
      return this.freelancerFunnelMapping[current]
    }
  },
  methods: {
    /**
     * Go back one page
     */
    goBack () {
      this.$router.back()
    }
  }
}
