import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _d2f83242 = () => interopDefault(import('../pages/index.vue' /* webpackChunkName: "pages/index" */))
const _76cc0c1a = () => interopDefault(import('../pages/about.vue' /* webpackChunkName: "pages/about" */))
const _40e04b5a = () => interopDefault(import('../pages/affiliate.vue' /* webpackChunkName: "pages/affiliate" */))
const _4b36c3ae = () => interopDefault(import('../pages/applications/index.vue' /* webpackChunkName: "pages/applications/index" */))
const _360c30e8 = () => interopDefault(import('../pages/blog/index.vue' /* webpackChunkName: "pages/blog/index" */))
const _9754a636 = () => interopDefault(import('../pages/change-password.vue' /* webpackChunkName: "pages/change-password" */))
const _410631db = () => interopDefault(import('../pages/completed/index.vue' /* webpackChunkName: "pages/completed/index" */))
const _13137c26 = () => interopDefault(import('../pages/contact.vue' /* webpackChunkName: "pages/contact" */))
const _739a2774 = () => interopDefault(import('../pages/create-task/index.vue' /* webpackChunkName: "pages/create-task/index" */))
const _8f125f5c = () => interopDefault(import('../pages/forgot-password.vue' /* webpackChunkName: "pages/forgot-password" */))
const _1d2a8f60 = () => interopDefault(import('../pages/freelancers/index.vue' /* webpackChunkName: "pages/freelancers/index" */))
const _22134fa5 = () => interopDefault(import('../pages/in-progress/index.vue' /* webpackChunkName: "pages/in-progress/index" */))
const _83432d9e = () => interopDefault(import('../pages/info.vue' /* webpackChunkName: "pages/info" */))
const _7d03ef8a = () => interopDefault(import('../pages/invitations/index.vue' /* webpackChunkName: "pages/invitations/index" */))
const _a4836ec4 = () => interopDefault(import('../pages/koth/index.vue' /* webpackChunkName: "pages/koth/index" */))
const _602b89f6 = () => interopDefault(import('../pages/login.vue' /* webpackChunkName: "pages/login" */))
const _432a194f = () => interopDefault(import('../pages/messages.vue' /* webpackChunkName: "pages/messages" */))
const _184f239a = () => interopDefault(import('../pages/my-tasks/index.vue' /* webpackChunkName: "pages/my-tasks/index" */))
const _216f215a = () => interopDefault(import('../pages/privacy-policy.vue' /* webpackChunkName: "pages/privacy-policy" */))
const _04f3b0f9 = () => interopDefault(import('../pages/profile/index.vue' /* webpackChunkName: "pages/profile/index" */))
const _0d40717b = () => interopDefault(import('../pages/sign-up/index.vue' /* webpackChunkName: "pages/sign-up/index" */))
const _06644a46 = () => interopDefault(import('../pages/subscriptions/index.vue' /* webpackChunkName: "pages/subscriptions/index" */))
const _0d63a1de = () => interopDefault(import('../pages/tasks/index.vue' /* webpackChunkName: "pages/tasks/index" */))
const _4081a514 = () => interopDefault(import('../pages/terms.vue' /* webpackChunkName: "pages/terms" */))
const _12d2a8d3 = () => interopDefault(import('../pages/agents/tasks/index.vue' /* webpackChunkName: "pages/agents/tasks/index" */))
const _32f1fe86 = () => interopDefault(import('../pages/clients/trusted.vue' /* webpackChunkName: "pages/clients/trusted" */))
const _1fbd74b5 = () => interopDefault(import('../pages/create-agent/basic-info.vue' /* webpackChunkName: "pages/create-agent/basic-info" */))
const _08bfb123 = () => interopDefault(import('../pages/create-client/basic-info.vue' /* webpackChunkName: "pages/create-client/basic-info" */))
const _6d3e412a = () => interopDefault(import('../pages/create-freelancer/basic-info.vue' /* webpackChunkName: "pages/create-freelancer/basic-info" */))
const _6872efba = () => interopDefault(import('../pages/create-freelancer/preview.vue' /* webpackChunkName: "pages/create-freelancer/preview" */))
const _32d02bf8 = () => interopDefault(import('../pages/create-freelancer/projects.vue' /* webpackChunkName: "pages/create-freelancer/projects" */))
const _6c829d80 = () => interopDefault(import('../pages/create-freelancer/skills.vue' /* webpackChunkName: "pages/create-freelancer/skills" */))
const _0344e6d0 = () => interopDefault(import('../pages/create-freelancer/work-experience.vue' /* webpackChunkName: "pages/create-freelancer/work-experience" */))
const _7423b5c1 = () => interopDefault(import('../pages/create-task/agency.vue' /* webpackChunkName: "pages/create-task/agency" */))
const _de1e960e = () => interopDefault(import('../pages/create-task/agent.vue' /* webpackChunkName: "pages/create-task/agent" */))
const _50b33a01 = () => interopDefault(import('../pages/create-task/escrow.vue' /* webpackChunkName: "pages/create-task/escrow" */))
const _1e2c484e = () => interopDefault(import('../pages/create-task/subscription.vue' /* webpackChunkName: "pages/create-task/subscription" */))
const _af1e0812 = () => interopDefault(import('../pages/create-task/success.vue' /* webpackChunkName: "pages/create-task/success" */))
const _38b5992f = () => interopDefault(import('../pages/profile/preview.vue' /* webpackChunkName: "pages/profile/preview" */))
const _50038ba4 = () => interopDefault(import('../pages/sign-up/agent.vue' /* webpackChunkName: "pages/sign-up/agent" */))
const _9ac9481c = () => interopDefault(import('../pages/sign-up/client.vue' /* webpackChunkName: "pages/sign-up/client" */))
const _21bb0e2a = () => interopDefault(import('../pages/sign-up/freelancer.vue' /* webpackChunkName: "pages/sign-up/freelancer" */))
const _623207ec = () => interopDefault(import('../pages/sign-up/success.vue' /* webpackChunkName: "pages/sign-up/success" */))
const _bb2a9684 = () => interopDefault(import('../pages/subscriptions/subscribe.vue' /* webpackChunkName: "pages/subscriptions/subscribe" */))
const _0563a718 = () => interopDefault(import('../pages/user/settings.vue' /* webpackChunkName: "pages/user/settings" */))
const _1bb5223e = () => interopDefault(import('../pages/agents/tasks/_id/index.vue' /* webpackChunkName: "pages/agents/tasks/_id/index" */))
const _59a47e64 = () => interopDefault(import('../pages/agents/tasks/_id/applications/_applicationId.vue' /* webpackChunkName: "pages/agents/tasks/_id/applications/_applicationId" */))
const _48751314 = () => interopDefault(import('../pages/tasks/skill/_slug/_skillId.vue' /* webpackChunkName: "pages/tasks/skill/_slug/_skillId" */))
const _08b3d25e = () => interopDefault(import('../pages/applications/_id.vue' /* webpackChunkName: "pages/applications/_id" */))
const _34558fa0 = () => interopDefault(import('../pages/blog/_slug.vue' /* webpackChunkName: "pages/blog/_slug" */))
const _64250dc7 = () => interopDefault(import('../pages/canceled/_id.vue' /* webpackChunkName: "pages/canceled/_id" */))
const _71e1cb00 = () => interopDefault(import('../pages/clients/_id.vue' /* webpackChunkName: "pages/clients/_id" */))
const _76c621fa = () => interopDefault(import('../pages/completed/_id.vue' /* webpackChunkName: "pages/completed/_id" */))
const _59a965fa = () => interopDefault(import('../pages/confirm-email/_hash.vue' /* webpackChunkName: "pages/confirm-email/_hash" */))
const _01915653 = () => interopDefault(import('../pages/confirm-task/_hash.vue' /* webpackChunkName: "pages/confirm-task/_hash" */))
const _4ed4e9c8 = () => interopDefault(import('../pages/freelancers/_id.vue' /* webpackChunkName: "pages/freelancers/_id" */))
const _090af74d = () => interopDefault(import('../pages/in-progress/_id.vue' /* webpackChunkName: "pages/in-progress/_id" */))
const _1afc4c72 = () => interopDefault(import('../pages/invitations/_id.vue' /* webpackChunkName: "pages/invitations/_id" */))
const _261add44 = () => interopDefault(import('../pages/login-as/_id.vue' /* webpackChunkName: "pages/login-as/_id" */))
const _60cd90c4 = () => interopDefault(import('../pages/my-tasks/_id/index.vue' /* webpackChunkName: "pages/my-tasks/_id/index" */))
const _2650e3ba = () => interopDefault(import('../pages/reset-password/_hash.vue' /* webpackChunkName: "pages/reset-password/_hash" */))
const _60ec262e = () => interopDefault(import('../pages/subscriptions/_id.vue' /* webpackChunkName: "pages/subscriptions/_id" */))
const _4e8c83c6 = () => interopDefault(import('../pages/tasks/_id.vue' /* webpackChunkName: "pages/tasks/_id" */))
const _3205893c = () => interopDefault(import('../pages/unsubscribe/_hash.vue' /* webpackChunkName: "pages/unsubscribe/_hash" */))
const _6fee7fee = () => interopDefault(import('../pages/my-tasks/_id/edit.vue' /* webpackChunkName: "pages/my-tasks/_id/edit" */))
const _11c1d0e2 = () => interopDefault(import('../pages/my-tasks/_id/application/_applicationId.vue' /* webpackChunkName: "pages/my-tasks/_id/application/_applicationId" */))
const _4c9da012 = () => interopDefault(import('../pages/agents/_slug/_id.vue' /* webpackChunkName: "pages/agents/_slug/_id" */))
const _6e5c86fb = () => interopDefault(import('../pages/clients/_slug/_id.vue' /* webpackChunkName: "pages/clients/_slug/_id" */))
const _5041207a = () => interopDefault(import('../pages/freelancers/_slug/_id.vue' /* webpackChunkName: "pages/freelancers/_slug/_id" */))
const _082f1341 = () => interopDefault(import('../pages/tasks/_slug/_id.vue' /* webpackChunkName: "pages/tasks/_slug/_id" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/en",
    component: _d2f83242,
    name: "index___en"
  }, {
    path: "/hr",
    component: _d2f83242,
    name: "index___hr"
  }, {
    path: "/en/about",
    component: _76cc0c1a,
    name: "about___en"
  }, {
    path: "/en/affiliate",
    component: _40e04b5a,
    name: "affiliate___en"
  }, {
    path: "/en/applications",
    component: _4b36c3ae,
    name: "applications___en"
  }, {
    path: "/en/blog",
    component: _360c30e8,
    name: "blog___en"
  }, {
    path: "/en/change-password",
    component: _9754a636,
    name: "change-password___en"
  }, {
    path: "/en/completed",
    component: _410631db,
    name: "completed___en"
  }, {
    path: "/en/contact",
    component: _13137c26,
    name: "contact___en"
  }, {
    path: "/en/create-task",
    component: _739a2774,
    name: "create-task___en"
  }, {
    path: "/en/forgot-password",
    component: _8f125f5c,
    name: "forgot-password___en"
  }, {
    path: "/en/freelancers",
    component: _1d2a8f60,
    name: "freelancers___en"
  }, {
    path: "/en/in-progress",
    component: _22134fa5,
    name: "in-progress___en"
  }, {
    path: "/en/info",
    component: _83432d9e,
    name: "info___en"
  }, {
    path: "/en/invitations",
    component: _7d03ef8a,
    name: "invitations___en"
  }, {
    path: "/en/koth",
    component: _a4836ec4,
    name: "koth___en"
  }, {
    path: "/en/login",
    component: _602b89f6,
    name: "login___en"
  }, {
    path: "/en/messages",
    component: _432a194f,
    name: "messages___en"
  }, {
    path: "/en/my-tasks",
    component: _184f239a,
    name: "my-tasks___en"
  }, {
    path: "/en/privacy-policy",
    component: _216f215a,
    name: "privacy-policy___en"
  }, {
    path: "/en/profile",
    component: _04f3b0f9,
    name: "profile___en"
  }, {
    path: "/en/sign-up",
    component: _0d40717b,
    name: "sign-up___en"
  }, {
    path: "/en/subscriptions",
    component: _06644a46,
    name: "subscriptions___en"
  }, {
    path: "/en/tasks",
    component: _0d63a1de,
    name: "tasks___en"
  }, {
    path: "/en/terms",
    component: _4081a514,
    name: "terms___en"
  }, {
    path: "/hr/about",
    component: _76cc0c1a,
    name: "about___hr"
  }, {
    path: "/hr/affiliate",
    component: _40e04b5a,
    name: "affiliate___hr"
  }, {
    path: "/hr/applications",
    component: _4b36c3ae,
    name: "applications___hr"
  }, {
    path: "/hr/blog",
    component: _360c30e8,
    name: "blog___hr"
  }, {
    path: "/hr/change-password",
    component: _9754a636,
    name: "change-password___hr"
  }, {
    path: "/hr/completed",
    component: _410631db,
    name: "completed___hr"
  }, {
    path: "/hr/contact",
    component: _13137c26,
    name: "contact___hr"
  }, {
    path: "/hr/create-task",
    component: _739a2774,
    name: "create-task___hr"
  }, {
    path: "/hr/forgot-password",
    component: _8f125f5c,
    name: "forgot-password___hr"
  }, {
    path: "/hr/freelancers",
    component: _1d2a8f60,
    name: "freelancers___hr"
  }, {
    path: "/hr/in-progress",
    component: _22134fa5,
    name: "in-progress___hr"
  }, {
    path: "/hr/info",
    component: _83432d9e,
    name: "info___hr"
  }, {
    path: "/hr/invitations",
    component: _7d03ef8a,
    name: "invitations___hr"
  }, {
    path: "/hr/koth",
    component: _a4836ec4,
    name: "koth___hr"
  }, {
    path: "/hr/login",
    component: _602b89f6,
    name: "login___hr"
  }, {
    path: "/hr/messages",
    component: _432a194f,
    name: "messages___hr"
  }, {
    path: "/hr/my-tasks",
    component: _184f239a,
    name: "my-tasks___hr"
  }, {
    path: "/hr/privacy-policy",
    component: _216f215a,
    name: "privacy-policy___hr"
  }, {
    path: "/hr/profile",
    component: _04f3b0f9,
    name: "profile___hr"
  }, {
    path: "/hr/sign-up",
    component: _0d40717b,
    name: "sign-up___hr"
  }, {
    path: "/hr/subscriptions",
    component: _06644a46,
    name: "subscriptions___hr"
  }, {
    path: "/hr/tasks",
    component: _0d63a1de,
    name: "tasks___hr"
  }, {
    path: "/hr/terms",
    component: _4081a514,
    name: "terms___hr"
  }, {
    path: "/en/agents/tasks",
    component: _12d2a8d3,
    name: "agents-tasks___en"
  }, {
    path: "/en/clients/trusted",
    component: _32f1fe86,
    name: "clients-trusted___en"
  }, {
    path: "/en/create-agent/basic-info",
    component: _1fbd74b5,
    name: "create-agent-basic-info___en"
  }, {
    path: "/en/create-client/basic-info",
    component: _08bfb123,
    name: "create-client-basic-info___en"
  }, {
    path: "/en/create-freelancer/basic-info",
    component: _6d3e412a,
    name: "create-freelancer-basic-info___en"
  }, {
    path: "/en/create-freelancer/preview",
    component: _6872efba,
    name: "create-freelancer-preview___en"
  }, {
    path: "/en/create-freelancer/projects",
    component: _32d02bf8,
    name: "create-freelancer-projects___en"
  }, {
    path: "/en/create-freelancer/skills",
    component: _6c829d80,
    name: "create-freelancer-skills___en"
  }, {
    path: "/en/create-freelancer/work-experience",
    component: _0344e6d0,
    name: "create-freelancer-work-experience___en"
  }, {
    path: "/en/create-task/agency",
    component: _7423b5c1,
    name: "create-task-agency___en"
  }, {
    path: "/en/create-task/agent",
    component: _de1e960e,
    name: "create-task-agent___en"
  }, {
    path: "/en/create-task/escrow",
    component: _50b33a01,
    name: "create-task-escrow___en"
  }, {
    path: "/en/create-task/subscription",
    component: _1e2c484e,
    name: "create-task-subscription___en"
  }, {
    path: "/en/create-task/success",
    component: _af1e0812,
    name: "create-task-success___en"
  }, {
    path: "/en/profile/preview",
    component: _38b5992f,
    name: "profile-preview___en"
  }, {
    path: "/en/sign-up/agent",
    component: _50038ba4,
    name: "sign-up-agent___en"
  }, {
    path: "/en/sign-up/client",
    component: _9ac9481c,
    name: "sign-up-client___en"
  }, {
    path: "/en/sign-up/freelancer",
    component: _21bb0e2a,
    name: "sign-up-freelancer___en"
  }, {
    path: "/en/sign-up/success",
    component: _623207ec,
    name: "sign-up-success___en"
  }, {
    path: "/en/subscriptions/subscribe",
    component: _bb2a9684,
    name: "subscriptions-subscribe___en"
  }, {
    path: "/en/user/settings",
    component: _0563a718,
    name: "user-settings___en"
  }, {
    path: "/hr/agents/tasks",
    component: _12d2a8d3,
    name: "agents-tasks___hr"
  }, {
    path: "/hr/clients/trusted",
    component: _32f1fe86,
    name: "clients-trusted___hr"
  }, {
    path: "/hr/create-agent/basic-info",
    component: _1fbd74b5,
    name: "create-agent-basic-info___hr"
  }, {
    path: "/hr/create-client/basic-info",
    component: _08bfb123,
    name: "create-client-basic-info___hr"
  }, {
    path: "/hr/create-freelancer/basic-info",
    component: _6d3e412a,
    name: "create-freelancer-basic-info___hr"
  }, {
    path: "/hr/create-freelancer/preview",
    component: _6872efba,
    name: "create-freelancer-preview___hr"
  }, {
    path: "/hr/create-freelancer/projects",
    component: _32d02bf8,
    name: "create-freelancer-projects___hr"
  }, {
    path: "/hr/create-freelancer/skills",
    component: _6c829d80,
    name: "create-freelancer-skills___hr"
  }, {
    path: "/hr/create-freelancer/work-experience",
    component: _0344e6d0,
    name: "create-freelancer-work-experience___hr"
  }, {
    path: "/hr/create-task/agency",
    component: _7423b5c1,
    name: "create-task-agency___hr"
  }, {
    path: "/hr/create-task/agent",
    component: _de1e960e,
    name: "create-task-agent___hr"
  }, {
    path: "/hr/create-task/escrow",
    component: _50b33a01,
    name: "create-task-escrow___hr"
  }, {
    path: "/hr/create-task/subscription",
    component: _1e2c484e,
    name: "create-task-subscription___hr"
  }, {
    path: "/hr/create-task/success",
    component: _af1e0812,
    name: "create-task-success___hr"
  }, {
    path: "/hr/profile/preview",
    component: _38b5992f,
    name: "profile-preview___hr"
  }, {
    path: "/hr/sign-up/agent",
    component: _50038ba4,
    name: "sign-up-agent___hr"
  }, {
    path: "/hr/sign-up/client",
    component: _9ac9481c,
    name: "sign-up-client___hr"
  }, {
    path: "/hr/sign-up/freelancer",
    component: _21bb0e2a,
    name: "sign-up-freelancer___hr"
  }, {
    path: "/hr/sign-up/success",
    component: _623207ec,
    name: "sign-up-success___hr"
  }, {
    path: "/hr/subscriptions/subscribe",
    component: _bb2a9684,
    name: "subscriptions-subscribe___hr"
  }, {
    path: "/hr/user/settings",
    component: _0563a718,
    name: "user-settings___hr"
  }, {
    path: "/en/agents/tasks/:id",
    component: _1bb5223e,
    name: "agents-tasks-id___en"
  }, {
    path: "/hr/agents/tasks/:id",
    component: _1bb5223e,
    name: "agents-tasks-id___hr"
  }, {
    path: "/en/agents/tasks/:id/applications/:applicationId?",
    component: _59a47e64,
    name: "agents-tasks-id-applications-applicationId___en"
  }, {
    path: "/hr/agents/tasks/:id/applications/:applicationId?",
    component: _59a47e64,
    name: "agents-tasks-id-applications-applicationId___hr"
  }, {
    path: "/en/tasks/skill/:slug?/:skillId?",
    component: _48751314,
    name: "tasks-skill-slug-skillId___en"
  }, {
    path: "/hr/tasks/skill/:slug?/:skillId?",
    component: _48751314,
    name: "tasks-skill-slug-skillId___hr"
  }, {
    path: "/en/applications/:id",
    component: _08b3d25e,
    name: "applications-id___en"
  }, {
    path: "/en/blog/:slug",
    component: _34558fa0,
    name: "blog-slug___en"
  }, {
    path: "/en/canceled/:id?",
    component: _64250dc7,
    name: "canceled-id___en"
  }, {
    path: "/en/clients/:id?",
    component: _71e1cb00,
    name: "clients-id___en"
  }, {
    path: "/en/completed/:id",
    component: _76c621fa,
    name: "completed-id___en"
  }, {
    path: "/en/confirm-email/:hash?",
    component: _59a965fa,
    name: "confirm-email-hash___en"
  }, {
    path: "/en/confirm-task/:hash?",
    component: _01915653,
    name: "confirm-task-hash___en"
  }, {
    path: "/en/freelancers/:id",
    component: _4ed4e9c8,
    name: "freelancers-id___en"
  }, {
    path: "/en/in-progress/:id?",
    component: _090af74d,
    name: "in-progress-id___en"
  }, {
    path: "/en/invitations/:id",
    component: _1afc4c72,
    name: "invitations-id___en"
  }, {
    path: "/en/login-as/:id?",
    component: _261add44,
    name: "login-as-id___en"
  }, {
    path: "/en/my-tasks/:id",
    component: _60cd90c4,
    name: "my-tasks-id___en"
  }, {
    path: "/en/reset-password/:hash?",
    component: _2650e3ba,
    name: "reset-password-hash___en"
  }, {
    path: "/en/subscriptions/:id",
    component: _60ec262e,
    name: "subscriptions-id___en"
  }, {
    path: "/en/tasks/:id",
    component: _4e8c83c6,
    name: "tasks-id___en"
  }, {
    path: "/en/unsubscribe/:hash?",
    component: _3205893c,
    name: "unsubscribe-hash___en"
  }, {
    path: "/hr/applications/:id",
    component: _08b3d25e,
    name: "applications-id___hr"
  }, {
    path: "/hr/blog/:slug",
    component: _34558fa0,
    name: "blog-slug___hr"
  }, {
    path: "/hr/canceled/:id?",
    component: _64250dc7,
    name: "canceled-id___hr"
  }, {
    path: "/hr/clients/:id?",
    component: _71e1cb00,
    name: "clients-id___hr"
  }, {
    path: "/hr/completed/:id",
    component: _76c621fa,
    name: "completed-id___hr"
  }, {
    path: "/hr/confirm-email/:hash?",
    component: _59a965fa,
    name: "confirm-email-hash___hr"
  }, {
    path: "/hr/confirm-task/:hash?",
    component: _01915653,
    name: "confirm-task-hash___hr"
  }, {
    path: "/hr/freelancers/:id",
    component: _4ed4e9c8,
    name: "freelancers-id___hr"
  }, {
    path: "/hr/in-progress/:id?",
    component: _090af74d,
    name: "in-progress-id___hr"
  }, {
    path: "/hr/invitations/:id",
    component: _1afc4c72,
    name: "invitations-id___hr"
  }, {
    path: "/hr/login-as/:id?",
    component: _261add44,
    name: "login-as-id___hr"
  }, {
    path: "/hr/my-tasks/:id",
    component: _60cd90c4,
    name: "my-tasks-id___hr"
  }, {
    path: "/hr/reset-password/:hash?",
    component: _2650e3ba,
    name: "reset-password-hash___hr"
  }, {
    path: "/hr/subscriptions/:id",
    component: _60ec262e,
    name: "subscriptions-id___hr"
  }, {
    path: "/hr/tasks/:id",
    component: _4e8c83c6,
    name: "tasks-id___hr"
  }, {
    path: "/hr/unsubscribe/:hash?",
    component: _3205893c,
    name: "unsubscribe-hash___hr"
  }, {
    path: "/en/my-tasks/:id?/edit",
    component: _6fee7fee,
    name: "my-tasks-id-edit___en"
  }, {
    path: "/hr/my-tasks/:id?/edit",
    component: _6fee7fee,
    name: "my-tasks-id-edit___hr"
  }, {
    path: "/en/my-tasks/:id?/application/:applicationId",
    component: _11c1d0e2,
    name: "my-tasks-id-application-applicationId___en"
  }, {
    path: "/hr/my-tasks/:id?/application/:applicationId",
    component: _11c1d0e2,
    name: "my-tasks-id-application-applicationId___hr"
  }, {
    path: "/en/agents/:slug?/:id?",
    component: _4c9da012,
    name: "agents-slug-id___en"
  }, {
    path: "/en/clients/:slug?/:id?",
    component: _6e5c86fb,
    name: "clients-slug-id___en"
  }, {
    path: "/en/freelancers/:slug/:id?",
    component: _5041207a,
    name: "freelancers-slug-id___en"
  }, {
    path: "/en/tasks/:slug/:id?",
    component: _082f1341,
    name: "tasks-slug-id___en"
  }, {
    path: "/hr/agents/:slug?/:id?",
    component: _4c9da012,
    name: "agents-slug-id___hr"
  }, {
    path: "/hr/clients/:slug?/:id?",
    component: _6e5c86fb,
    name: "clients-slug-id___hr"
  }, {
    path: "/hr/freelancers/:slug/:id?",
    component: _5041207a,
    name: "freelancers-slug-id___hr"
  }, {
    path: "/hr/tasks/:slug/:id?",
    component: _082f1341,
    name: "tasks-slug-id___hr"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}
