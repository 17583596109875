import redirectMap from '@/lib/redirectMap'

export default function ({
  store,
  $auth,
  redirect,
  localePath
}) {
  if ($auth.loggedIn) {
    const roleName = store.getters['user/getActiveRoleTypeName']
    return redirect(localePath(redirectMap[roleName]))
  }
}
