//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import {
  CheckSquareIcon, CoffeeIcon, MailIcon, CheckIcon, BookmarkIcon, GlobeIcon
} from 'vue-feather-icons'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'FreelancerMenu',
  components: {
    CheckSquareIcon,
    CoffeeIcon,
    BookmarkIcon,
    MailIcon,
    CheckIcon,
    GlobeIcon
  },
  data () {
    return {
      items: []
    }
  }
}
