export default function ({
  store,
  redirect,
  $auth,
  localePath,
  i18n
}) {
  if ($auth.loggedIn) {
    if (!store.getters['user/isUserCompleted']) {
      const roleType = store.getters['user/getActiveRoleType']
      store.dispatch('ui/showNotification', {
        type: 'danger',
        text: i18n.t('common.complete_profile')
      })
      const route = store.getters['user/currentWizardStep']
      return redirect(localePath(`/create-${roleType.name}/${route}`))
    }

    return true
  }

  return true
}
