import Vue from 'vue'
import { library, config } from '@fortawesome/fontawesome-svg-core'
import {
  FontAwesomeLayers,
  FontAwesomeLayersText,
  FontAwesomeIcon
} from '@fortawesome/vue-fontawesome'

import {
  faMediumM as freeFabFaMediumM,
  faTwitter as freeFabFaTwitter,
  faYoutube as freeFabFaYoutube,
  faTelegramPlane as freeFabFaTelegramPlane,
  faDiscord as freeFabFaDiscord,
  faRedditAlien as freeFabFaRedditAlien,
  faLinkedinIn as freeFabFaLinkedinIn,
  faFacebookF as freeFabFaFacebookF,
  faInstagram as freeFabFaInstagram,
  faTiktok as freeFabFaTiktok
} from '@fortawesome/free-brands-svg-icons'

library.add(
  freeFabFaMediumM,
  freeFabFaTwitter,
  freeFabFaYoutube,
  freeFabFaTelegramPlane,
  freeFabFaDiscord,
  freeFabFaRedditAlien,
  freeFabFaLinkedinIn,
  freeFabFaFacebookF,
  freeFabFaInstagram,
  freeFabFaTiktok
)

config.autoAddCss = false

Vue.component('FontAwesomeIcon', FontAwesomeIcon)
Vue.component('FontAwesomeLayers', FontAwesomeLayers)
Vue.component('FontAwesomeLayersText', FontAwesomeLayersText)
