//
//
//
//
//
//
//
//
//
//
//

import { Base64 } from 'js-base64'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'ImageDisplay',
  props: {
    file: {
      type: Object,
      default () {
        return {}
      }
    },
    alt: {
      type: String,
      default: null
    },
    options: {
      type: Object,
      default () {
        return {
          resize: {}
        }
      }
    }
  },
  computed: {
    src () {
      try {
        const imageRequest = JSON.stringify({
          bucket: process.env.imagesBucket,
          key: this.file.fileName,
          edits: Object.assign({}, this.options, { rotate: null })
        })
        return `${process.env.imagesURL}/${Base64.encode(imageRequest)}`
      } catch (err) {
        return null
      }
    }
  }
}
