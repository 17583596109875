export default {
  methods: {
    async checkProfileCompleteness () {
      const profileCompleted = this.$store.getters['user/isUserCompleted']
      const openStepRoute = this.$store.getters['user/currentWizardStep']
      const activeRole = this.$store.getters['user/getActiveRoleType']

      if (!profileCompleted) {
        await this.$store.dispatch('ui/showNotification', {
          type: 'warning',
          text: this.$t('common.complete_profile')
        })
        await this.$router.replace(this.localePath(`/create-${activeRole.name}/${openStepRoute}`))
        return false
      } else {
        return true
      }
    }
  }
}
