//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarDisplay from '@/components/ui/AvatarDisplay'
import { mapGetters } from 'vuex'
import ProfileMethods from '@/mixins/ProfileMethods'
import AxiosErrorHandler from '@/mixins/AxiosErrorHandler'
import UserMenuProfileButton from '@/components/ui/UserMenuProfileButton'
import { roleNames } from 'ct-constants'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'UserMenu',
  components: {
    UserMenuProfileButton,
    AvatarDisplay
  },
  mixins: [
    ProfileMethods,
    AxiosErrorHandler
  ],
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 32,
          height: 32
        }
      }
    }
  },
  computed: {
    ...mapGetters('user', {
      activeRoleData: 'getActiveRoleData',
      user: 'getCurrentUser',
      userName: 'getUserName',
      avatar: 'getAvatar',
      hasClient: 'hasClient',
      hasFreelancer: 'hasFreelancer',
      otherRoles: 'otherRoles',
      currentRole: 'getActiveRoleType',
      isClient: 'isClient',
      isFreelancer: 'isFreelancer',
      isAgent: 'isAgent',
      hasAgent: 'hasAgent'
    }),

    /**
     * Get other role type name
     * @returns {String}
     */
    currentRoleName () {
      return this.currentRole?.name || ''
    },

    /**
     * Checks if any role is missing
     * @returns {boolean}
     */
    missingRole () {
      return !this.hasAgent || !this.hasClient || !this.hasFreelancer
    }
  },
  methods: {
    /**
     * Logout
     */
    async logout () {
      try {
        await this.$router.replace(this.localePath('/login'))
        await this.$auth.logout()
        this.$smartContract.removeKeypairs()
        this.$auth.$storage.removeUniversal('redirect')

        if (this.$gtag) {
          this.$gtag.set({
            user_id: null
          })
        }
      } catch (err) {
        await this.handleError(err)
      }
    },

    /**
     * Create freelancer role profile for user
     * @returns {Promise<void>}
     */
    async createFreelancer () {
      try {
        await this.$store.dispatch('user/createFreelancer')
        await this.$store.dispatch('user/switchRole', roleNames.FREELANCER)
        await this.checkProfileCompleteness()
        await this.$store.dispatch('ui/showNotification', {
          type: 'success',
          text: this.$t('freelancers.roleCreated')
        })
      } catch (err) {
        await this.handleError(err)
      }
    },

    /**
     * Create client role profile for user
     * @returns {Promise<void>}
     */
    async createClient () {
      try {
        await this.$store.dispatch('user/createClient')
        await this.$store.dispatch('user/switchRole', roleNames.CLIENT)
        await this.checkProfileCompleteness()
        await this.$store.dispatch('ui/showNotification', {
          type: 'success',
          text: this.$t('clients.roleCreated')
        })
      } catch (err) {
        await this.handleError(err)
      }
    },

    /**
     * Create agent role profile for user
     * @returns {Promise<void>}
     */
    async createAgent () {
      try {
        await this.$store.dispatch('user/createAgent')
        await this.$store.dispatch('user/switchRole', roleNames.AGENT)
        await this.checkProfileCompleteness()
        await this.$store.dispatch('ui/showNotification', {
          type: 'success',
          text: this.$t('agent.profile.roleCreated')
        })
      } catch (err) {
        await this.handleError(err)
      }
    },

    /**
     * Change role to other one
     * @returns {Promise<void>}
     */
    async switchRole (role) {
      try {
        await this.$store.dispatch('user/switchRole', role)
        if (await this.checkProfileCompleteness()) {
          if (this.isFreelancer) {
            await this.$router.replace(this.localePath('/tasks'))
          } else if (this.isClient) {
            await this.$router.replace(this.localePath('/create-task'))
          } else if (this.isAgent) {
            await this.$router.replace(this.localePath('/agents/tasks'))
          }
        }
      } catch (err) {
        await this.handleError(err)
      }
    }
  }
}
