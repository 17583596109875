export const state = () => ({
  packages: []
})

/**
 * Actions
 */
export const actions = {
  async fetchPackages ({
    commit,
    state
  }) {
    // fetch packages only if not already fetched
    if (!state.packages.length) {
      const { data } = await this.$axios.get('/packages')
      commit('setPackages', data.data)
    }
  }
}

/**
 * Mutations
 */
export const mutations = {
  setPackages (state, packages) {
    state.packages = packages
  }
}

/**
 * Getters
 */
export const getters = {
  getTaskPackages (state) {
    return state.packages.filter(p => p.entityType === 'task')
  },

  getFreelancerPackages (state) {
    return state.packages.filter(p => p.entityType === 'freelancer')
  },

  getClientPackages (state) {
    return state.packages.filter(p => p.entityType === 'client')
  }
}
