//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoSmallFl from '@/components/layout/LogoSmallFl'

export default {
  name: 'LogoFreelanceHr',
  components: { LogoSmallFl },
  computed: {
    /**
     * Check if dark theme is enabled
     * @return boolean
     * */
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
