//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import LogoSmallCt from '@/components/layout/LogoSmallCt'

export default {
  name: 'LogoCryptotaskOrg',
  components: { LogoSmallCt },
  computed: {
    /**
     * Check if dark theme is enabled
     * @return boolean
     * */
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
