//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import PostJobMenuItem from '@/components/layout/PostJobMenuItem'

export default {
  name: 'TopMenu',
  components: {
    PostJobMenuItem
  },
  computed: {
    landingUrl () {
      return `${process.env.landingUrl}info?mode=${this.isDarkThemeEnabled ? 'dark' : 'light'}`
    },
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    }
  }
}
