//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import UserMenu from './UserMenu.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'UserInfo',
  components: {
    UserMenu
  }
}
