//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

// noinspection JSUnusedGlobalSymbols
import SocialLinks from '@/components/layout/SocialLinks'
import FooterLogo from '@/components/layout/FooterLogo'
export default {
  name: 'MainLayoutFooter',
  components: { FooterLogo, SocialLinks }
}
