//
//
//
//
//
//

import { SunIcon, MoonIcon } from 'vue-feather-icons'

export default {
  name: 'ThemeToggler',
  components: {
    SunIcon,
    MoonIcon
  },
  computed: {
    /**
     * Check if dark theme is enabled
     * @return boolean
     * */
    isDarkThemeEnabled () {
      return this.$store.state.ui.isDarkThemeEnabled
    },

    routeMode () {
      return this.$route?.query?.mode
    }
  },
  watch: {
    routeMode () {
      this.setTheme()
    }
  },
  mounted () {
    this.setTheme()
  },
  methods: {
    setTheme () {
      const isDarkThemeEnabled = this.$auth.$storage.getLocalStorage('dark-theme')
      if ((this.routeMode && this.routeMode === 'dark') || (!this.routeMode && isDarkThemeEnabled)) {
        this.enableDarkTheme()
        this.$store.commit('ui/enableDarkTheme')
      } else {
        this.disableDarkTheme()
        this.$store.commit('ui/disableDarkTheme')
      }
    },

    /**
     * Enables dark theme, adds item to local storage
     * */
    enableDarkTheme () {
      const { body } = document
      body.classList.add('dark-theme')
      this.$auth.$storage.setLocalStorage('dark-theme', 'enabled')
    },
    /**
     * Enables dark theme, removes item to local storage
     * */
    disableDarkTheme () {
      const { body } = document
      body.classList.remove('dark-theme')
      this.$auth.$storage.removeLocalStorage('dark-theme')
    },
    /**
     * Toggles theme
     * */
    toggleDarkTheme () {
      const isDarkThemeEnabled = this.$auth.$storage.getLocalStorage('dark-theme')

      if (isDarkThemeEnabled) {
        this.$store.commit('ui/disableDarkTheme')
        this.disableDarkTheme()
        return
      }
      this.$store.commit('ui/enableDarkTheme')
      this.enableDarkTheme()
    }
  }
}
