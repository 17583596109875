//
//
//
//
//
//
//
//
//
//
//
//

import { mapState } from 'vuex'
import { XCircleIcon } from 'vue-feather-icons'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'GlobalNotification',
  components: {
    XCircleIcon
  },
  computed: {
    ...mapState('ui', {
      text: state => state.notificationText,
      type: state => state.notificationType
    }),
    displayed () {
      return !!this.text
    }
  },
  methods: {
    removeNotification () {
      this.$store.commit('ui/hideNotification')
    }
  }
}
