//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { contrastColor } from 'contrast-color'
import ImageDisplay from './ImageDisplay.vue'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'AvatarDisplay',
  components: { ImageDisplay },
  props: {
    userName: {
      type: String,
      default: null
    },
    avatar: {
      type: Object,
      default () {
        return {}
      }
    },
    options: {
      type: Object,
      default () {
        return {}
      }
    },
    color: {
      type: String,
      default: null
    }
  },
  computed: {
    /**
     * Check if avatar exists
     */
    hasAvatar () {
      return !!this.avatar && !!this.avatar.fileName
    },

    /**
     * Return avatar placeholder image if no avatar uploaded
     * @return {any}
     */
    avatarPlaceholder () {
      // eslint-disable-next-line global-require
      return require('@/assets/img/avatar-placeholder.png')
    },

    /**
     * Get first letter of username
     * @returns {string|string}
     */
    firstLetter () {
      return this.userName ? this.userName.substring(0, 1).toUpperCase() : ''
    },

    /**
     * Calculate contrast color for letters
     * @returns {*|string}
     */
    cc () {
      return this.color
        ? contrastColor({
          bgColor: '#' + this.color
        })
        : ''
    },

    /**
     * Build color box style
     * @returns {{'background-color': string, color: (*|string)}}
     */
    colorStyle () {
      return {
        'background-color': '#' + this.color,
        color: this.cc
      }
    }
  }
}
