//
//
//
//
//
//
//
//

export default {
  name: 'SocialLink',
  props: {
    link: {
      type: Object,
      required: true
    }
  }
}
