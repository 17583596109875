//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { CoffeeIcon, BookmarkIcon } from 'vue-feather-icons'

export default {
  name: 'AgentMenu',
  components: {
    CoffeeIcon,
    BookmarkIcon
  }
}
