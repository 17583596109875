//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  name: 'ErrorLayout',
  layout: 'blank',
  props: {
    error: {
      type: Object,
      required: true
    }
  }
}
