const middleware = {}

middleware['checkProfileCompleteness'] = require('../middleware/checkProfileCompleteness.js')
middleware['checkProfileCompleteness'] = middleware['checkProfileCompleteness'].default || middleware['checkProfileCompleteness']

middleware['isAgent'] = require('../middleware/isAgent.js')
middleware['isAgent'] = middleware['isAgent'].default || middleware['isAgent']

middleware['isClient'] = require('../middleware/isClient.js')
middleware['isClient'] = middleware['isClient'].default || middleware['isClient']

middleware['isFreelancer'] = require('../middleware/isFreelancer.js')
middleware['isFreelancer'] = middleware['isFreelancer'].default || middleware['isFreelancer']

middleware['redirectClient'] = require('../middleware/redirectClient.js')
middleware['redirectClient'] = middleware['redirectClient'].default || middleware['redirectClient']

middleware['redirectLanding'] = require('../middleware/redirectLanding.js')
middleware['redirectLanding'] = middleware['redirectLanding'].default || middleware['redirectLanding']

export default middleware
