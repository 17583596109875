import Vue from 'vue'
import moment from 'moment'

export default () => {
  Vue.filter('dateFormat', (value, format) => {
    if (!value) {
      return ''
    }
    return moment(value).format(format)
  })
}
