import { roleNames } from 'ct-constants'
import handleError from '@/lib/handleError'

export default async function ({
  store,
  $auth,
  i18n
}) {
  try {
    const isClient = store.getters['user/isClient']
    const hasClient = store.getters['user/hasClient']

    if ($auth.loggedIn && !isClient) {
      if (!hasClient) {
        // redirect to client profile creation
        await store.dispatch('user/createClient')
      }

      // switch to client profile
      await store.dispatch('user/switchRole', roleNames.CLIENT)
    }
  } catch (error) {
    await handleError(error, i18n, store)
  }
}
