//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import AvatarDisplay from '@/components/ui/AvatarDisplay'

export default {
  name: 'UserMenuProfileButton',
  components: { AvatarDisplay },
  props: {
    avatar: {
      type: Object,
      default: null
    },
    color: {
      type: String,
      default: null
    },
    userName: {
      type: String,
      default: null
    },
    roleName: {
      type: String,
      default: null
    }
  },
  data () {
    return {
      avatarOptions: {
        resize: {
          width: 32,
          height: 32
        }
      }
    }
  }
}
