//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import capitalize from 'lodash/capitalize'

// noinspection JSUnusedGlobalSymbols
export default {
  name: 'LanguageSwitcher',
  computed: {
    languages () {
      return this.$i18n.locales
    },
    selectedLanguage () {
      return this.$store.getters['util/getCurrentLanguage']
    },
    currentLanguageCode () {
      return this.$store.state.i18n.locale
    }
  },
  methods: {
    selectLanguage (l) {
      if (l.code !== this.currentLanguageCode) {
        this.$router.replace(this.switchLocalePath(l.code))
        this.$moment.locale(l.code)

        if (this.$gtm) {
          this.$gtm.init(this.$config[`googleTagManager${capitalize(l.code)}`])
        }
      }
    }
  }
}
